<template>
  <div :class="[hasCustomGoal || isInvisible ? 'promo-tab0-goal' : 'promo-tab0']">
    <div class="chart-filter-wrapper">
      <div class="chart-filter-date-select">
        <v-btn
          depressed
          class="mr-2"
          text
          color="primary"
          @click="exportSinglePromptToCsv({ ...model, data: metrics }, currChartSrc)"
        >
          Export to CSV
        </v-btn>
        <div class="chart-filter-label">Date range:</div>
        <RfMetricsDateRangeSelector
          parentPage="promotions"
          :key="model.id"
          v-on:fetchMetrics="fetchMetrics"
          v-on:fetchMetricsCustom="fetchMetricsCustom"
          :sinceStartDate="true"
        />
      </div>
    </div>
    <v-card class="promo-tab0-stats">
      <div class="segment-overview-header retention-stat-h bg-white">
        <h4>Performance</h4>
        <div class="segment-performance-stats"></div>
      </div>
      <div class="promo-chart-container">
        <div v-if="hasData">
          <v-row>
            <v-col cols="12" class="performance-stats-wrapper">
              <template v-if="isPushNotification">
                <div class="performance-stats-col">
                  <div class="performance-stats-label">Endpoints</div>
                  <div class="performance-stats-value">{{ uniqueEndpointsGroupedByDate }}</div>
                </div>
                <div class="performance-stats-col">
                  <div class="performance-stats-label">Attempted Deliveries</div>
                  <div class="performance-stats-value">{{ attemptedDeliveriesGroupedByDate }}</div>
                </div>
                <div class="performance-stats-col">
                  <div class="performance-stats-label">Deliveries</div>
                  <div class="performance-stats-value">{{ uniqueDeliveriesGroupedByDate }}</div>
                </div>
              </template>
              <template v-else>
                <div class="performance-stats-col">
                  <div class="performance-stats-label">Users</div>
                  <div class="performance-stats-value">{{ totalUsersText }}</div>
                </div>
                <template v-if="!isInvisible">
                  <div class="performance-stats-col">
                    <div class="performance-stats-label">{{ impressionsLabel }}</div>
                    <div class="performance-stats-value">{{ totalImpressionsText }}</div>
                  </div>
                  <div class="performance-stats-col">
                    <div class="performance-stats-label">CTR</div>
                    <div class="performance-stats-value">{{ displayCTR }}</div>
                  </div>
                  <div class="performance-stats-col">
                    <div>
                      <div class="performance-stats-label">Clicks</div>
                      <div class="performance-stats-value">{{ totalGoalsText }}</div>
                    </div>
                  </div>
                </template>
                <div class="performance-stats-col" v-if="hasCustomGoal">
                  <div>
                    <div class="performance-stats-label">Custom Goal</div>
                    <div class="performance-stats-value">{{ totalCustomGoals }}</div>
                  </div>
                </div>
                <div class="performance-stats-col custom-ctr-value" v-if="hasCustomGoal">
                  <div class="performance-stats-label">Conv. Rate</div>
                  <div class="performance-stats-value">{{ displayConversionRate }}</div>
                </div>
                <div class="performance-stats-col" v-else>
                  <div class="performance-stats-label">Conv. Rate</div>
                  <div class="performance-stats-value">
                    {{ isInvisible ? "-" : displayConversionRateDefault }}
                  </div>
                  <div v-if="accept2Enabled">
                    <div class="performance-stats-label">Conv. Rate (additional)</div>
                    <div class="performance-stats-value">{{ displayConversionRateAdditional }}</div>
                  </div>
                </div>
              </template>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div v-if="getMetrics" class="rf-chart-wrapper">
            <v-row>
              <v-col cols="12">
                <RfPromotionChart
                  :metrics="getMetrics"
                  :metricsKey="currChartSrc"
                  :hasCustomGoal="hasCustomGoal"
                  :singleDayCustom="singleDayCustom"
                  :actions="model.actions"
                  :customGoalInteractionName="customGoalInteractionName"
                  :goalsTitle="goalsTitle"
                  :isInvisible="isInvisible"
                  :model="model"
                />
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else class="flex h-full items-center justify-center text-center">
          <NoChartDataPlaceholder class="py-20" />
        </div>
      </div>
    </v-card>
    <template v-if="!isInvisible">
      <v-card class="promo-tab0-piechart" style="grid-area: prompt-interaction">
        <div class="segment-overview-header retention-stat-h bg-white">
          <h4>Interaction</h4>
        </div>
        <div class="flex items-center justify-center text-center">
          <GChart
            v-if="promptInteractionMetrics?.length > 1"
            :options="getDefaultPieChartOptions({ width: 400 })"
            :data="promptInteractionMetrics"
            :create-chart="createPieChart"
          />
          <NoChartDataPlaceholder class="py-20" v-else />
        </div>
      </v-card>
      <v-card
        class="promo-tab0-piechart"
        v-if="hasCustomGoal"
        style="grid-area: custom-goal-interaction"
      >
        <div class="segment-overview-header retention-stat-h bg-white">
          <h4>Goals</h4>
        </div>
        <div class="flex items-center justify-center text-center">
          <GChart
            v-if="customGoalMetrics?.length > 1"
            :options="getDefaultPieChartOptions({ width: 400 })"
            :data="customGoalMetrics"
            :create-chart="createPieChart"
          />
          <NoChartDataPlaceholder class="py-20" v-else />
        </div>
      </v-card>
    </template>
    <template v-if="isSurvey">
      <v-card class="promo-tab0-piechart">
        <div class="segment-overview-header retention-stat-h bg-white">
          <h4>Survey Interaction</h4>
        </div>
        <RfSurveyChart :hasData="customMetricsTotal" :surveyChartData="surveyChartData" />
      </v-card>
      <v-card class="promo-tab0-piechart promo-tab0--survey">
        <div class="segment-overview-header retention-stat-h bg-white">
          <h4>Survey Metrics</h4>
        </div>
        <RfSurveyMetricsTable :surveyChartData="surveyChartData" :totalData="customMetricsTotal" />
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfPromotionChart from "@/components/RfPromotionChart.vue";
import MetricsUtils from "@/utils/MetricsUtils";
import MetricsMixin from "@/utils/MetricsMixin";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import CustomGoalInteractionMixin from "@/utils/CustomGoalInteractionMixin";
import UserSettingsMixin from "@/utils/UserSettingsMixin.js";
import { createPieChart, getDefaultPieChartOptions } from "@/utils/vueGoogleChartUtils";
import { TITLE_ROW, TITLE_ROW_CUSTOM } from "@/utils/constants/DisplayMetricsConstants.js";
import { getSurveyInputs, getTotals, percentage } from "@/utils/metricsHelpers";
import { exportSinglePromptToCsv } from "@/utils/CsvUtils";
import {
  isAccept2Enabled,
  isEmail,
  isInvisible,
  isPushNotification,
  isSurvey,
} from "@/utils/prompts/promptHelpers";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import {
  PATH_CUSTOM_METRIC_PIECHART,
  PATH_CUSTOM_METRIC_SURVEY,
} from "@/utils/constants/CustomMetricsConstants";
import StringUtils from "@/utils/StringUtils";
import RfSurveyChart from "@/blocks/RfCharts/RfSurveyChart.vue";
import RfSurveyMetricsTable from "@/blocks/RfTable/RfSurveyMetricsTable.vue";
import NoChartDataPlaceholder from "@/components/placeholder/NoChartDataPlaceholder.vue";
import { interactionDenominators } from "@/utils/constants/PromoInteractionConstants";

export default {
  name: "RfRetentionAnalysis",
  setup: () => ({
    createPieChart,
    percentage,
    exportSinglePromptToCsv,
    getDefaultPieChartOptions,
  }),
  mixins: [MetricsMixin, CustomGoalInteractionMixin, UserSettingsMixin],
  components: {
    RfPromotionChart,
    RfMetricsDateRangeSelector,
    RfSurveyChart,
    RfSurveyMetricsTable,
    NoChartDataPlaceholder,
  },
  data() {
    return {
      compareChartSrc: null,
      currChartSrc: null,
      compareKey: "promotionsCompare",
      usageKey: "promotionsUsage",
      singleDayCustom: false,
      segmentEditMode: true,
    };
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      model: state => state.apps.currPath,
      metrics: state => state.apps.currPathMetrics,
      currPathCustomMetrics: state => state.apps.currPathCustomMetrics,
    }),
    isSurvey() {
      return isSurvey(this.model);
    },
    surveyChartData() {
      return [...Array(this.surveyInputs.labels.length).keys()].map(index => {
        const label = this.surveyInputs.labels[index];
        const value = this.customMetrics[this.surveyInputs.values[index]] || 0;
        const percentage = this.customMetricsTotal
          ? StringUtils.formatStatFixed((value * 100) / this.customMetricsTotal)
          : 0;
        return [
          label,
          {
            label,
            value,
            percentage,
            v: value,
            f: `${label} ${percentage}`,
          },
          `${label} (<strong>${percentage}</strong>)`,
        ];
      });
    },
    surveyInputs() {
      return getSurveyInputs(this.model);
    },
    customMetrics() {
      return (
        this.currPathCustomMetrics?.[
          this.isSurvey ? PATH_CUSTOM_METRIC_SURVEY : PATH_CUSTOM_METRIC_PIECHART
        ]?.[this.currChartSrc]?.agg_data?.[2] || []
      );
    },
    customMetricsTotal() {
      return Object.values(this.customMetrics).reduce((a, e) => a + e, 0);
    },
    accept2Enabled() {
      return isAccept2Enabled(this.model);
    },
    isInvisible() {
      return isInvisible(this.model);
    },
    isPushNotification() {
      return isPushNotification(this.model);
    },
    uniqueEndpointsGroupedByDate() {
      const data =
        this.metrics?.["unique-endpoints-grouped-by-date"]?.[this.currChartSrc]?.uniques || 0;
      return data ? toLocaleNumberString(data) : "N/A";
    },
    attemptedDeliveriesGroupedByDate() {
      const data =
        this.metrics?.["attempted-deliveries-grouped-by-date"]?.[this.currChartSrc]?.uniques || 0;
      return data ? toLocaleNumberString(data) : "N/A";
    },
    uniqueDeliveriesGroupedByDate() {
      const data =
        this.metrics?.["unique-deliveries-grouped-by-date"]?.[this.currChartSrc]?.uniques || 0;
      return data ? toLocaleNumberString(data) : "N/A";
    },
    totalUsers() {
      const statType = this.isInvisible ? "goals" : "uimpressions";
      return this.metrics?.[statType]?.[this.currChartSrc]?.uniques || 0;
    },
    totalUsersText() {
      return this.totalUsers ? toLocaleNumberString(this.totalUsers) : "N/A";
    },
    totalImpressions() {
      return getTotals(this.metrics, "impressions", this.currChartSrc);
    },
    impressionsLabel() {
      return isEmail(this.model) ? "Sends" : "Impressions";
    },
    totalImpressionsText() {
      if (!this.totalImpressions) return "N/A";
      this.$emit("getTotalImpressions", this.totalImpressions);
      return toLocaleNumberString(this.totalImpressions);
    },
    totalGoals() {
      return getTotals(this.metrics, "goals", this.currChartSrc);
    },
    totalGoals2() {
      return getTotals(this.metrics, "goals2", this.currChartSrc);
    },
    totalCustomGoals() {
      if (this.isInvisible)
        return (
          (this.hasCustomGoal
            ? this.metrics?.custom_goals_accepted[this.currChartSrc]?.uniques
            : this.metrics?.goals[this.currChartSrc]?.uniques) || 0
        );
      return getTotals(this.metrics, "custom_goals_seen", this.currChartSrc);
    },
    totalGoalsText() {
      return this.totalGoals ? toLocaleNumberString(this.totalGoals) : "N/A";
    },
    hasData() {
      return !!this.getMetrics.find(metricType => {
        if (!metricType) return false;
        const metricData = metricType[this.currChartSrc] || {};
        const dataPerTimeUnit = metricData.data || [];
        return !!dataPerTimeUnit.find(time => !!time.count);
      });
    },
    getMetrics() {
      if (Object.keys(this.metrics).length === 0) return [];
      const additionalMetrics = [];
      if (this.hasCustomGoal) {
        additionalMetrics.push(
          this.isInvisible ? this.metrics.custom_goals_accepted : this.metrics.custom_goals_seen,
        );
      }
      const goals = this.metrics[interactionDenominators.accepted];

      const chartData = this.isInvisible ? [goals] : [this.metrics.uimpressions];
      return [...chartData, goals, ...additionalMetrics];
    },
    promptInteractionMetrics() {
      if (!this.metrics || !this.currChartSrc) return null;

      const pieData = Object.entries(this.metrics)
        .filter(this.onlyDisplayedMetrics)
        .map(([key, value]) => [
          this.namingMapperMetrics(key),
          value[this.currChartSrc] ? value[this.currChartSrc].uniques : 0,
        ]);
      let hasData = false;
      pieData.forEach(row => {
        if (row[1] > 0) hasData = true;
      });
      if (!hasData) return null;
      return [TITLE_ROW, ...pieData];
    },
    customGoalMetrics() {
      if (!this.metrics || !this.currChartSrc) return null;

      const pieData = Object.entries(this.metrics)
        .filter(this.onlyDisplayedMetricsCustom)
        .map(([key, value]) => [
          this.namingMapperMetricsCustom(key),
          value[this.currChartSrc] ? value[this.currChartSrc].uniques : 0,
        ]);

      return [TITLE_ROW_CUSTOM, ...pieData];
    },
  },
  methods: {
    ...mapActions(["getPathMetrics", "getPathCustomMetrics"]),
    fetchMetrics(timePeriod) {
      this.singleDayCustom = false;
      if (timePeriod === "since_start_date" && this.model.start_date) {
        const now = new Date();
        const datestring = `${now.getFullYear()}-${`0${now.getMonth() + 1}`.slice(
          -2,
        )}-${`0${now.getDate()}`.slice(-2)}`;
        this.singleDayCustom = datestring === this.model.start_date;
      }

      if (this.compareChartSrc) {
        this.compareChartSrc = MetricsUtils.Comparisons[timePeriod];
      }
      if (!this.metrics[timePeriod]) {
        let metric_periods = null;

        if (this.is_compare_enabled && MetricsUtils.Comparisons[timePeriod]) {
          metric_periods = [
            { period: timePeriod },
            { period: MetricsUtils.Comparisons[timePeriod] },
          ];
        } else {
          metric_periods = [{ period: timePeriod }];
        }

        const attrs = {
          appId: this.$route.params.aid,
          id: this.$route.params.pid,
          params: { metric_periods },
        };
        if (!attrs.id) return;

        this.getPathMetrics(attrs);
        this.isSurvey &&
          this.getPathCustomMetrics({
            ...attrs,
            params: { ...attrs.params, metric_keys: [PATH_CUSTOM_METRIC_SURVEY] },
          });
      }
      this.currChartSrc = timePeriod;
    },
    fetchMetricsCustom(timePeriod) {
      if (timePeriod.length) {
        this.singleDayCustom = timePeriod[0] === timePeriod[1];

        const attrs = {
          appId: this.$route.params.aid,
          id: this.$route.params.pid,
          params: { metric_periods: [{ period: "custom", date_range: timePeriod }] },
        };

        if (!attrs.id) return;

        this.getPathMetrics(attrs);
        this.isSurvey &&
          this.getPathCustomMetrics({
            ...attrs,
            params: { ...attrs.params, metric_keys: [PATH_CUSTOM_METRIC_SURVEY] },
          });
      }
      this.currChartSrc = "custom";
    },
  },
  mounted() {
    const filter = this.getUserSetting(this.compareKey);
    if (filter) {
      this.compareChartSrc = filter;
    }
  },
  watch: {
    compareChartSrc(to, from) {
      if (to !== from) {
        this.setUserSetting(this.compareKey, to);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.promo-tab0--survey {
  grid-area: survey;
}
</style>
