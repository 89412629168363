<template>
  <div class="inline-flex items-center gap-3">
    <label v-if="title" class="label" :for="`input${_uid}`">
      {{ title }}
    </label>
    <button
      :id="`input${_uid}`"
      class="button"
      :class="{ 'button--on': value }"
      role="switch"
      type="button"
      :aria-checked="value"
      @click="$emit('input', !value)"
    >
      <span class="knob" :class="{ 'knob--on': value }" aria-hidden="true"> </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: [String, Boolean], default: false },
    value: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.label {
  @apply text-buttons;
  @apply text-grey-1 #{!important};
}

.button {
  @apply cursor-pointer bg-blue-3 rounded-full flex-shrink-0;
  width: 38px;
  height: 25px;
  transition: background 100ms ease-in-out;

  &--on {
    @apply bg-blue-1;
  }

  &:focus {
    outline: none;
  }
}

.knob {
  @apply bg-white rounded-full block;
  width: 21px;
  height: 21px;
  transition: transform 300ms ease-in-out;
  transform: translateX(2px);
  box-shadow: 0px 3px 9px 0px rgba(92, 104, 124, 0.16);

  &--on {
    transform: translateX(calc(100% - 10.5px + 4px));
  }
}
</style>
