<template>
  <div class="api-website-action-div">
    <RfRetentionActionsThirdParty
      :actions="actions"
      :model="model"
      :isDisabled="isDisabled"
      :action_group_connector_actions="model.action_group_connector_actions"
      :action_type="actionType"
      ref="thirdParty"
    />
  </div>
</template>
<script>
import RfRetentionActionsThirdParty from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsThirdParty.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfRetentionActions",
  props: ["model", "isDisabled", "actionType"],
  components: { RfRetentionActionsThirdParty },
  data() {
    return {
      actions: this.cloneActions(this.model),
    };
  },
  methods: {
    fillModel(model) {
      model.actions = this.actions;
      if (this.$refs.thirdParty) {
        this.$refs.thirdParty.fillModel(model);
      }

      ["web", "ios", "tv", "android_os"].forEach(os => {
        if (this.$refs[os]) {
          this.$refs[os].fillModel(model);
        }
      });
    },
    cloneActions(model) {
      return model ? cloneDeep(model.actions) : {};
    },
  },
  watch: {
    model(to) {
      this.actions = this.cloneActions(to);
    },
  },
};
</script>
