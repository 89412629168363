<template>
  <div class="pd-segments-comp">
    <RfPromoDetailsInfoRow title="Zone">
      <template v-slot:promodetail>
        <div style="margin-left: -5px" v-if="zone">
          <span>
            <router-link :to="zoneUrl">{{ zone.name }}</router-link>
          </span>
        </div>
        <div v-else>N/A</div>
      </template>
    </RfPromoDetailsInfoRow>
  </div>
</template>
<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";

export default {
  name: "RfPromoZoneInfo",
  props: ["path"],
  components: { RfPromoDetailsInfoRow },
  data() {
    return {
      zone: this.path.path_group,
    };
  },
  computed: {
    zoneUrl() {
      return { path: `/apps/${this.$route.params.aid}/placements/${this.zone.id}` };
    },
  },
};
</script>
