<template>
  <div class="rf-metrics-cohort-stats">
    <RfCohortCard :currApp="currApp" :currChartSrc="currChartSrc" :preSelectedPath="currPathId">
      <template v-slot:title>
        <div class="rf-metrics-card-name">
          <span>Retention</span>
        </div>
      </template>
    </RfCohortCard>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RfCohortCard from "../../../components/RfAppDashboard/RfCohortCard.vue";

export default {
  name: "RfRetentionStats",
  components: {
    RfCohortCard,
  },
  computed: {
    ...mapState({
      currApp: state => state.apps.currApp,
    }),
    currChartSrc() {
      if (this.$refs.stats) {
        return this.refs.currChartSrc || "last_seven_days";
      }
      return "last_seven_days";
    },
    currPathId() {
      return this.$route.params.pid;
    },
  },
};
</script>
