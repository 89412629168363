<template>
  <div class="modal-overlay-style" :class="modalWrapperSize" :style="overlayBackgroundColor">
    <div
      class="rfmodal-content outer-modal rf-device-web rf-modal-content-wrapper center-modal"
      :style="modalWrapperStyle"
    >
      <div class="rfmodal-wrapper inner-modal" ref="rfmodal">
        <RfRetentionMessage
          :actions="actions"
          :isNewDeviceEditor="isNewDeviceEditor"
          :isMobile="isMobile"
        />
      </div>
      <div :style="containerBackgroundImage" class="rfmodal-backgroundimage"></div>
    </div>
  </div>
</template>

<script>
import RfRetentionMessage from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessage.vue";

export default {
  name: "RfRetentionModalContent",
  components: { RfRetentionMessage },
  props: {
    actions: Object,
    deviceType: String,
    model: Object,
    zoomStyle: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    containerBackgroundImage() {
      const styles = {
        "background-image": "url(https://assets.redfastlabs.com/images/bg/9.jpg)",
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] = `url(${this.actions.rf_settings_bg_image})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    modalWrapperSize() {
      if (this.deviceType === "ipadLandscape") {
        return `retention-modal-preview-ipad${this.isNewDeviceEditor ? " new" : ""}`;
      }
      if (this.deviceType === "ipadPortrait") {
        return "retention-modal-preview-ipad-portrait";
      }
      if (this.deviceType === "tv_os") {
        return `retention-modal-preview-tvos${this.isNewDeviceEditor ? " new" : ""}`;
      }
      if (this.deviceType === "desktop") {
        return `div--${this.actions.rf_settings_window_max_width}`;
      }
      if (this.deviceType === "androidLandscape") {
        return "retention-modal-preview-android-tablet";
      }
      if (this.deviceType === "androidPortrait") {
        return "retention-modal-preview-android-tablet-portrait";
      }
      if (this.deviceType === "androidPhone") {
        return "retention-modal-preview-android-phone";
      }
      if (this.deviceType === "androidSquare") {
        return "retention-modal-preview-android-square";
      }
      if (this.deviceType === "androidTV") {
        return "retention-modal-preview-android-tv";
      }
    },
    getNewEditorWidth() {
      if (!this.isNewDeviceEditor) return {};
      const dimensions =
        this.deviceType === "androidSquare"
          ? this.actions.rf_settings_bg_image_android_os_tablet_dims
          : this.deviceType === "androidTV"
            ? this.actions.rf_settings_bg_image_android_os_tv_dims
            : this.actions.rf_settings_bg_image_tv_os_tv_dims;

      if (!dimensions) return {};
      const [width, height] = dimensions.split("x");
      return { width: `${width}px`, height: `${height}px`, aspectRatio: "unset" };
    },
    modalWrapperStyle() {
      return {
        width: this.actions.rf_settings_window_max_width,
        ...this.getNewEditorWidth,
        ...this.zoomStyle,
      };
    },
    overlayBackgroundColor() {
      if (this.deviceType === "desktop")
        return {
          background: this.actions.rf_settings_overlay_background || "#252323B3",
        };
    },
  },
  methods: {
    getPreviewDimensions() {
      const htmlItem = this.$refs.rfmodal;
      if (htmlItem) {
        const { clientWidth, clientHeight } = htmlItem;
        if (clientWidth && clientHeight) {
          return `${clientWidth}x${clientHeight}`;
        }
      }
      return "";
    },
  },
  updated() {
    this.$nextTick(() => {
      this.dimensions = this.getPreviewDimensions();
    });
  },
  mounted() {
    setTimeout(() => {
      this.dimensions = this.getPreviewDimensions();
    }, 0);
  },
};
</script>
