var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo-design-group"},[(!_vm.isEmail)?_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.actions.rf_settings_privacy_policy_type),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_privacy_policy_type", $$v)},expression:"actions.rf_settings_privacy_policy_type"}},[_c('v-radio',{attrs:{"value":"link","label":"Simple"}}),_c('v-radio',{attrs:{"value":"long","label":_vm.pathTypeLinkOptionLabel}})],1):_vm._e(),(_vm.actions.rf_settings_privacy_policy_type === 'link')?_c('div',[_c('v-text-field',{staticStyle:{"margin-bottom":"14px"},attrs:{"outlined":"","dense":"","label":"Privacy Policy Link","placeholder":"e.g.: http://company.com/pagename","hide-details":""},model:{value:(_vm.actions.rf_settings_privacy_policy_link),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_privacy_policy_link", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_privacy_policy_link"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.pathTypeLabel,"hide-details":"","placeholder":"e.g.: http://company.com/pagename"},model:{value:(_vm.actions.rf_settings_tos_link),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_tos_link", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_tos_link"}})],1):_vm._e(),(_vm.actions.rf_settings_privacy_policy_type === 'long')?_c('div',[_c('v-textarea',{attrs:{"hide-details":"","outlined":"","dense":"","label":_vm.pathTypeLabelHtml,"disabled":_vm.currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text'),"rows":"3"},model:{value:(
        _vm.currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')
          ? _vm.previewActions.rf_settings_privacy_policy_text
          : _vm.actions.rf_settings_privacy_policy_text
      ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')
          ? _vm.previewActions.rf_settings_privacy_policy_text
          : _vm.actions, "rf_settings_privacy_policy_text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n        currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')\n          ? previewActions.rf_settings_privacy_policy_text\n          : actions.rf_settings_privacy_policy_text\n      "}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }