<template>
  <span class="rf-tag">
    <slot></slot>
    <button
      v-if="this.$listeners.clear"
      class="flex items-center justify-center"
      @click="$emit('clear')"
    >
      <RfCloseTagIcon class="!h-6 !w-6" />
    </button>
  </span>
</template>

<script>
import RfCloseTagIcon from "@/components/icons/RfCloseTagIcon.vue";

export default {
  props: { clear: { type: Boolean, default: false } },
  components: { RfCloseTagIcon },
};
</script>

<style lang="scss" scoped>
.rf-tag {
  @apply bg-white text-grey-1 text-body py-1 px-3 rounded-2xl gap-1 flex items-center whitespace-nowrap;
}
</style>
