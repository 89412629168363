<template>
  <div class="flex flex-col rounded bg-white p-5" :class="{ 'gap-3': opened }">
    <button class="flex items-center justify-between" @click="opened = !opened">
      <h2 class="text-heading-1 !text-black-1">History</h2>
      <Transition mode="out-in">
        <RfChevronMiniClosedIcon v-if="!opened" class="my-auto !h-7 !w-7" />
        <RfChevronMiniOpenIcon v-else class="my-auto !h-7 !w-7" />
      </Transition>
    </button>
    <div
      class="grid grid-rows-0fr transition-grid-template-rows duration-500"
      :class="{ 'grid-rows-1fr': opened }"
    >
      <div class="flex flex-col gap-3 overflow-hidden">
        <div v-for="entry in history" class="flex items-start gap-2">
          <template v-if="entry.error">
            <RfErrorStatusIcon class="!h-6 !w-6 flex-shrink-0" />
            <div class="flex items-start gap-1">
              <div class="text-action-buttons flex flex-grow items-center gap-4 !text-black-2">
                <button
                  class="text-heading-2 inline-flex items-center gap-2 !text-blue-1"
                  @click="emit('open', entry)"
                >
                  {{ entry.error }}
                  <RfLinkIcon class="!h-6 !w-6 !stroke-blue-1" />
                </button>
                <div
                  class="text-body w-40 truncate !text-grey-2"
                  :title="entry.parsed_message.user_id"
                >
                  User ID: {{ entry.parsed_message.user_id }}
                </div>
              </div>
            </div>
            <div class="text-body min-w-44 !text-grey-2">
              {{ dayjs(entry.timestamp * 1000).format("MMM Do YYYY, h:mm a") }}
            </div>
          </template>
          <template v-else>
            <component
              v-bind="getActionComponent(entry.action)"
              class="!h-6 !w-6 flex-shrink-0 rounded-full"
            />
            <div class="flex items-start gap-1">
              <div class="text-action-buttons flex-grow !text-black-2">
                <template v-if="entry.summary">
                  {{ `${entry.display_object_type}: ` }}
                  <span v-if="entry.object_name" class="font-bold">
                    {{ entry.object_name }}
                  </span>
                  {{ ` ${entry.summary}` }}
                </template>
                <template v-else-if="entry.object_type !== 'Path'">
                  {{ `${entry.display_object_type} was ${entry.display_action}` }}
                </template>
                <template v-else>
                  Prompt {{ `${entry.action}d${entry.updated_fields?.length ? ":" : ""}` }}
                  <span v-if="entry.updated_fields?.length" class="font-bold">
                    {{ entry.updated_fields.join(", ") }}
                  </span>
                </template>
                by {{ entry.name }}
              </div>
              <div class="text-body min-w-44 !text-grey-2">
                {{ dayjs(entry.time).format("MMM Do YYYY, h:mm a") }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import RfChevronMiniClosedIcon from "@/components/icons/RfChevronMiniClosedIcon.vue";
import RfChevronMiniOpenIcon from "@/components/icons/RfChevronMiniOpenIcon.vue";
import RfErrorStatusIcon from "@/components/icons/RfErrorStatusIcon.vue";
import RfLinkIcon from "@/components/icons/RfLinkIcon.vue";
import RfActionError from "@/components/statuses/RfActionError.vue";
import RfActionInfo from "@/components/statuses/RfActionInfo.vue";
import RfActionSuccess from "@/components/statuses/RfActionSuccess.vue";
import dayjs from "dayjs";
import { computed, ref } from "vue";

const emit = defineEmits(["open"]);
const props = defineProps(["activities", "connectorErrors"]);

const history = computed(() =>
  [...(props.activities || []), ...(props.connectorErrors || [])].sort(
    (a, b) => new Date(b.time || b.timestamp * 1000) - new Date(a.time || a.timestamp * 1000),
  ),
);

const opened = ref(true);

const getActionComponent = action => {
  if (action === "create") return { is: RfActionSuccess, title: "created" };
  if (action === "destroy") return { is: RfActionError, title: "deleted" };
  return { is: RfActionInfo, title: "updated" };
};
</script>
