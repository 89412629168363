<template>
  <div class="pd-segments-comp">
    <RfPromoDetailsInfoRow title="Pipeline">
      <template v-slot:promodetail>
        <div v-if="path.pipeline_id">
          <span>
            <router-link :to="pipelineUrl(path.pipeline_id)">
              {{ path.pipeline_name }}
            </router-link>
          </span>
        </div>
        <div v-else>
          <div>N/A</div>
        </div>
      </template>
    </RfPromoDetailsInfoRow>
  </div>
</template>
<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";

export default {
  name: "RfPromoPipelineInfo",
  props: ["path"],
  components: { RfPromoDetailsInfoRow },
  methods: {
    pipelineUrl(id) {
      return { path: `/apps/${this.$route.params.aid}/pipelines/${id}` };
    },
  },
};
</script>
