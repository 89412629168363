<template>
  <div class="list-promo-stats">
    <div class="list-promo-stats-ctr">
      <div class="list-promo-charts-tabs">
        <div class="py-6"></div>
      </div>
      <div>
        <div class="all-segment-stat promotion-stat">
          <div class="all-segment-stat-single promotion-impr-num">
            <span class="stat-label stat-label-impressions">
              <span class="sml-box"></span> <span>Users</span>
            </span>
            <span class="stat-value">{{ toLocaleNumberString(totalData.users) }} </span>
          </div>
          <div class="all-segment-stat-single promotion-clicks-num">
            <span class="stat-label stat-label-clicks">
              <span class="sml-box"></span> <span>Clicks</span>
            </span>
            <span class="stat-value">{{ toLocaleNumberString(totalData.goals) }}</span>
          </div>
          <div class="all-segment-stat-single promotion-ctr-num">
            <span class="stat-label">Conv. Rate</span>
            <span class="stat-value"> {{ `${totalData.convRate.toLocaleString()}%` }}</span>
          </div>
        </div>
        <GChart
          v-if="buildChartData"
          :data="buildChartData"
          :options="formatComboChartOptions"
          :create-chart="createComboChart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { statsAggregator } from "@/utils/metricsHelpers";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { createComboChart } from "@/utils/vueGoogleChartUtils";
import dayjs from "dayjs";

export default {
  name: "RfSingleSequenceStats",
  props: ["model", "metricsKey", "singleDayCustom", "isSurvey"],
  setup: () => ({ createComboChart, toLocaleNumberString }),
  computed: {
    data() {
      return statsAggregator(this.model, this.metricsKey);
    },
    hasMetric() {
      const foundMetric = Object.entries(this.data).find(([_, v]) => v[this.metricsKey]);
      return !!foundMetric && this.data;
    },
    totalData() {
      if (!this.model?.length) return { users: 0, goals: 0, convRate: 0 };

      const allPrompts = [...this.model];
      const index = this.isSurvey
        ? allPrompts.findIndex(el => el?.actions?.rf_retention_survey_selected)
        : 0;
      const firstPrompt = allPrompts.splice(index, 1)[0];

      if (
        !firstPrompt?.data?.uimpressions?.[this.metricsKey] ||
        !firstPrompt?.data?.goals?.[this.metricsKey]
      )
        return { users: 0, goals: 0, convRate: 0 };

      const users = firstPrompt.data.uimpressions[this.metricsKey].uniques || 0;
      const goals = allPrompts.reduce((a, e) => a + e.data.goals[this.metricsKey].uniques, 0) || 0;
      const convRate = !users ? 0 : ((goals / users) * 100).toFixed(2);
      return { users, goals, convRate };
    },
    formatComboChartOptions() {
      let maxWindowVal = 100;
      if (this.hasMetric) {
        const currentMetricValues = Object.entries(this.data).reduce((total, [_, v]) => {
          const vals = (v[this.metricsKey].data || []).map(item => item.count);
          return total.concat(vals);
        }, []);
        const chartCeilingRounder = 10;
        const value = Math.max(...currentMetricValues, chartCeilingRounder);
        maxWindowVal = Math.ceil(value / chartCeilingRounder) * chartCeilingRounder;
      }

      const opts = {
        height: 220,
        width: "100%",
        animation: { duration: 1000, easing: "inAndOut", startup: true },
        tooltip: { isHtml: true },
        backgroundColor: { fill: "transparent" },
        legend: { position: "none" },
        colors: ["#70B9F7", "#67AC5C"],
        vAxes: {
          0: {
            format: "short",
            viewWindow: { min: 0, max: maxWindowVal },
            baselineColor: "#ccc",
            gridlines: { color: "#eee", count: 3 },
            minorGridlines: { color: "#eee", count: 0 },
          },
        },
        focusTarget: "category",
        pointSize: 10,
        bar: { groupWidth: "90%" },
        seriesType: "bars",
        series: {
          1: {
            type: "line",
            curveType: "function",
            format: "# $",
            targetAxisIndex: 0,
          },
        },
        chartArea: { left: 70, top: 25, right: 10, width: "85%", height: "80%" },
        enableInteractivity: false,
      };

      if (
        ["this_month", "last_month", "today", "yesterday"].includes(this.metricsKey) ||
        this.singleDayCustom
      ) {
        opts.hAxis = { maxAlternation: 1, showTextEvery: 5 };
      }

      return opts;
    },
    buildChartData() {
      if (!this.hasMetric)
        return [
          ["", ""],
          ["N/A", 0],
          ["N/A", 0],
          ["N/A", 0],
          ["N/A", 0],
          ["N/A", 0],
          ["N/A", 0],
          ["N/A", 0],
        ];

      const dataSlct = [this.data.uimpressions, this.data.goals];

      const currentMetricValues = Object.entries(dataSlct).reduce((total, [_, v]) => {
        const vals = (v[this.metricsKey].data || []).map(item => item.count);
        return total.concat(vals);
      }, []);
      if (!currentMetricValues.length) return;
      const chartCeilingRounder = 10;
      const value = Math.max(...currentMetricValues, chartCeilingRounder);
      const maxWindowVal = Math.ceil(value / chartCeilingRounder) * chartCeilingRounder;
      const columns = Object.keys(dataSlct).length + 1;
      const header = new Array(columns).fill("");
      const rows = Object.entries(dataSlct).map(([_, v]) => v[this.metricsKey].data);

      const transposeRows = rows[0].map((_, i) =>
        rows.map(row => {
          let colVal = row[i].count;

          if (colVal === 0) colVal = maxWindowVal / 40;
          else if (colVal === null) colVal = 0;

          return { ...row[i], count: colVal };
        }),
      );
      const datedRows = transposeRows.map(row => {
        const text = dayjs(`${row[0].date}`, "YYYYMMDD").utc(true);
        const rowItems = row.map(item => item.count);

        const format =
          ["today", "yesterday"].includes(this.metricsKey) || this.singleDayCustom ? "hA" : "MM/DD";
        return [text.format(format), ...rowItems];
      });
      return [header, ...datedRows];
    },
  },
};
</script>
