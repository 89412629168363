<template>
  <v-card>
    <v-card-title class="headline">{{ editTitle }}</v-card-title>
    <v-card-text v-if="editingTrigger">
      <RfTextInput
        outlined
        dense
        v-model.trim="editingTrigger.name"
        title="Name"
        :disabled="isReadOnlyRoleMixin"
        placeholder="e.g.: All Pages"
        hide-details
      />
      <div class="trigger-modal-detail-row">
        <div>Category:</div>
        <v-radio-group
          row
          v-model="triggerType"
          :disabled="isReadOnlyRoleMixin || !!parentTriggerType || !!editingTrigger.id"
        >
          <v-radio label="web" value="web" />
          <v-radio label="device" value="device" />
        </v-radio-group>
      </div>
      <div class="trigger-settings-div">
        <div class="trigger-setting-page">
          <v-radio-group hide-details v-model="anyPageSelected" :disabled="isReadOnlyRoleMixin">
            <v-radio :value="true" :label="'Any ' + settings.url_path" />
            <v-radio :value="false" :label="'Specific ' + settings.url_path" />
          </v-radio-group>
          <div class="trigger-specific-pg-opt" v-if="!anyPageSelected">
            <RfTextInput
              outlined
              dense
              hide-details
              :title="settings.url_path_label"
              :disabled="isReadOnlyRoleMixin"
              :placeholder="settings.url_path_placeholder"
              v-model.trim="editingTrigger.url_path"
              background-color="#ffffff"
            />
            <template v-if="triggerType !== 'device'">
              <RfTextInput
                outlined
                dense
                hide-details
                :disabled="isReadOnlyRoleMixin"
                :title="settings.query_params_label"
                :placeholder="settings.query_params_placeholder"
                v-model.trim="editingTrigger.query_params"
                background-color="#ffffff"
              />
              <RfTextInput
                outlined
                dense
                hide-details
                :disabled="isReadOnlyRoleMixin"
                :title="settings.url_hash_label"
                :placeholder="settings.url_hash_placeholder"
                v-model.trim="editingTrigger.url_hash"
                background-color="#ffffff"
              />
            </template>

            <div class="use-reg-expression-opt" v-if="regexAllowed">
              <div class="d-flex align-center">
                <div class="use-reg-title">Use regular expression:</div>
                <v-radio-group
                  hide-details
                  class="ml-7 mt-0 pt-0"
                  :disabled="isReadOnlyRoleMixin"
                  v-model="editingTrigger.use_regex"
                  row
                >
                  <v-radio :value="true" label="Yes" />
                  <v-radio :value="false" label="No" />
                </v-radio-group>
              </div>
              <div class="use-reg-help-txt" v-if="editingTrigger.use_regex">
                Wildcards (*) are not supported
              </div>
              <div class="use-reg-help-txt" v-else>Wildcards (*) are supported</div>
              <div v-if="canShowTester && !isReadOnlyRoleMixin">
                <button class="use-reg-btn" @click.stop="showRegexTestModal = true">
                  {{ testerLabel }}
                </button>
                <v-dialog v-model="showRegexTestModal" max-width="550" width="550">
                  <RfRegexTester
                    :testType="testType"
                    :urlPath="editingTrigger.url_path"
                    :urlHash="editingTrigger.url_hash"
                    :queryParams="editingTrigger.query_params"
                    v-on:close="showRegexTestModal = false"
                    :useRegex="editingTrigger.use_regex"
                  />
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
        <div class="trigger-setting-clicks">
          <v-switch
            hide-details
            inset
            v-model="clickEnabled"
            label="Use click trigger"
            :disabled="isReadOnlyRoleMixin"
          ></v-switch>
          <v-card elevation="4" class="trigger-setting-clicks-cnt">
            <v-card-title v-if="!clickEnabled" class="cnt-txt">Timer setting:</v-card-title>
            <v-card-text class="pt-5">
              <template v-if="clickEnabled">
                <RfTextInput
                  outlined
                  dense
                  :disabled="isReadOnlyRoleMixin"
                  :title="settings.click_id_label"
                  placeholder="e.g. #cancel"
                  v-model="editingTrigger.click_id"
                />
                <RfSelect
                  outlined
                  dense
                  :disabled="isReadOnlyRoleMixin"
                  label="Show after"
                  placeholder="Select clicks count"
                  v-model="editingTrigger.click_count_threshold"
                  :items="clickCountOptions"
                  v-if="clickEnabled && triggerType !== 'device'"
                />
              </template>
              <template v-else>
                <RfTextInput
                  title="After number of seconds (optional)"
                  v-model="editingTrigger.delay_seconds"
                  type="number"
                  outlined
                  required
                  dense
                  :disabled="this.clickEnabled || isReadOnlyRoleMixin"
                >
                </RfTextInput>
              </template>
              <RfSelect
                outlined
                dense
                :disabled="isReadOnlyRoleMixin"
                title="Advanced"
                v-model="editingTrigger.custom_trigger_id"
                :items="customTriggers"
                v-if="
                  triggerType !== 'device' ||
                  (triggerType === 'device' && app?.flags?.custom_devices_js_sdk)
                "
              />
            </v-card-text>
          </v-card>

          <div v-if="editingTrigger.custom_trigger_id">
            <v-switch
              hide-details
              inset
              v-model="useEventInterval"
              :disabled="isReadOnlyRoleMixin"
              label="Event-based"
            ></v-switch>
            <v-card v-if="!useEventInterval" elevation="4" class="trigger-setting-clicks-cnt">
              <v-card-text class="py-5">
                <RfTextInput
                  outlined
                  :disabled="isReadOnlyRoleMixin"
                  dense
                  hide-details
                  type="number"
                  title="Advanced Interval (seconds)"
                  v-model="editingTrigger.custom_trigger_seconds"
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="modal-card-actions-div">
      <v-btn large depressed @click="closeNewTriggerDialog" class="cancel-btn px-5" outlined>
        {{ !isDisabledRoleMixin ? "Cancel" : "Close" }}
      </v-btn>
      <RfButton
        :button-text="submitCTA"
        color="success"
        class="px-10"
        large
        :disabled="isDisabledRoleMixin || !submitEnabled"
        @click="submitTrigger"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfRegexTester from "@/components/RfCommonCards/RfRegexTester.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";

export default {
  name: "RfEditTrigger",
  props: ["editTrigger", "creating", "app", "parentTriggerType"],
  mixins: [RoleMixin],
  components: { RfRegexTester, RfTextInput, RfSelect, RfButton },
  data() {
    return {
      editingTrigger: this.editTrigger,
      clickEnabled: false,
      anyPageSelected: true,
      testString: "",
      showRegexTestModal: false,
      descriptions: {
        web: {
          url_path: "page",
          url_path_label: "Page url that triggers the prompt",
          url_path_placeholder: "/settings",
          click_id_label: "Query selector",
          click_id_placeholder: "#cancel-button",
          query_params_label: "Query parameters that trigger the prompt",
          query_params_placeholder: "version=2020",
          url_hash_label: "Url hash that triggers the prompt",
          url_hash_placeholder: "#profile",
        },
        device: {
          url_path: "screen",
          url_path_label: "View controller where the prompt appears",
          url_path_placeholder: "ViewController",
          click_id_label: "Button name",
          click_id_placeholder: "cancel",
        },
      },
      eventInterval: 7200,
      useEventInterval: false,
      triggerType: this.parentTriggerType || "web",
      clickCountOptions: Array(20)
        .fill()
        .map((_, index) => {
          const number = index + 1;
          const label = number === 1 ? "click" : "clicks";
          return {
            value: number,
            text: `${number} ${label}`,
          };
        }),
    };
  },
  computed: {
    ...mapState({
      clientActions: state => state.apps.clientActions || [],
    }),
    newTrigger() {
      const trigger = {
        url_path: "*",
        use_regex: false,
        click_id: null,
        delay_seconds: 0,
        trigger_type: this.triggerType,
      };
      if (this.triggerType === "web") {
        trigger.click_count_threshold = 0;
        trigger.custom_trigger_id = null;
        trigger.custom_trigger_seconds = 2;
        trigger.query_params = null;
        trigger.url_hash = null;
      }
      return trigger;
    },
    settings() {
      return this.descriptions[this.triggerType] || this.descriptions.web;
    },
    submitCTA() {
      return this.creating ? "Create Trigger" : "Update Trigger";
    },
    submitEnabled() {
      if (!this.editingTrigger) return false;
      let requirementsFilled =
        !!this.editingTrigger.url_path ||
        !!this.editingTrigger.query_params ||
        !!this.editingTrigger.url_hash;
      if (this.clickEnabled) {
        requirementsFilled = requirementsFilled && !!this.editingTrigger.click_id;
        if (this.triggerType !== "device") {
          requirementsFilled = requirementsFilled && !!this.editingTrigger.click_count_threshold;
        }
      }

      if (this.editingTrigger.custom_trigger_id) {
        requirementsFilled =
          requirementsFilled &&
          (this.useEventInterval ||
            (!!this.editingTrigger.custom_trigger_seconds &&
              parseInt(this.editingTrigger.custom_trigger_seconds) > 0));
      }
      return requirementsFilled;
    },
    canShowTester() {
      const { url_path, query_params, url_hash } = this.editingTrigger;
      return (
        this.editingTrigger.use_regex ||
        (url_path && url_path.indexOf("*") >= 0) ||
        (query_params && query_params.indexOf("*") >= 0) ||
        (url_hash && url_hash.indexOf("*") >= 0)
      );
    },
    testType() {
      return this.editingTrigger.use_regex ? "Regular" : "Wildcard";
    },
    editTitle() {
      return this.creating ? "Create Trigger" : "Update Trigger";
    },
    testerLabel() {
      return `Test your ${this.testType} expression`;
    },
    regexAllowed() {
      return this.triggerType !== "device";
    },
    customTriggers() {
      if (this.app) {
        const blank = [{ text: "None", value: "" }];
        const actions = this.clientActions
          .filter(action => action.action_type === "custom_trigger")
          .map(action => {
            return { text: action.name, value: action.id };
          });
        return blank.concat(actions);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["createTrigger", "updateTrigger", "getClientActions"]),
    async submitTrigger() {
      this.editingTrigger.trigger_type = this.triggerType;
      if (this.clickEnabled) {
        this.editingTrigger.delay_seconds = 0;
      } else {
        this.editingTrigger.click_id = null;
        if (this.triggerType !== "device") {
          this.editingTrigger.click_count_threshold = 0;
        }
      }
      if (this.anyPageSelected) {
        this.editingTrigger.url_path = "*";
        this.editingTrigger.use_regex = false;
        this.editingTrigger.query_params = null;
        this.editingTrigger.url_hash = null;
      }

      if (!this.editingTrigger.custom_trigger_id) {
        this.editingTrigger.custom_trigger_seconds = null;
      }
      if (this.useEventInterval && this.editingTrigger.custom_trigger_id) {
        this.editingTrigger.custom_trigger_seconds = this.eventInterval;
      }
      if (this.creating) {
        this.createTrigger({
          appId: this.app.id,
          newTrigger: this.editingTrigger,
        });
      } else {
        await this.updateTrigger({
          appId: this.app.id,
          triggerId: this.editingTrigger.id,
          model: this.editingTrigger,
        });
      }
      this.closeNewTriggerDialog(this.creating);
      this.triggerType = "web";
    },
    closeNewTriggerDialog(creating) {
      this.triggerType = "web";
      this.$emit("closeNewTriggerDialog", creating);
    },
  },
  watch: {
    anyPageSelected(to) {
      if (to) {
        this.editingTrigger.url_path = "*";
        this.editingTrigger.query_params = null;
        this.editingTrigger.url_hash = null;
      }
    },
    clickEnabled(to) {
      if (to && !this.editingTrigger.click_count_threshold) {
        this.editingTrigger.click_count_threshold = 1;
      }
    },
  },
  mounted() {
    if (!this.clientActions.length) this.getClientActions({ appId: this.app.id });

    if (this.creating) {
      this.clickEnabled = false;
      this.addNewTriggerDialog = true;
      this.anyPageSelected = true;
      this.clickEnabled = false;
      this.useEventInterval = false;
      this.editingTrigger = this.newTrigger;
    } else {
      this.useEventInterval = this.editingTrigger.custom_trigger_seconds === this.eventInterval;
      this.clickEnabled = !!this.editingTrigger.click_id;
      this.anyPageSelected =
        this.editingTrigger.url_path === "*" &&
        !this.editingTrigger.query_params &&
        !this.editingTrigger.url_hash;
      this.triggerType = this.editingTrigger.trigger_type;
    }
  },
};
</script>

<style lang="scss" scoped>
.use-reg-btn {
  color: #3096ed;
}
</style>
