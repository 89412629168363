<template>
  <div ref="topBar" class="rf-top-nav fixed left-0 top-0 z-20 w-full">
    <div data-rf-zone="app-notification-bar"></div>
    <div class="flex h-12 items-center px-4 py-1">
      <div class="rf-logo-top-left">
        <a href="/"><img class="img" alt="Redfast logo" src="../../../assets/images/logo.png" /></a>
      </div>
      <div class="flex-grow"></div>
      <template v-if="$vuetify.breakpoint.smAndUp">
        <div class="rf-top-menu-list flex flex-grow-0 items-center">
          <div class="rf-top-menu rf-top-menu-org-switch">
            <v-menu
              content-class="rf-top-dropdown-app"
              offset-y
              open-on-click
              close-on-content-click
              :disabled="appSwitcherDisabled"
              v-if="isSuperAdminRoleMixin"
            >
              <template v-slot:activator="{ on, value }">
                <button class="align-center body-text inline-flex" v-on="on">
                  {{ currOrg.name || "All" }}
                  <Transition mode="out-in">
                    <RfChevronMiniClosedIcon v-if="!value" class="!h-7 !w-7" />
                    <RfChevronMiniOpenIcon v-else class="!h-7 !w-7" />
                  </Transition>
                </button>
              </template>
              <v-list>
                <v-list-item
                  v-for="(org, index) in orgSelector"
                  :key="index"
                  @click="switchOrg(org.key)"
                  class="rf-topbar-list-item"
                  :class="currOrg.key === org.key ? 'org-selected' : ''"
                >
                  <v-list-item-title>
                    <span>{{ org.name }}</span>
                    <v-icon color="primary" size="18" v-if="currOrg.key === org.key"
                      >check_circle</v-icon
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="user-profile-slct" v-else>
              {{ currAppUserOrg.name }}
            </div>
          </div>
          <div class="rf-top-menu rf-top-menu-user">
            <v-menu content-class="rf-top-dropdown-user" offset-y>
              <template v-slot:activator="{ on, value }">
                <button class="align-center body-text inline-flex" v-on="on">
                  <RfAccountIcon class="!h-6 !w-6" :class="{ '!fill-red-1': isImpersonating }" />
                  <Transition mode="out-in">
                    <RfChevronMiniClosedIcon v-if="!value" class="!h-7 !w-7" />
                    <RfChevronMiniOpenIcon v-else class="!h-7 !w-7" />
                  </Transition>
                </button>
              </template>
              <v-list>
                <v-list-item class="rf-topbar-list-item" v-if="isSuperAdminRoleMixin">
                  <v-list-item-title>
                    <router-link :to="{ path: '/settings/company' }">Company Settings</router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="rf-topbar-list-item" v-if="isSuperAdminRoleMixin">
                  <v-list-item-title>
                    <router-link :to="{ path: '/settings/global' }">Global Settings</router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="rf-topbar-list-item">
                  <v-list-item-title>
                    <button @click="logoutUser()">Logout</button>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </template>
      <RfSwitch
        v-if="classicView.show"
        v-model="classicViewStore.classicView[classicView.key]"
        class="mx-4"
        title="Classic view"
        @input="v => setUserSetting(classicView.setting, v)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import RoleMixin from "@/utils/RoleMixin";
import RfSwitch from "@/components/inputs/RfSwitch.vue";
import RfAccountIcon from "@/components/icons/RfAccountIcon.vue";
import RfChevronMiniOpenIcon from "@/components/icons/RfChevronMiniOpenIcon.vue";
import RfChevronMiniClosedIcon from "@/components/icons/RfChevronMiniClosedIcon.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { useClassicViewStore } from "@/pinia/classicViewStore";
import { onMounted, onUnmounted, ref } from "vue";

export default {
  mixins: [RoleMixin, UserSettingsMixin],
  components: { RfSwitch, RfAccountIcon, RfChevronMiniClosedIcon, RfChevronMiniOpenIcon },
  setup: () => {
    const root = document.querySelector(":root");
    const topBar = ref();

    const resizeObserver = new ResizeObserver(([navbar]) =>
      root.style.setProperty("--main-margin-top", `${navbar.target.clientHeight}px` || "48px"),
    );

    onMounted(() => topBar.value && resizeObserver.observe(topBar.value));
    onUnmounted(() => resizeObserver.disconnect());

    return { topBar, classicViewStore: useClassicViewStore() };
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      currUser: state => state.apps.currUser,
      isImpersonating: state => state.apps.isImpersonating,
      loading: state => state.loading > 0,
      orgs: state => state.apps.orgs,
    }),
    ...mapGetters(["currOrg", "currAppUserOrg"]),
    orgSelector() {
      return [{ name: "All", key: null }, ...(this.orgs || [])];
    },
    appSwitcherDisabled() {
      return this.loading || this.$route.path === "/newapp";
    },
    classicView() {
      if (
        [
          "Retention Detail",
          "Pipeline's Retention Detail",
          "Guide's Retention Detail",
          "Placements's Retention Detail",
        ].includes(this.$route.name)
      )
        return {
          show: true,
          key: "prompt",
          setting: "promptClassicView",
        };

      return { show: false, key: null, setting: null };
    },
  },
  methods: {
    ...mapActions(["logoutUser", "getOrgs", "updateUser", "getUser"]),
    async switchOrg(orgKey) {
      this.currUser.settings[this.currApp.id].org_key = orgKey;
      await this.updateUser({ user: this.currUser });
      await this.getUser();
      const redirectPage = {
        "/apps/:aid/experiences/:sid": `/apps/${this.$route.params.aid}/experiences`,
        "/apps/:aid/pipelines/:pid": `/apps/${this.$route.params.aid}/pipelines`,
        "/apps/:aid/retentions/:pid": `/apps/${this.$route.params.aid}/prompts`,
      };
      const result = this.$route.matched.find(({ path }) => {
        const items = Object.keys(redirectPage);
        const res = items.find(item => path.match(item));
        return res;
      });
      if (result) {
        window.location.replace(redirectPage[result.path]);
      } else {
        window.location.reload();
      }
    },
  },
  watch: {
    currApp: {
      handler(to, from) {
        if (to?.id === from?.id) return;
        this.getOrgs(to.id);
        if (!this.classicView.show) return;
        this.classicViewStore.classicView[this.classicView.key] = this.getUserSetting(
          this.classicView.setting,
        );
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-top-nav {
  background-color: #f9f9f9;
  box-shadow: 0px 4px 9px rgba(220, 222, 226, 0.25);
  border-bottom: 1px solid #ddd;
}

.rf-logo-top-left {
  max-width: 110px;
  height: 22px;
}

.rf-top-menu-list {
  .rf-top-menu {
    align-items: center;
    justify-content: center;
    display: flex;

    button {
      color: #212121 !important;
    }
  }
  .rf-top-menu-org-switch,
  .rf-top-menu-app-switch {
    button {
      padding: 10px 16px 10px 0;
    }
  }
}
</style>
