<template>
  <div class="modal-overlay-style promo-video-outer-wrapper" :style="overlayBackgroundColor">
    <div
      class="rfmodal-content outer-modal video-promo-wrapper rf-device-web rf-modal-content-wrapper center-modal"
      :style="videoWinWidth"
    >
      <div class="rfmodal-wrapper inner-modal" ref="rfmodal">
        <RfRetentionMessage :actions="actions" isVideo />
      </div>

      <div class="rfmodal-backgroundimage">
        <video
          ref="promoVideoPlayer"
          :autoplay="actions.rf_settings_video_autoplayed"
          :loop="actions.rf_settings_video_loop"
          :muted="actions.rf_settings_video_muted"
          :key="backgroundVideoKey"
          @ended="endedPlaying"
          :poster="actions.rf_settings_video_poster"
          v-if="showVideo"
        >
          <source
            :src="backgroundVideo"
            :type="backgroundVideoType"
            v-if="actions.rf_settings_video_media_type !== 'm3u8'"
          />
          Your browser does not support the HTML5 Video element.
        </video>
        <img
          ref="promoVideoPoster"
          class="videoPosterImgDefault"
          :src="actions.rf_settings_bg_image"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RfRetentionMessage from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessage.vue";
import Hls from "hls.js";

export default {
  name: "RfRetentionVideoContent",
  props: ["actions", "deviceType", "zoomStyle"],
  components: { RfRetentionMessage },
  data() {
    return {
      showVideo: true,
      showPoster: false,
      hls: new Hls(),
    };
  },
  computed: {
    videoWinWidth() {
      return {
        width: this.actions.rf_settings_video_width,
        height: this.actions.rf_settings_video_height,
        ...this.zoomStyle,
      };
    },
    backgroundVideoKey() {
      if (
        this.actions.rf_settings_video_media_type.length === 0 ||
        this.actions.rf_settings_video_media_type === "m3u8"
      ) {
        return "staticKey";
      }
      return this.backgroundVideo;
    },
    backgroundVideo() {
      return this.actions.rf_settings_video_src;
    },
    backgroundVideoType() {
      return `video/${this.actions.rf_settings_video_media_type}`;
    },
    modalWrapperStyle() {
      if (this.actions.rf_settings_bg_image) {
        return {
          width: this.actions.rf_settings_window_max_width,
        };
      }
      return {
        "background-color": this.actions.rf_settings_bg_image_color,
        "width": this.actions.rf_settings_window_max_width,
      };
    },
    overlayBackgroundColor() {
      return {
        background: this.actions.rf_settings_overlay_background || "#252323B3",
      };
    },
  },
  methods: {
    getPreviewDimensions() {
      const htmlItem = this.$refs.rfmodal;
      if (htmlItem) {
        const { clientWidth, clientHeight } = htmlItem;
        if (clientWidth && clientHeight) {
          return `${clientWidth}x${clientHeight}`;
        }
      }
      return "";
    },
    endedPlaying() {
      this.$refs.promoVideoPlayer.classList.add("fadeOutVideo");
      this.$refs.promoVideoPoster.classList.add("fadeInPoster");
      this.showPoster = true;
    },
    attachHlsVideo(url) {
      this.$nextTick(() => {
        const mediaType = url.split(".").pop();
        if (mediaType === "m3u8") {
          const video = this.$refs.promoVideoPlayer;
          if (Hls.isSupported()) {
            this.hls.detachMedia();
            this.hls = new Hls();
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
              this.hls.loadSource(url);
            });
          } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = url;
            video.addEventListener("loadedmetadata", () => {
              video.play();
            });
          }
        }
      });
    },
  },
  watch: {
    "actions.rf_settings_video_src": function (to, from) {
      if (to && to.length && to !== from) {
        this.attachHlsVideo(to);
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      this.dimensions = this.getPreviewDimensions();
    });
  },
  mounted() {
    setTimeout(() => {
      this.dimensions = this.getPreviewDimensions();
    }, 0);
    this.attachHlsVideo(this.actions.rf_settings_video_src);
  },
};
</script>
