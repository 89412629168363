const StatusesConstants = {
  running: { type: "running", title: "Running" },
  ready: { type: "ready", title: "Scheduled" },
  paused: { type: "paused", title: "Paused" },
  pending: { type: "pending", title: "Draft" },
  ended: { type: "ended", title: "Ended" },
  limited: { type: "limited", title: "Limited" },
};

const StatusConstantTitleToObj = {
  Running: StatusesConstants.running,
  Ready: StatusesConstants.ready,
  Paused: StatusesConstants.paused,
  Pending: StatusesConstants.pending,
  Ended: StatusesConstants.ended,
  Limited: StatusesConstants.limited,
};

const StatusesSimplifiedConstants = {
  running: { type: "running", title: "Running", action: "Run" },
  paused: { type: "paused", title: "Paused", action: "Pause" },
  ended: { type: "ended", title: "Ended", action: "End" },
  ready: { type: "ready", title: "Scheduled", action: "Schedule" },
  limited: { type: "limited", title: "Limited" },
};

const SimplifyStatusesMapConstants = {
  [StatusesConstants.running.type]: StatusesSimplifiedConstants.running,
  [StatusesConstants.ready.type]: StatusesSimplifiedConstants.ready,

  [StatusesConstants.paused.type]: StatusesSimplifiedConstants.paused,
  [StatusesConstants.pending.type]: StatusesSimplifiedConstants.paused,

  [StatusesConstants.ended.type]: StatusesSimplifiedConstants.ended,

  [StatusesConstants.limited.type]: StatusesSimplifiedConstants.limited,
};

export { StatusesSimplifiedConstants, SimplifyStatusesMapConstants, StatusConstantTitleToObj };
export default StatusesConstants;
