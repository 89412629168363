import { saveToCsvFile } from "./CsvUtils";

export default {
  methods: {
    exportExperimentsToCsv(variations) {
      const headers = [
        "Variation",
        "Users",
        ...(this.customGoalInteraction !== "seen" ? ["Impressions"] : []),
        this.interactionsTitle,
        ...(this.shouldShowCTR ? [this.ctrTitle] : []),
        ...(this.hasCustomGoal ? ["Goal Completion"] : []),
        "Conversion Rate",
      ];
      const values = [];
      variations.forEach(variation => {
        values.push([
          variation.variantName || variation.name,
          `"${this.formatUsers(variation)}"`,
          ...(this.customGoalInteraction !== "seen"
            ? [`"${this.formatImpressions(variation)}"`]
            : []),
          `"${this.formatGoals(variation)}"`,
          ...(this.shouldShowCTR ? [`"${this.formatCTR(variation)}"`] : []),
          ...(this.hasCustomGoal ? [`"${this.formatCustomGoals(variation)}"`] : []),
          `"${this.formatConversion(variation)}"`,
        ]);
      });

      values.push([
        "Total",
        `"${this.totalUsersText || this.totalUsersData || ""}"`,
        ...(this.customGoalInteraction !== "seen"
          ? [`"${this.totalImpressionsText || this.totalImpressionsData || ""}"`]
          : []),
        `"${this.totalGoalsText || this.totalGoalsData || ""}"`,
        ...(this.shouldShowCTR ? [this.displayCTR == null ? "" : `"${this.displayCTR}"`] : []),
        ...(this.hasCustomGoal
          ? [`"${this.totalCustomGoalsText || this.totalCustomGoalsData || ""}"`]
          : []),
        "",
      ]);

      saveToCsvFile([headers, ...values], "experiment_export");
    },
  },
};
