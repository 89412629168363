<template>
  <div class="state-buttons">
    <div v-if="item.sequence_id">
      Inherited from <router-link :to="sequenceLink">{{ item.sequence_name }}</router-link>
    </div>
    <div class="promo-details-status-div" v-else>
      <RfPromoPublishStatus :item="item" />
      <div class="status-all-buttons">
        <RfButton
          v-if="promoStatusLabel === 'Scheduled' || promoStatusLabel === 'Pending'"
          small
          outlined
          color="success"
          icon="play_arrow"
          button-text="Start"
          :tooltip="{ bottom: true }"
          :disabled="isDisabledRoleMixin"
          @click="callPublishDialog('start')"
        />
        <RfButton
          v-if="promoStatusLabel === 'Paused'"
          small
          outlined
          color="success"
          icon="play_arrow"
          button-text="Resume"
          :tooltip="{ bottom: true }"
          :disabled="isDisabledRoleMixin"
          @click="callPublishDialog('resume')"
        />
        <RfButton
          v-if="!item.is_enabled && dayjs(item.start_date).isAfter(dayjs())"
          icon="mdi-clock-fast"
          button-text="Schedule"
          small
          outlined
          :tooltip="{ bottom: true }"
          color="info"
          :disabled="isDisabledRoleMixin"
          @click="callPublishDialog('ready')"
        />
        <RfButton
          v-if="promoStatusLabel === 'Running'"
          small
          outlined
          color="info"
          icon="pause"
          button-text="Pause"
          :tooltip="{ bottom: true }"
          :disabled="isDisabledRoleMixin"
          @click="callPublishDialog('pause')"
        />
        <RfButton
          v-if="canEnd"
          outlined
          small
          color="info"
          icon="stop"
          button-text="End"
          :tooltip="{ bottom: true }"
          :disabled="isDisabledRoleMixin"
          @click="callPublishDialog('end')"
        />
      </div>
    </div>
    <v-dialog v-model="promoPublishDialog" width="580">
      <v-card flat>
        <v-card-title class="headline">
          {{ `${capitalize(promoPublishDialogContent)} ${capitalize(itemType)}` }}
        </v-card-title>
        <v-card-text>
          <div>
            Are you sure you want to {{ promoPublishDialogContent }} this {{ itemType }}?
            {{ publishHelpText }}
          </div>
          <RfStartStopDatePicker
            v-if="promoPublishDialogContent === StatusesSimplifiedConstants.ready.type"
            ref="dateRangePicker"
            class="mt-4"
            :dateRange="{
              startDate: dayjs(item.start_date).utc().toISOString(),
              endDate: dayjs(item.end_date).utc().toISOString(),
            }"
            :actions="item.actions"
            :isPushNotification="isPushNotification(item)"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click.stop="promoPublishDialog = false"
            >Close</v-btn
          >
          <v-btn
            large
            depressed
            color="accent"
            width="150px"
            @click="submitPublish"
            class="submit-publish-button"
            >{{ capitalize(promoPublishDialogContent) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfPromoPublishStatus from "@/components/RfPromotions/RfPromoPublishStatus.vue";
import PromoStatusMixin from "@/utils/PromoStatusMixin";
import { mapActions } from "vuex";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import dayjs from "dayjs";
import { StatusesSimplifiedConstants } from "@/utils/constants/PromptStatusesConstants";
import RfStartStopDatePicker from "../RfStartStopDatePicker.vue";
import { isPushNotification } from "@/utils/prompts/promptHelpers";
import { ref } from "vue";

export default {
  name: "RfStateButtonsInterior",
  props: ["item"],
  components: { RfPromoPublishStatus, RfButton, RfStartStopDatePicker },
  mixins: [PromoStatusMixin, RoleMixin],
  setup: () => ({
    capitalize: StringUtils.capitalize,
    toastsStore: useToastsStore(),
    dayjs,
    StatusesSimplifiedConstants,
    isPushNotification,
    dateRangePicker: ref(),
  }),
  data: () => ({ promoPublishDialog: false, promoPublishDialogContent: null }),
  computed: {
    canEnd() {
      return (
        this.promoStatusLabel === "Running" ||
        this.promoStatusLabel === "Paused" ||
        this.promoStatusLabel === "Limited"
      );
    },
    itemType() {
      return this.item.subject_name === "Sequence" ? "guide" : "prompt";
    },
    promoStatusLabel() {
      return this.promoStatus(this.item).label;
    },
    publishHelpText() {
      let message;
      let subject = this.itemType;
      if (subject === "guide") {
        subject += " and its items";
      }
      switch (this.promoPublishDialogContent) {
        case "start":
        case "resume":
          message = `The ${subject} will run immediately.`;
          break;
        case "ready":
          message = `The ${subject} will run on the scheduled start date.`;
          break;
        case "end":
        case "pause":
          message = `The ${subject} will stop running immediately.`;
          break;
        default:
          message = "";
          break;
      }
      return message;
    },
    sequenceLink() {
      return { path: `/apps/${this.$route.params.aid}/experiences/${this.item.sequence_id}` };
    },
  },
  methods: {
    ...mapActions(["updatePath", "updateSequence"]),
    callPublishDialog(action) {
      this.promoPublishDialog = true;
      this.promoPublishDialogContent = action;
    },
    getToday() {
      const now = new Date();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${now.getFullYear()}-${month}-${day}`;
    },
    getYesterday() {
      const now = new Date();
      const yesterday = new Date(now);
      yesterday.setDate(yesterday.getDate() - 1);

      const month = String(yesterday.getMonth() + 1).padStart(2, "0");
      const day = String(yesterday.getDate()).padStart(2, "0");
      return `${yesterday.getFullYear()}-${month}-${day}`;
    },
    async submitPublish() {
      try {
        let isStarting = false;
        const copyItem = { ...this.item };
        switch (this.promoPublishDialogContent) {
          case "start":
            copyItem.is_enabled = true;
            copyItem.start_date = this.getToday();
            isStarting = true;
            break;
          case "end":
            copyItem.is_enabled = false;
            copyItem.end_date = this.getYesterday(); // yesterday
            break;
          case "resume":
          case "ready":
            copyItem.is_enabled = true;
            isStarting = true;
            break;
          case "pause":
            copyItem.is_enabled = false;
            break;
          default:
            break;
        }
        this.promoPublishDialog = false;

        if (this.promoPublishDialogContent === StatusesSimplifiedConstants.ready.type) {
          if (this.dateRangePicker) {
            const { fullStartDate, fullEndDate, frequency, localTime } = this.dateRangePicker;
            if (dayjs(fullStartDate) >= dayjs(fullEndDate))
              throw new Error("Start date must be before end date");

            copyItem.start_date = fullStartDate;
            copyItem.end_date = fullEndDate;
            if (frequency) copyItem.actions.rf_pinpoint_campaign_frequency = frequency;
            if (localTime) copyItem.actions.rf_pinpoint_campaign_local_time = localTime;
          }
        }

        if (this.itemType === "guide") {
          if (isStarting && !copyItem.paths.length) {
            this.toastsStore.create({
              type: "error",
              body: "Cannot start or resume a guide with no items",
            });
          } else {
            await this.updateSequence({
              appId: this.item.app_id,
              sequenceId: this.item.id,
              modelSequence: copyItem,
            });
            this.toastsStore.create({ type: "success", body: "Guide updated successfully" });
          }
        } else {
          await this.updatePath({
            appId: this.item.app_id,
            pathId: this.item.id,
            modelPath: copyItem,
          });
          this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
        }
      } catch (_) {}
    },
  },
};
</script>
