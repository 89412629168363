<template>
  <div class="trigger-configuration-wrapper">
    <div class="add-user-header-div">
      <div class="add-user-title">
        <div class="action-provider-settings-title">
          <h4>When should it be initiated</h4>
          <div style="display: block" v-html="helpText"></div>
        </div>
      </div>
      <div style="text-align: right">
        <v-btn
          depressed
          outlined
          color="success"
          @click="showCreateTriggerDialog"
          :disabled="isDisabled"
          style="margin-right: 12px"
        >
          <v-icon left size="15px">edit</v-icon>
          <span>Create new trigger</span>
        </v-btn>
        <v-btn depressed color="success" @click="showAddNewTriggerDialog" :disabled="isDisabled">
          <v-icon left size="18px">add</v-icon>
          <span>Select & Add trigger</span>
        </v-btn>
      </div>
    </div>
    <v-dialog scrollable persistent v-model="addNewTriggerDialog" max-width="900" width="800">
      <v-card>
        <v-card-title class="headline">Select a trigger</v-card-title>
        <v-card-text>
          <div class="triggers-warning-div" v-if="hasInjectTrigger">
            <v-alert type="error">
              Warning: Adding a trigger will delete any trigger added via Real Time
            </v-alert>
          </div>
          <div class="add-triggers-list-mod" v-if="appTriggerItems.length > 0">
            <v-checkbox
              v-for="trigger in [...appTriggerItems].sort((a, b) =>
                `${a.text}`.localeCompare(`${b.text}`),
              )"
              :key="trigger.id"
              v-model="addedTriggers"
              :label="trigger.text"
              :value="trigger.value"
              hide-details
            ></v-checkbox>
          </div>
          <div v-else>
            <v-icon>warning</v-icon>
            There are no existing triggers.
            <a @click.stop="redirectToTriggerPage">Click here to add some.</a>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            style="width: 100px"
            @click="addNewTriggerDialog = false"
            class="cancel-btn"
            outlined
            >Cancel</v-btn
          >
          <v-btn large depressed @click="submitTrigger" color="success" style="width: 150px"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable persistent v-model="createTriggerDialog" max-width="900" width="800">
      <RfEditTrigger
        :editTrigger="editingTrigger"
        :key="computeKey"
        :creating="creating"
        :app="currApp"
        :parentTriggerType="this.model.device_type === 'web' ? 'web' : 'device'"
        v-on:closeNewTriggerDialog="closeCreateTriggerDialog"
      />
    </v-dialog>
    <RfBaseModal
      ref="advancedArgsModal"
      title="Advanced Arguments"
      width="550"
      primaryText="Done"
      @close="() => (editArgsTriggerIndex = null)"
      @primary="submitArgs"
    >
      <template #body>
        <RfAdvancedArguments ref="advancedArgsBody" />
      </template>
    </RfBaseModal>
    <div class="all-triggers-options">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="tbl-col-1">Name</th>
              <th class="tbl-col-1">{{ urlPathHeader }}</th>
              <th class="tbl-col-2">Regex</th>
              <th class="tbl-col-5">Delay</th>
              <th class="tbl-col-3">{{ clickIdHeader }}</th>
              <th class="tbl-col-4" v-if="triggerType === 'web'">Click count</th>
              <th
                class="tbl-col-4"
                v-if="
                  triggerType === 'web' ||
                  (triggerType === 'device' && currApp?.flags?.custom_devices_js_sdk)
                "
              >
                Advanced
              </th>
              <th class="tbl-col-4" v-if="triggerType === 'web'">Interval (seconds)</th>
              <th class="tbl-col-4" v-if="triggerType === 'web'">Arguments</th>
              <th class="tbl-col-4" v-if="!isStandalone">Added via Real Time</th>
              <th class="tbl-col-4"></th>
            </tr>
          </thead>
          <tbody class="settings-triggers-tbl-ct" v-if="triggers.length">
            <tr v-for="(trigger, index) in triggerDisplay" :key="index">
              <td>{{ trigger.name }}</td>
              <td>{{ displayPage(trigger) }}</td>
              <td>
                <div v-if="regexDisplay(trigger)">{{ regexDisplay(trigger) }}</div>
                <div class="setting-empty-val" v-else>N/A</div>
              </td>
              <td>{{ trigger.delay_seconds || "0" }}</td>
              <td>{{ trigger.click_id || "None" }}</td>
              <td v-if="triggerType === 'web'">{{ trigger.click_count_threshold || null }}</td>
              <td
                v-if="
                  triggerType === 'web' ||
                  (triggerType === 'device' && currApp?.flags?.custom_devices_js_sdk)
                "
              >
                {{ displayCustomTrigger(trigger, customTriggers) || "None" }}
              </td>
              <td v-if="triggerType === 'web'">
                <div v-if="displayCustomInterval(trigger, undefined, eventInterval)">
                  {{ displayCustomInterval(trigger, undefined, eventInterval) }}
                </div>
                <div class="setting-empty-val" v-else>N/A</div>
              </td>
              <td v-if="triggerType === 'web'">
                <div v-if="trigger.custom_trigger_id">
                  {{ argDisplay(trigger) }}
                  <v-icon size="18" @click="editTriggerArgs(index, trigger.custom_trigger_args)">
                    settings
                  </v-icon>
                </div>
                <div class="setting-empty-val" v-else>N/A</div>
              </td>
              <td v-if="!isStandalone">
                <v-icon color="success" size="16" v-if="trigger.trigger_type === 'easy_button'"
                  >fas fa-check</v-icon
                >
                <v-icon size="16" color="#dddddd" v-else>check_box_outline_blank</v-icon>
              </td>
              <td class="triggers_edit_column" align="right">
                <div class="settings-triggers-tbl-btn">
                  <v-icon
                    color="accent"
                    size="18"
                    style="margin-right: 10px"
                    @click="editTrigger(trigger)"
                    class="edit-trigger-button"
                    :disabled="isEditBtnDisabled"
                    >edit</v-icon
                  >
                  <v-icon size="18" @click="deleteTrigger(trigger)">clear</v-icon>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td align="center" colspan="9">
                <div style="padding: 30px 0">
                  You don't have any triggers yet. Add a new trigger.
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RfEditTrigger from "@/components/RfCommonCards/RfEditTrigger.vue";
import { cloneDeep } from "lodash-es";
import RfBaseModal from "../modals/RfBaseModal.vue";
import RfAdvancedArguments from "@/blocks/RfAdvancedArguments.vue";
import { isStandalone } from "@/utils/prompts/promptHelpers";
import {
  displayCustomInterval,
  displayCustomTrigger,
  displayPage,
  regexDisplay,
} from "@/utils/triggerHelpers";

export default {
  name: "RfTrigger",
  setup: () => ({ displayCustomInterval, regexDisplay, displayPage, displayCustomTrigger }),
  props: ["model", "isDisabled", "triggerType", "isEditBtnDisabled"],
  components: { RfEditTrigger, RfBaseModal, RfAdvancedArguments },
  data() {
    return {
      triggers: cloneDeep(this.model.triggers).sort((a, b) =>
        `${a.name}`.localeCompare(`${b.name}`),
      ),
      computeKey: 0,
      eventInterval: 7200,
      addNewTriggerDialog: false,
      addedTriggers: [],
      toDelete: null,
      createTriggerDialog: false,
      createdTrigger: false,
      updating: false,
      creating: false,
      editingTrigger: null,
      editArgsTriggerIndex: null,
    };
  },
  methods: {
    ...mapActions(["getTriggers", "getClientActions"]),
    fillModel(model) {
      model.triggers = this.triggers;
      if (this.toDelete) {
        model.toDelete = this.toDelete;
        this.toDelete = null;
      }
    },
    showAddNewTriggerDialog() {
      this.addNewTriggerDialog = true;
      this.addedTriggers = [];
    },
    showCreateTriggerDialog() {
      this.editingTrigger = null;
      this.computeKey++;
      this.creating = true;
      this.updating = false;
      this.createTriggerDialog = true;
    },
    closeCreateTriggerDialog(created) {
      this.createTriggerDialog = false;
      this.computeKey++;
      if (created) this.createdTrigger = true;
    },
    submitTrigger() {
      if (this.hasInjectTrigger) {
        const inject = this.triggers.find(item => item.trigger_type === "easy_button");
        this.toDelete = inject.id;
        const index = this.triggers.indexOf(inject);
        this.triggers.splice(index, 1);
      }
      this.triggers = this.triggers.concat(this.addedTriggers);
      this.addNewTriggerDialog = false;
      this.computeKey++;
    },
    editTrigger(trigger) {
      this.editingTrigger = cloneDeep(trigger);
      this.computeKey++;
      this.updating = true;
      this.creating = false;
      this.createTriggerDialog = true;
    },
    deleteTrigger(trigger) {
      const index = this.triggers.indexOf(trigger);
      if (index !== -1) this.triggers.splice(index, 1);
    },
    editTriggerArgs(index, args) {
      this.editArgsTriggerIndex = index;
      this.$refs.advancedArgsModal.show();
      this.$nextTick(() => this.$refs.advancedArgsBody.init(args));
    },
    submitArgs() {
      this.triggers.splice(this.editArgsTriggerIndex, 1, {
        ...this.triggers[this.editArgsTriggerIndex],
        custom_trigger_args: this.$refs.advancedArgsBody.get(),
      });
      this.$refs.advancedArgsModal.close();
    },
    argDisplay(trigger) {
      let result = "";
      if (trigger.custom_trigger_args) {
        Object.keys(trigger.custom_trigger_args).forEach(key => {
          if (result.length) result += ", ";
          result += `${key}: ${trigger.custom_trigger_args[key]}`;
        });
      }
      return result;
    },
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      appTriggers: state => state.apps.triggers,
      currTrigger: state => state.apps.currTrigger,
      clientActions: state => state.apps.clientActions || [],
    }),
    redirectToTriggerPage() {
      return this.$router.replace({
        path: `/apps/${this.$route.params.aid}/settings/triggers`,
      });
    },
    customTriggers() {
      const blank = [{ text: "None", value: "" }];
      const actions = this.clientActions.filter(action => action.action_type === "custom_trigger");
      return blank.concat(actions);
    },
    helpText() {
      let text =
        "Specify one or more trigger events. Multiple triggers are combined using OR logic.";
      if (this.isDisabled) {
        text += " Triggers cannot be changed when an experiment is running.";
      }
      return text;
    },
    triggerDisplay() {
      this.computeKey;
      return this.triggers;
    },
    isStandalone() {
      return isStandalone(this.model);
    },
    urlPathHeader() {
      return this.triggerType === "web" ? "Page" : "Screen";
    },
    clickIdHeader() {
      return this.triggerType === "web" ? "Query Selector" : "Button Name";
    },
    triggerIds() {
      return this.triggers.map(trigger => trigger.id);
    },
    appTriggerItems() {
      return this.appTriggers
        .filter(
          trigger =>
            trigger.trigger_type === this.triggerType && this.triggerIds.indexOf(trigger.id) < 0,
        )
        .map(trigger => {
          return {
            text: trigger.name || "Unnamed Trigger",
            value: trigger,
          };
        });
    },
    hasInjectTrigger() {
      return !!this.triggers.find(trigger => trigger.trigger_type === "easy_button");
    },
  },
  mounted() {
    this.getTriggers({ appId: this.currApp.id });
    if (!this.clientActions.length) this.getClientActions({ appId: this.currApp.id });
  },
  watch: {
    currTrigger(to) {
      if (to && to.id) {
        if (this.createdTrigger && this.triggers.map(item => item.id).indexOf(to.id) < 0) {
          this.triggers.push(to);
        } else {
          this.triggers = this.triggers.map(item => {
            return item.id === to.id ? to : item;
          });
        }
        this.createdTrigger = false;
        this.updating = false;
        this.creating = false;
      }
    },
  },
};
</script>
<style scoped>
.config-trigger-specific-pg {
  margin: 6px 0 0 2rem;
}
</style>
