<template>
  <div>
    <RfPromoDetailsInfoRow title="Limits">
      <template v-slot:promodetail>
        <div>
          <div v-if="item.sequence_id">
            Inherited from <router-link :to="sequenceLink">{{ item.sequence_name }}</router-link>
          </div>
          <div v-else>
            <div v-if="is_limited || is_capped || is_budgeted || hasUserLimit || hasDeliveryLimit">
              <div v-if="is_limited">
                <span v-if="currLimit > 0"
                  >Impression limit:
                  <b>{{ currLimit }} {{ pluralize("impression", currLimit) }}</b> remaining.</span
                >
                <span v-else>Impression limit exceeded</span>
              </div>
              <div v-if="is_capped">
                Frequency cap: Show no more than
                <b>{{ capped_value }} {{ pluralize("time", capped_value) }}</b> over
                <b>{{ capped_days }} {{ pluralize("day", capped_days) }}</b
                >.
              </div>
              <div v-if="is_budgeted">
                Budget limit: <b>{{ currBudget }} ({{ currBudgetPercent }}%)</b> remaining.
              </div>
              <div v-if="hasUserLimit">
                <span v-if="currUsers > 0">
                  User limit:
                  <b>{{ currUsers }} {{ pluralize("user", currUsers) }}</b> remaining.
                </span>
                <span v-else>User limit exceeded</span>
              </div>

              <div v-if="hasDeliveryLimit">
                <span v-if="currDeliveries > 0">
                  Delivery limit:
                  <b>{{ currDeliveries }} {{ pluralize("deliveries", currDeliveries) }}</b>
                  remaining.
                </span>
                <span v-else>Delivery limit exceeded</span>
              </div>
            </div>
            <div v-else>-</div>
            <div class="pd-info-edit edit-freq-cap" v-if="!item.sequence_id">
              <RfButton
                icon-size="16"
                title="edit"
                icon="edit"
                color="accent"
                x-small
                :disabled="editDisabled"
                @click="showPromoDetailDialog"
              />
            </div>
          </div>
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="600"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Limit this {{ itemType }} to:</v-card-title>
        <v-card-text>
          <RfTraffic :model="item" ref="traffic" />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined @click="closeDialog">Close</v-btn>
          <v-btn
            large
            @click="submitConfiguration"
            width="200px"
            color="primary"
            depressed
            :disabled="editDisabled"
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RfTraffic from "@/components/RfPathCreate/RfTraffic.vue";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfPromoLimitsInfo",
  components: { RfPromoDetailsInfoRow, RfTraffic, RfButton },
  props: ["item", "editDisabled"],
  setup: () => ({ pluralize: StringUtils.pluralize, toastsStore: useToastsStore() }),
  data() {
    return {
      showPromotionInfoDialog: false,
    };
  },
  computed: {
    is_capped() {
      return this.item.filter.frequency_cap.is_capped;
    },
    capped_value() {
      return this.item.filter.frequency_cap.cap;
    },
    capped_days() {
      return Math.floor(this.item.filter.frequency_cap.cap_seconds / (3600 * 24));
    },
    is_budgeted() {
      return this.item.filter.supply.is_budgeted;
    },
    currBudget() {
      return this.item.filter.supply.budget - (this.item.curr_budget || 0);
    },
    currBudgetPercent() {
      const budget = parseInt(this.item.filter.supply.budget);
      const curr_budget = parseInt(this.item.curr_budget);
      if (budget <= 0) return 0;

      return 100 - parseInt((curr_budget / budget) * 100);
    },
    is_limited() {
      return this.item.filter.traffic.is_limited;
    },
    currLimit() {
      return this.item.filter.traffic.limit - (this.item.curr_limit || 0);
    },
    sequenceLink() {
      return { path: `/apps/${this.$route.params.aid}/experiences/${this.item.sequence_id}` };
    },
    hasUserLimit() {
      return this.item.filter.users.is_limited;
    },
    currUsers() {
      return this.item.filter.users.limit - (this.item.curr_users || 0);
    },
    hasDeliveryLimit() {
      return this.item.filter.deliveries.is_limited;
    },
    currDeliveries() {
      return this.item.filter.deliveries.limit - (this.item.curr_deliveries || 0);
    },
    itemType() {
      if (this.item.sequence_type) return "guide";
      return "prompt";
    },
  },
  methods: {
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    async submitConfiguration() {
      try {
        const appId = this.$route.params.aid;
        const newPath = cloneDeep(this.item);
        this.$refs.traffic.fillModel(newPath);

        let title = "Prompt";
        if (this.item.subject_name === "Sequence") {
          title = "Guide";
          await this.updateSequence({
            appId,
            sequenceId: this.$route.params.sid,
            modelSequence: newPath,
          });
        } else {
          await this.updatePath({ appId, pathId: this.$route.params.pid, modelPath: newPath });
        }
        this.showPromotionInfoDialog = false;
        this.toastsStore.create({ type: "success", body: `${title} updated successfully` });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath", "updateSequence"]),
  },
};
</script>
