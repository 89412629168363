import { computed } from "vue";

const WEB_MEASURES = ["px", "%", "em", "rem", "vw", "vh"];
const DEFAULT_MEASURES = ["px", "%"];

export const useCSSProperty = (
  actionsFn,
  key,
  { web = false, defaultValue = 0, defaultMeasure = "px" } = {
    web: false,
    defaultValue: 0,
    defaultMeasure: "px",
  },
) => {
  const measures = computed(() => (web ? WEB_MEASURES : DEFAULT_MEASURES));
  const variable = computed({
    get() {
      const number = actionsFn()[key]?.match?.(/[\d\,\.]+(?=[^0-9])/g)?.[0];
      const postfix = actionsFn()[key]?.match?.(/[\D]+$/g)?.[0];

      return [number || defaultValue, postfix || defaultMeasure];
    },
    set([v, index]) {
      const number = actionsFn()[key]?.match?.(/[\d\,\.]+(?=[^0-9])/g)?.[0];
      const postfix = actionsFn()[key]?.match?.(/[\D]+$/g)?.[0];

      if (index) actionsFn()[key] = `${number}${v || defaultMeasure}`;
      else actionsFn()[key] = `${v || defaultValue}${postfix}`;
    },
  });

  return { variable, measures };
};
