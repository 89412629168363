<template>
  <v-card height="auto" class="rf-metrics-card rf-metrics-cohort-card">
    <div class="rf-metrics-wrapper">
      <div>
        <slot name="title"></slot>
      </div>
    </div>
    <div class="cohort-pickers-wrapper">
      <div class="cohort-pickers left-col">
        <div class="cohort-picker-row">
          <div class="cohort-pr-name">Prompt name:</div>
          <v-select
            v-model="selectedPath"
            :items="availablePaths"
            item-text="name"
            item-value="value"
            dense
            outlined
            background-color="#ffffff"
            height="34"
            hide-details
            style="width: 395px"
          ></v-select>
        </div>
        <div class="cohort-picker-row second-row">
          <div class="cohort-pr-name">Target:</div>
          <v-select
            v-model="currentSelection"
            :items="selectables"
            item-text="name"
            item-value="value"
            dense
            outlined
            background-color="#ffffff"
            height="34"
            hide-details
          ></v-select>
        </div>
        <div class="cohort-picker-row second-row" v-if="hasSelectableExperiments">
          <div class="cohort-pr-name">Experiment:</div>
          <v-select
            v-model="currentExpSelection"
            :items="currentExpSelectables"
            item-text="name"
            item-value="value"
            dense
            outlined
            background-color="#ffffff"
            height="34"
            hide-details
          ></v-select>
        </div>
      </div>
      <div class="cohort-versus-div">VS</div>
      <div class="cohort-pickers right-col">
        <div class="cohort-picker-row">
          <div class="cohort-pr-name">Prompt name:</div>
          <v-select
            v-model="compareSelectedPath"
            :items="availablePathsWithNone"
            item-text="name"
            item-value="value"
            dense
            outlined
            background-color="#ffffff"
            height="34"
            hide-details
            style="width: 395px"
          ></v-select>
        </div>
        <div class="cohort-picker-row second-row" v-show="compareSelectedPath">
          <div class="cohort-pr-name">Target:</div>
          <v-select
            v-model="compareCurrentSelection"
            :items="selectables"
            item-text="name"
            item-value="value"
            dense
            outlined
            background-color="#ffffff"
            height="34"
            hide-details
          ></v-select>
        </div>
        <div class="cohort-picker-row second-row" v-if="hasCompareSelectableExperiments">
          <div class="cohort-pr-name">Experiment:</div>
          <v-select
            v-model="compareCurrentExpSelection"
            :items="currentExpSelectables"
            item-text="name"
            item-value="value"
            dense
            outlined
            background-color="#ffffff"
            height="34"
            hide-details
          ></v-select>
        </div>
      </div>
    </div>
    <div class="cohort-present-div">
      <div class="cohort-table-header">
        <h4>{{ hasData ? selectionName : "" }}</h4>
        <v-select
          v-model="selectedNumberOfDays"
          :items="dates"
          item-text="name"
          item-value="value"
          dense
          outlined
          background-color="#ffffff"
          height="34"
          hide-details
        ></v-select>
      </div>
      <div class="cohort-present-table-wrapper">
        <table class="cohort-present-table" v-if="currentMetrics.length && hasData">
          <tbody>
            <tr v-for="(row, rowIdx) in currentMetrics" :key="rowIdx">
              <template v-for="(col, colIdx) in row">
                <td
                  class="cohort-dates-cell row-one"
                  :key="`${rowIdx}-${colIdx}`"
                  v-if="rowIdx === 0"
                >
                  <div>{{ col }}</div>
                </td>
                <td
                  :class="rowIdx === 0 || colIdx === 0 ? 'cohort-dates-cell other-rows' : ''"
                  :key="colIdx"
                  v-else
                >
                  <div v-if="rowIdx === 0 || colIdx === 0">{{ col }}</div>
                  <div v-else :class="divColor(rowIdx, colIdx, col)">
                    <div class="cohorts-compare-val" v-if="compareMetrics.length">
                      <span class="cohorts-val-one">{{
                        rowIdx >= availableMetricsRow
                          ? getFormattedNumbers(rowIdx, colIdx, col)
                          : "N/A"
                      }}</span>
                      <span class="cohorts-val-two">
                        {{
                          rowIdx >= availableCompareMetricsRow
                            ? getFormattedNumbers(rowIdx, colIdx, compareMetrics[rowIdx][colIdx])
                            : "N/A"
                        }}</span
                      >
                      <span class="triangle-right-corner"></span>
                    </div>
                    <div class="cohort-not-compare-val" v-else>
                      {{
                        rowIdx >= availableMetricsRow
                          ? getFormattedNumbers(rowIdx, colIdx, col)
                          : "N/A"
                      }}
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <img alt="" src="@/assets/images/cohort_placeholder.png" />
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import MetricsUtils from "@/utils/MetricsUtils";
import MetricsMixin from "@/utils/MetricsMixin";
import { toLocaleNumberString } from "@/utils/stringHelpers";

export default {
  name: "RfCohortCard",
  props: ["currApp", "currChartSrc", "preSelectedPath"],
  mixins: [MetricsMixin],
  data() {
    return {
      currentPath: null,
      currentSelection: "goal",
      currentExpSelection: null,
      compareCurrentPath: null,
      compareCurrentSelection: null,
      compareCurrentExpSelection: null,
      dates: MetricsUtils.CohortPeriods,
      selectedNumberOfDays: 14,
      selectables: [
        { value: "goal", name: "Primary Accept" },
        { value: "goal2", name: "Secondary Accept" },
        { value: "timeout", name: "Timeout" },
        { value: "dismiss", name: "Dismiss" },
        { value: "decline", name: "Decline" },
        { value: "eligible", name: "All Eligible" },
        { value: "impression", name: "Impression" },
        { value: "holdout", name: "Holdout" },
        { value: "custom_goal", name: "Custom Goal" },
      ],
    };
  },
  computed: {
    ...mapState({
      pathsCohortMetrics: state => state.apps.pathsCohortMetrics,
      retentionMetrics: state => state.apps.retentionMetrics,
    }),
    availablePaths() {
      const topPromos = this.retentionMetrics[this.currChartSrc];
      if (!topPromos) return [];
      return topPromos.data.map(promo => ({ value: promo.id, name: promo.name }));
    },
    availablePathsWithNone() {
      const nonePath = { value: null, name: "None" };
      return [nonePath, ...this.availablePaths];
    },
    selectionName() {
      return this.selectables.find(item => item.value === this.currentSelection).name;
    },
    hasSelectableExperiments() {
      return (
        this.pathsCohortMetrics[this.selectedPath] &&
        this.pathsCohortMetrics[this.selectedPath].experiments &&
        !!this.pathsCohortMetrics[this.selectedPath].experiments.length
      );
    },
    hasCompareSelectableExperiments() {
      return (
        this.pathsCohortMetrics[this.compareSelectedPath] &&
        this.pathsCohortMetrics[this.compareSelectedPath].experiments &&
        !!this.pathsCohortMetrics[this.compareSelectedPath].experiments.length
      );
    },
    currentExpSelectables() {
      const nonePath = { value: null, name: "None" };
      const rows = [];
      this.pathsCohortMetrics[this.selectedPath].experiments.forEach(item => {
        rows.push({ value: item.id, name: item.name, header: item.name });
        item.variations.forEach(v => rows.push({ value: v.id, name: ` - ${v.name}` }));
      });
      return [nonePath, ...rows];
    },
    selectedPath: {
      get() {
        if (!this.currentPath) {
          return this.availablePaths[0] && this.availablePaths[0].value;
        }
        return this.currentPath;
      },
      set(value) {
        this.currentPath = value;
      },
    },
    compareSelectedPath: {
      get() {
        if (!this.availablePaths.length) return null;

        if (!this.compareCurrentPath) {
          return this.availablePathsWithNone[0] && this.availablePathsWithNone[0].value;
        }
        return this.compareCurrentPath;
      },
      set(value) {
        this.compareCurrentPath = value;
      },
    },
    cohortSampleData() {
      return this.currApp && this.currApp.feature_set.indexOf("cohort_sample_data") > -1;
    },
    currentMetrics() {
      let key = this.selectedPath;
      if (this.currentExpSelection) {
        key = `${key}:${this.currentExpSelection}`;
      }
      if (
        !this.pathsCohortMetrics[key] ||
        !this.pathsCohortMetrics[key][this.currentSelection] ||
        !this.pathsCohortMetrics[key][this.currentSelection][this.selectedNumberOfDays]
      )
        return [];

      return this.pathsCohortMetrics[key][this.currentSelection][this.selectedNumberOfDays];
    },
    compareMetrics() {
      let key = this.compareSelectedPath;
      const currentSelection = this.compareCurrentSelection;
      if (this.compareCurrentExpSelection) {
        key = `${key}:${this.compareCurrentExpSelection}`;
      }

      if (
        !key ||
        !this.pathsCohortMetrics[key] ||
        !this.pathsCohortMetrics[key][currentSelection] ||
        !this.pathsCohortMetrics[key][currentSelection][this.selectedNumberOfDays]
      )
        return [];

      return this.pathsCohortMetrics[key][currentSelection][this.selectedNumberOfDays];
    },
    availableMetricsRow() {
      return this.getAvailableRowsByPath(this.selectedPath);
    },
    availableCompareMetricsRow() {
      return this.getAvailableRowsByPath(this.compareSelectedPath);
    },
    hasData() {
      const rowWithData = this.currentMetrics.find((row, rowIndex) => {
        if (rowIndex > 0) {
          const cellWithData = row.find((cell, cellIndex) => (cellIndex > 0 ? !!cell : false));
          return !!cellWithData;
        }
        return false;
      });
      return !!rowWithData;
    },
  },
  methods: {
    ...mapActions(["getPathCohortMetrics", "getRetentionMetrics"]),
    getAvailableRowsByPath(path) {
      if (this.selectedNumberOfDays > 30) {
        return Math.ceil(this.selectedNumberOfDays / 7) - this.publishedPeriodAgo(path, "weeks");
      }
      return this.selectedNumberOfDays - this.publishedPeriodAgo(path, "days");
    },
    publishedPeriodAgo(selectedPath, period) {
      const paths = (this.retentionMetrics[this.currChartSrc] || {}).data;
      if (!paths || !paths.length) return 0;
      const path = paths.find(p => p.id === selectedPath) || paths[0];
      if (!path.last_published_date) {
        return -1;
      }
      let ago = this.$moment(path.last_published_date);
      let today = this.$moment();
      if (period === "week") {
        ago = ago.startOf("week");
        today = today.startOf("week");
      }

      return today.diff(ago, period);
    },
    divColor(row, column, number) {
      if (column === 1) return "cohort-div-val cohort-value-div-usersnum";
      const cellNum = this.getNumbers(row, column, number);
      const num = Math.ceil(cellNum / 10) * 10;
      return `cohort-div-val cohort-value-div-${num}`;
    },
    getNumbers(row, column, number) {
      if (this.cohortSampleData) {
        const totalRows = this.currentMetrics.length;
        const rowValue = totalRows - (totalRows + column - 1);
        if (rowValue === 0) return 100;
        let multiplier = 1.5;
        if (this.currentSelection !== "goal") {
          multiplier = 3;
        }
        const descendingVal = rowValue * multiplier;
        return parseInt(100 + descendingVal);
      }

      return parseFloat(Math.round(number * 10000) / 100);
    },
    getFormattedNumbers(row, column, number) {
      return column === 1
        ? toLocaleNumberString(number)
        : `${this.getNumbers(row, column, number)}%`;
    },
    getCohortMetrics(appId, pathId, params) {
      this.getPathCohortMetrics({
        appId,
        id: pathId,
        params,
      });
    },
  },
  watch: {
    selectedPath(path) {
      this.currentExpSelection = null;
      this.getCohortMetrics(this.$route.params.aid, path, {
        activity: this.currentSelection,
        days: this.selectedNumberOfDays,
        action_group_id: this.currentExpSelection,
      });
    },
    compareSelectedPath(path) {
      this.compareCurrentExpSelection = null;
      if (path && !this.compareCurrentSelection) {
        this.compareCurrentSelection = "goal";
      } else if (path) {
        this.getCohortMetrics(this.$route.params.aid, path, {
          activity: this.compareCurrentSelection,
          days: this.selectedNumberOfDays,
          action_group_id: this.compareCurrentExpSelection,
        });
      }
    },
    currentSelection(activity) {
      this.getCohortMetrics(this.$route.params.aid, this.selectedPath, {
        activity,
        days: this.selectedNumberOfDays,
        action_group_id: this.currentExpSelection,
      });
    },
    compareCurrentSelection(activity) {
      const path = this.compareSelectedPath;
      if (path) {
        this.getCohortMetrics(this.$route.params.aid, path, {
          activity,
          days: this.selectedNumberOfDays,
          action_group_id: this.compareCurrentExpSelection,
        });
      }
    },
    currentExpSelection(actionGroupId) {
      this.getCohortMetrics(this.$route.params.aid, this.selectedPath, {
        activity: this.currentSelection,
        days: this.selectedNumberOfDays,
        action_group_id: actionGroupId,
      });
    },
    compareCurrentExpSelection(actionGroupId) {
      this.getCohortMetrics(this.$route.params.aid, this.compareSelectedPath, {
        activity: this.compareCurrentSelection,
        days: this.selectedNumberOfDays,
        action_group_id: actionGroupId,
      });
    },
    selectedNumberOfDays(days) {
      this.getCohortMetrics(this.$route.params.aid, this.selectedPath, {
        activity: this.currentSelection,
        days,
        action_group_id: this.currentExpSelection,
      });
      if (this.compareSelectedPath) {
        this.getCohortMetrics(this.$route.params.aid, this.compareSelectedPath, {
          activity: this.compareCurrentSelection,
          days,
          action_group_id: this.compareCurrentExpSelection,
        });
      }
    },
  },
  updated() {},
  mounted() {
    if (this.preSelectedPath) this.selectedPath = this.preSelectedPath;
    if (!Object.keys(this.retentionMetrics).length) {
      this.getRetentionMetrics({
        appId: this.currApp.id,
      });
    }
  },
};
</script>
