<template>
  <div class="promo-publish-info">
    <span>{{ status.label }}</span>
    <v-icon size="14" :color="status.color">lens</v-icon>
  </div>
</template>
<script>
import PromoStatusMixin from "@/utils/PromoStatusMixin";

export default {
  name: "RfPromoPublishStatus",
  props: ["item"],
  mixins: [PromoStatusMixin],
  computed: {
    status() {
      return this.promoStatus(this.item);
    },
  },
};
</script>
