<template>
  <div title="Schedule">
    <div class="rf-section-title">
      <div class="schedule-blurbs">
        Specify a schedule to determine when this {{ itemType }} should be active. By default,
        Schedule is from midnight on the start date to 11:59 pm on the end date based on the app's
        timezone setting.
      </div>
    </div>
    <v-alert width="700" dense outlined type="warning" v-if="!model.is_enabled"
      >Note: This {{ itemType }} is currently a draft. To activate, start the
      {{ itemType }} manually.</v-alert
    >
    <div class="add-schedule-date-time-div">
      <RfStartStopDatePicker
        ref="dateRangePicker"
        :dateRange="dateRange"
        :actions="model.actions"
        :isPushNotification="isPushNotification"
        v-if="scheduleType === 'schedule'"
      />
      <RfSchedulePicker
        ref="schedulePicker"
        :scheduleRanges="scheduleRanges"
        v-if="scheduleType === 'daypart'"
        :itemType="itemType"
      />
      <div class="rf-section-title" style="padding-top: 20px" v-if="scheduleType === 'daypart'">
        <div class="schedule-blurbs">
          <h3>Specify a timezone for this schedule. Default is user timezone.</h3>
        </div>
      </div>
      <div v-if="scheduleType === 'daypart'">
        <v-radio-group row v-model="is_app_timezone">
          <v-radio :value="false" label="User Timezone"></v-radio>
          <v-radio :value="true" label="App Timezone"></v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RfSchedulePicker from "../RfSchedulePicker.vue";
import RfStartStopDatePicker from "../RfStartStopDatePicker.vue";

export default {
  name: "RfSchedule",
  components: {
    RfSchedulePicker,
    RfStartStopDatePicker,
  },
  props: {
    model: Object,
    scheduleType: String,
    isPushNotification: { type: Boolean, default: false },
  },
  data() {
    return {
      is_app_timezone: this.model.is_app_timezone,
    };
  },
  watch: {
    model(to) {
      this.is_app_timezone = to.is_app_timezone;
    },
  },
  computed: {
    itemType() {
      if (this.model.subject_name === "Sequence") {
        return "guide";
      }
      return "prompt";
    },
    dateRange() {
      const range = {
        startDate: moment.utc(this.model.start_date),
        endDate: moment.utc(this.model.end_date),
      };
      return range;
    },
    scheduleRanges() {
      return this.model.filter.schedule;
    },
  },
  methods: {
    fillModel(model) {
      if (this.$refs.dateRangePicker) {
        const { fullStartDate, fullEndDate, frequency, localTime } = this.$refs.dateRangePicker;
        if (this.$moment(fullStartDate) >= this.$moment(fullEndDate)) {
          throw new Error("Start date must be before end date");
        }
        model.start_date = fullStartDate;
        model.end_date = fullEndDate;
        if (frequency) model.actions.rf_pinpoint_campaign_frequency = frequency;
        if (localTime) model.actions.rf_pinpoint_campaign_local_time = localTime;
      }
      if (this.$refs.schedulePicker) {
        model.filter.schedule = this.$refs.schedulePicker.schedule;
        model.is_app_timezone = this.is_app_timezone;
      }
    },
  },
};
</script>
