<template>
  <div>
    <RfPromoDetailsInfoRow title="Stage Transition">
      <template v-slot:promodetail>
        <div>
          <span v-if="canTransition">{{ pipelineStageName }}</span>
          <span v-else>N/A</span>
          <div class="pd-info-edit">
            <RfButton
              icon-size="16"
              title="edit"
              icon="edit"
              color="accent"
              x-small
              :disabled="editDisabled"
              @click="showEditDialog"
            />
          </div>
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="editDialog"
      width="500"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Add a stage transition</v-card-title>
        <v-card-text>
          <RfStageSelection
            :pipelineStageId="selectedStage"
            :segments="path.segments"
            :showHelpText="true"
            :pipelineId="path.pipeline_id"
            ref="selector"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeEditDialog"
            >Close</v-btn
          >
          <v-btn large @click="submitConfiguration" width="200px" color="primary" depressed>
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfStageSelection from "@/components/RfPromotions/RfStageSelection.vue";
import { mapActions } from "vuex";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfPromoStageTransition",
  components: { RfPromoDetailsInfoRow, RfStageSelection, RfButton },
  setup: () => ({ toastsStore: useToastsStore() }),
  props: ["path", "editDisabled"],
  data() {
    return {
      editDialog: false,
      pipelineStageName: this.path.pipeline_stage_name,
      selectedStage: this.path.pipeline_stage_id,
    };
  },
  computed: {
    canTransition() {
      return !!this.path.segments.find(segment => !!segment.pipeline_stage_id);
    },
  },
  methods: {
    ...mapActions(["updatePath"]),
    showEditDialog() {
      this.editDialog = true;
      this.selectedStage = this.path.pipeline_stage_id;
    },
    closeEditDialog() {
      this.selectedStage = null;
      this.editDialog = false;
    },
    async submitConfiguration() {
      this.selectedStage = this.$refs.selector.selectedStage;
      try {
        const newRetention = cloneDeep(this.path);
        newRetention.pipeline_stage_id = this.selectedStage;
        this.closeEditDialog();
        await this.updatePath({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
          modelPath: newRetention,
        });
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
};
</script>
