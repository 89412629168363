<template>
  <RfBaseCard class="justify-start !gap-3 self-stretch">
    <span class="flex flex-grow-0 items-center justify-between">
      <span
        v-if="Array.isArray(props.title)"
        ref="buttonContainer"
        class="relative inline-flex items-center"
      >
        <h2 v-for="(title, index) in props.title" ref="buttons">
          <button
            class="text-buttons px-4 pb-3 pt-2 !text-black-2"
            @click="selectedTab = { key: title.key, index: index }"
          >
            {{ title.value }}
          </button>
        </h2>
        <span
          ref="slider"
          aria-hidden="true"
          class="absolute bottom-0 top-auto h-0.5 w-10 bg-blue-1 transition-all duration-300"
        ></span>
      </span>
      <h2 v-else class="text-heading-1">{{ props.title }}</h2>
      <slot name="more"></slot>
    </span>
    <div v-if="$slots.body || $scopedSlots.body" class="h-full">
      <slot name="body"></slot>
    </div>
    <template v-if="Array.isArray(props.title)">
      <Transition name="component-fade" mode="out-in">
        <template v-for="slot in props.title">
          <div v-if="selectedTab.key === slot.key" :key="slot.key">
            <slot :name="slot.key"></slot>
          </div>
        </template>
      </Transition>
    </template>
  </RfBaseCard>
</template>

<script setup>
import { onMounted, ref, watchPostEffect } from "vue";
import RfBaseCard from "./RfBaseCard.vue";

const buttons = ref();
const slider = ref();
const buttonContainer = ref();
const selectedTab = ref({ key: "", index: 0 });
const props = defineProps({ title: { type: [String, Array], default: "Title" } });

Array.isArray(props.title) &&
  onMounted(() => (selectedTab.value = { key: props.title[0].key, index: 0 }));

watchPostEffect(() => {
  const button = buttons.value?.[selectedTab.value.index];
  if (!button || !buttonContainer.value || !slider.value) return;
  const { left: childLeft, width } = button.getBoundingClientRect();
  const { left: parentLeft } = buttonContainer.value.getBoundingClientRect();
  slider.value.style.width = `${width}px`;
  slider.value.style.transform = `translateX(${childLeft - parentLeft}px)`;
});
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
