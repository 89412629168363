<template>
  <RfSimpleCard>
    <div class="user-setting-content settings-all-dwnld-wrap">
      <div class="flex flex-col gap-5 pb-5">
        <div>
          All raw prompt interaction events get be downloaded from your dedicated AWS S3 bucket.
          <RfButton
            button-text="Click here for credentials."
            link
            class="pb-1"
            :disabled="isDisabledRoleMixin"
            @click="awsCredentialsModal?.show()"
          />
          Refer to the
          <a target="_blank" href="https://help.redfast.com/docs/download-data">
            documentation for more information.
          </a>
        </div>

        <div>Download detailed prompt interaction data at a per userid level for the specified date range. Please note this may take several minutes to complete. Exports can be downloaded for 30 days.</div>
        <RfAwsCredentialsModal
          ref="awsCredentialsModal"
          :awsUserName="currApp.aws_creds?.user_name"
          :awsPath="currApp.aws_creds?.aws_path.replace('ingest', 'exports')"
          :awsAccessKey="currAppAwsCredentials?.access_key_id || currApp?.aws_creds?.access_key_id"
          :awsSecretKey="
            currAppAwsCredentials?.secret_access_key || currApp?.aws_creds?.secret_access_key
          "
          @update:showCredentials="
            value =>
              value ? this.getAppAwsCredentials(currApp.id) : this.mutateCurrAppAwsCredentials({})
          "
        />
      </div>
      <div class="settings-download-wrapper">
        <div class="settings-download-ctr">
          <div class="stg-dwnld-label">Select date range</div>
          <RfMetricsDateRangeSelector
            parentPage="segments"
            v-on:fetchMetrics="fetchMetrics"
            v-on:fetchMetricsCustom="fetchMetricsCustom"
          />
        </div>
        <div class="stg-dwnld-btns">
          <v-btn class="dwnload-as-csv-btn" depressed color="accent" @click="exportToCsv">
            <v-icon size="20" left>download</v-icon>Export CSV</v-btn
          >
        </div>
      </div>
      <v-simple-table class="rf-settings-table-data mt-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Title</th>
              <th class="text-left">Date Requested</th>
              <th class="text-left">Requested By</th>
              <th class="text-left">Status</th>
              <th class="text-left">Download</th>
              
            </tr>
          </thead>
          <tbody v-if="jobs && jobs.length > 0">
            <tr v-for="(job, index) in jobs" :key="index">
              <td>User interactions data - {{ dayjs(job.start_date).format("MM/DD/YY")  }} - {{ dayjs(job.end_date).format("MM/DD/YY")  }}</td>
              <td>{{ dayjs(job.created_at).format("MM/DD/YY hh:mm A")  }}</td>
              <td>{{ job.creator_name }}</td>
              <td>{{ job.status }}{{ timeElapsed(job) }}</td>
              <td><RfButton
                          :disabled="job.status !== 'completed'"
                          icon="download"
                          color="accent"
                          @click="downloadLink(job)"
                        /></td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">
                <div class="settings-no-data-message">Nothing to show.</div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </RfSimpleCard>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import MetricsMixin from "@/utils/MetricsMixin";
import RfSimpleCard from "../RfSimpleCard.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfAwsCredentialsModal from "@/blocks/RfModals/RfAwsCredentialsModal.vue";
import { ref } from "vue";
import RfButton from "../buttons/RfButton.vue";
import ApiJobs from "@/apis/ApiJobs";
import { saveLinkToCsv } from "@/utils/CsvUtils";

export default {
  name: "RfDownloadSettings",
  components: {
    RfMetricsDateRangeSelector,
    RfSimpleCard,
    RfAwsCredentialsModal,
    RfButton,
  },
  mixins: [MetricsMixin, RoleMixin],
  setup: () => ({ awsCredentialsModal: ref(), dayjs }),
  data() {
    return {
      currChartSrc: null,
      singleDayCustom: false,
      dateRange: null,
      intervalId: null,
      intervalMs: 3000,
      lastCheckedDateTime: null,
    };
  },
  computed: {
    ...mapState({
      segments: state => state.apps.segments,
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      segmentMetrics: state => state.apps.segmentMetrics,
      currAppAwsCredentials: state => state.apps.currAppAwsCredentials,
      jobs: state => state.apps.jobs,
    }),
    ...mapGetters(["activeJobs"]),

  },
  watch: {
    activeJobs(to, from) {
      if(to.length > 0) {
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            this.getJobs({ appId: this.currApp.id });
            this.lastCheckedDateTime = dayjs();
          }, this.intervalMs);
        }
      } else {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
  },
  methods: {
    ...mapActions(["getJobs", "getAppAwsCredentials", "createJob"]),
    ...mapMutations(["mutateCurrAppAwsCredentials"]),
    timeElapsed(job) {
      if (job.status === 'pending' || job.status === 'processing' && this.lastCheckedDateTime) {
        let minutesDiff = dayjs(job.created_at).diff(this.lastCheckedDateTime, 'minute') * -1;
        if (minutesDiff < 0) {
          minutesDiff = 0;
        }
        return ` (${minutesDiff}min elapsed)`;
      }
    },
    exportToCsv() {
      const metricsPeriod = { period: this.currChartSrc };
      if (this.currChartSrc === "custom") {
        metricsPeriod.date_range = this.date_range;
      }
      this.lastCheckedDateTime = dayjs();
      this.createJob({
        appId: this.currApp.id,
        metrics_period: metricsPeriod,
      });
   
    },
    fetchMetrics(timePeriod) {
      this.date_range = timePeriod
      this.currChartSrc = timePeriod;
    },
    fetchMetricsCustom(timePeriod) {
      this.date_range = timePeriod
      this.currChartSrc = "custom";
    },
    async downloadLink(job) {
      const dlLink = await ApiJobs.downloadJobLink(this.currApp.id, job)
      const link = dlLink.link;
      const startDate = dayjs(job.start_date).format("MM_D_YYYY");
      const endDate = dayjs(job.end_date).format("MM_D_YYYY");
      const fname = `${startDate}_${endDate}_activities`;
      saveLinkToCsv(link, "activities", fname);
    },
  },
  mounted() {
    this.getJobs({ appId: this.$route.params.aid });
  },
  destroyed() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }
};
</script>
