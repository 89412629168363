<template>
  <div :class="showConfigModules">
    <div :class="leftColumnClass">
      <RfRetentionActionsInputs
        :actions="actions"
        :model="model"
        v-on:forceRender="forceRender"
        :viewTypeTab="viewTypeTab"
      />
    </div>
    <div class="retention-editor-rightcol">
      <div class="showhide-leftcol-trigger" @click="showHideLeftCol">
        <v-icon color="primary" size="20" v-if="leftColumnOpenStatus">arrow_back</v-icon>
        <v-icon color="primary" size="20" v-else>arrow_forward</v-icon>
      </div>
      <div class="rf-retention-modal-preview" v-if="isStandAlone">
        <div
          v-if="model.device_type === 'web' || model.path_type === 'bottom_banner'"
          style="width: 100%; height: 100%"
        >
          <div :class="standAlonePreviewClass" :showCover="model.path_type !== 'video'">
            <template v-if="model.device_type === 'web'">
              <RfRetentionDeviceWeb
                :model="model"
                :actionGroupId="model.action_group_id"
                :actions="actions"
                :viewTypeTab="viewTypeTab"
                :key="previewKey"
                v-on:clickTab="clickTab"
                :retentionTypeVideo="model.path_type === 'video'"
                ref="web"
                v-if="model.path_type === 'retention_modal' || model.path_type === 'video'"
              />
              <RfRetentionInterstitial
                :model="model"
                :actionGroupId="model.action_group_id"
                :actions="actions"
                :viewTypeTab="viewTypeTab"
                :key="previewKey"
                v-on:clickTab="clickTab"
                ref="web"
                v-if="model.path_type === 'interstitial' || model.path_type === 'widget'"
              />
            </template>
            <RfRetentionBanner
              :model="model"
              :actionGroupId="model.action_group_id"
              :actions="actions"
              :viewTypeTab="viewTypeTab"
              :key="previewKey"
              v-on:clickTab="clickTab"
              ref="web"
              v-if="model.path_type === 'bottom_banner'"
            />
          </div>
        </div>
        <RfRetentionDeviceIos
          :model="model"
          :actions="actions"
          :viewTypeTab="viewTypeTab"
          :key="previewKey"
          v-on:clickTab="clickTab"
          v-if="model.device_type === 'ios' && model.path_type !== 'bottom_banner'"
          ref="ios"
        />
        <RfRetentionDeviceTvOs
          :model="model"
          :actions="actions"
          :viewTypeTab="viewTypeTab"
          :key="previewKey"
          v-on:clickTab="clickTab"
          v-if="
            (model.device_type === 'tv_os' ||
              model.device_type === 'roku_os' ||
              model.device_type === 'custom_defined') &&
            model.path_type !== 'bottom_banner'
          "
          :retentionTypeVideo="model.path_type === 'video'"
          ref="tv"
        />
        <RfRetentionDeviceAndroidOs
          :model="model"
          :actions="actions"
          :viewTypeTab="viewTypeTab"
          :key="previewKey"
          v-on:clickTab="clickTab"
          v-if="model.device_type === 'android_os' && model.path_type !== 'bottom_banner'"
          ref="android_os"
        />
      </div>
      <div
        class="rf-retention-modal-preview retention-text-preview"
        v-if="model.path_type === 'text'"
      >
        <RfRetentionTileText
          :actions="actions"
          :model="model"
          :key="previewKey"
          :viewTypeTab="viewTypeTab"
          :currPathGroup="currPathGroup"
          ref="text"
          v-on:clickTab="clickTab"
          v-if="model.path_type === 'text'"
        />
      </div>
      <div
        class="rf-retention-modal-preview retention-tile-preview"
        v-if="isHorizontalVerticalTile"
      >
        <RfRetentionTile
          :actions="actions"
          :key="previewKey"
          ref="tile"
          :viewTypeTab="viewTypeTab"
          v-on:clickTab="clickTab"
          :model="model"
          :currPathGroup="currPathGroup"
        />
      </div>
      <div v-if="isEmail(model)">
        <RfRetentionEmail
          :actions="actions"
          :key="previewKey"
          ref="tile"
          :viewTypeTab="viewTypeTab"
          v-on:clickTab="clickTab"
          :model="model"
          :currPathGroup="currPathGroup"
        />
      </div>
      <div v-if="isPushNotification(model)">
        <RfRetentionPush
          ref="scheduled_push"
          :model="model"
          :actions="actions"
          :viewTypeTab="viewTypeTab"
          :key="previewKey"
          @clickTab="clickTab"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RfRetentionTile from "@/components/RfPathCreate/RfRetentionActions/RfRetentionTile.vue";
import RfRetentionTileText from "@/components/RfPathCreate/RfRetentionActions/RfRetentionTileText.vue";
import RfRetentionDeviceWeb from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceWeb.vue";
import RfRetentionInterstitial from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionInterstitial.vue";
import RfRetentionDeviceIos from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceIos.vue";
import RfRetentionDeviceTvOs from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceTvOs.vue";
import RfRetentionDeviceAndroidOs from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceAndroidOs.vue";
import RfRetentionEmail from "@/components/RfPathCreate/RfRetentionActions/RfRetentionEmail.vue";
import RfRetentionActionsInputs from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInputs.vue";
import RfRetentionBanner from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionBanner.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import CreativeModuleMixin from "@/utils/CreativeModuleMixin";
import { isEmail, isPushNotification } from "@/utils/prompts/promptHelpers";
import RfRetentionPush from "./RfRetentionActions/RfRetentionPush.vue";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";

export default {
  name: "RfRetentionCreativeEditor",
  props: ["model"],
  setup: () => ({ isEmail, isPushNotification, PromptTypes }),
  components: {
    RfRetentionTile,
    RfRetentionTileText,
    RfRetentionDeviceWeb,
    RfRetentionInterstitial,
    RfRetentionDeviceIos,
    RfRetentionDeviceTvOs,
    RfRetentionDeviceAndroidOs,
    RfRetentionEmail,
    RfRetentionActionsInputs,
    RfRetentionBanner,
    RfRetentionPush,
  },
  data() {
    return {
      viewTypeTab: "desktopViewTab",
      previewKey: 0,
      actions: this.cloneActions(this.model),
      leftColumnOpenStatus: true,
      path: this.model,
    };
  },
  mixins: [CreativeModuleMixin, RoleMixin],
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      pathGroups: state => state.apps.pathGroups,
    }),
    currPathGroup() {
      return this.pathGroups.find(item => item.id === this.model.path_group_id);
    },
    isStandAlone() {
      const pathType = this.model.path_type;
      return (
        pathType === "retention_modal" ||
        pathType === "video" ||
        pathType === "interstitial" ||
        pathType === "widget" ||
        pathType === "bottom_banner"
      );
    },
    isHorizontalVerticalTile() {
      const pathType = this.model.path_type;
      return pathType === "horizontal" || pathType === "tile" || pathType === "vertical";
    },
    showConfigModules() {
      if (this.leftColumnOpenStatus) {
        return "configmodules-visible retention-editor-wrapper";
      }
      return "retention-editor-wrapper";
    },
    isLeftColumnOpen() {
      return this.leftColumnOpenStatus;
    },
    isExperimentRunning() {
      return this.latestExperimentActive;
    },
    leftColumnClass() {
      return {
        "retention-editor-leftcol": true,
        "open-module": this.isLeftColumnOpen,
        "close-module": !this.isLeftColumnOpen,
        "inputs-disabled": this.isExperimentRunning || this.isReadOnlyRoleMixin,
      };
    },
    standAlonePreviewClass() {
      if (this.model.path_type === "video") return "retention-video-preview-div";
      return "retention-web-preview-div";
    },
  },
  methods: {
    getMobilePreviewDimensions() {
      return this.$refs.web.getMobilePreviewDimensions();
    },
    fillModel(model) {
      model.actions = this.actions;

      ["web", "ios", "tv", "android_os"].forEach(os => {
        if (this.$refs[os]) {
          this.$refs[os].fillModel(model);
        }
      });

      if (this.$refs.tile) {
        this.$refs.tile.fillModel(model);
      }

      if (this.$refs.text) {
        this.$refs.text.fillModel(model);
      }
    },
    cloneActions(model) {
      if (model) {
        return cloneDeep(model.actions);
      }
      return {};
    },
    forceRender() {
      this.previewKey += 1;
    },
    clickTab(tabType) {
      this.viewTypeTab = tabType;
      this.$emit("setTabType", tabType);
    },
    showHideLeftCol() {
      this.leftColumnOpenStatus = !this.leftColumnOpenStatus;
    },
  },
  watch: {
    model(to) {
      this.actions = this.cloneActions(to);
    },
  },
};
</script>
