<template>
  <RfAbTesting style="background-color: #fff" :path="path" />
</template>

<script>
import { mapState } from "vuex";
import RfAbTesting from "@/components/RfPathCreate/RfRetentionExperiment/RfAbTesting.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfRetentionExperimentsList",
  components: {
    RfAbTesting,
  },
  computed: {
    ...mapState({
      path: state => cloneDeep(state.apps.currPath),
    }),
  },
};
</script>
