<template>
  <v-card>
    <div class="promo-hero-div-header">
      <RfLimitWarning v-if="showWarning" />
    </div>
    <div class="promo-creative-module">
      <div class="promo-creative-leftcol">
        <div
          class="retention-view-creative-card h-full"
          @click.stop="showCreativeEditor"
          :class="{
            'pointer-events-none': isInvisible,
          }"
        >
          <RfPromotionThumbnail :item="path" />
        </div>
      </div>
    </div>
    <div class="mt-3 d-flex">
      <v-btn
        v-if="isInvisibleWeb"
        color="#efefef"
        class="mr-5"
        depressed
        style="width: 150px"
        @click="livePreviewShow = true"
      >
        <v-icon left>open_in_new</v-icon>Live Preview
      </v-btn>
      <div class="flex gap-2">
        <v-btn
          v-if="!isInvisible"
          data-cy="rf-promo-edit-button"
          depressed
          color="primary"
          @click.stop="showCreativeEditor"
        >
          <v-icon size="18" left>edit</v-icon>Edit
        </v-btn>
        <v-btn
          v-if="currApp.flags.device_api_implementation"
          depressed
          text
          color="primary"
          @click="$refs.devicePayload.show()"
        >
          View payload
        </v-btn>
      </div>
    </div>
    <RfDeviceApiPayloadModal ref="devicePayload" />
    <RfDomainChooserWrapper
      :app="currApp"
      :visible="livePreviewShow"
      v-on:open="openLivePreview"
      v-on:close="closeLivePreview"
    />
  </v-card>
</template>

<script>
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfLimitWarning from "@/components/RfCommonCards/RfLimitWarning.vue";
import CreativeModuleMixin from "@/utils/CreativeModuleMixin";
import LiveMixin from "@/utils/LiveMixin";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import RfDeviceApiPayloadModal from "@/components/RfDeviceApiPayloadModal.vue";
import { isInvisible } from "@/utils/prompts/promptHelpers";
import { mapState } from "vuex";

export default {
  name: "RfPromoCreativeModuleVertical",
  props: ["path", "clientActions", "showWarning"],
  components: {
    RfPromotionThumbnail,
    RfLimitWarning,
    RfDomainChooserWrapper,
    RfDeviceApiPayloadModal,
  },
  mixins: [CreativeModuleMixin, LiveMixin],
  data: () => ({ livePreviewShow: false }),
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    isInvisible() {
      return isInvisible(this.path);
    },
    isInvisibleWeb() {
      if (!this.path) return false;

      return this.path.path_type === "invisible" && this.path.device_type === "web";
    },
  },
  methods: {
    openLivePreview(domain) {
      this.viewPreview(domain, { actionGroupId: this.path.action_group_id });
      this.closeLivePreview();
    },
    closeLivePreview() {
      this.livePreviewShow = false;
    },
  },
};
</script>
