<template>
  <component
    ref="tooltipAnchorEl"
    :is="to && !props.disabled ? RouterLink : 'button'"
    v-bind="$attrs"
    v-on="$listeners"
    :to="to"
    class="rf-button text-action-buttons inline-flex items-center justify-center rounded"
    :class="{
      [typeClasses[type]]: true,
      [sizeClasses[size]]: !['table'].includes(props.type),
      disabled: props.disabled,
    }"
    @focus.native="() => tooltipEl?.show?.()"
    @focus="() => tooltipEl?.show?.()"
    @mouseenter.native="() => tooltipEl?.show?.()"
    @mouseenter="() => tooltipEl?.show?.()"
    @blur.native="() => tooltipEl?.hide?.()"
    @blur="() => tooltipEl?.hide?.()"
    @mouseleave.native="() => tooltipEl?.hide?.()"
    @mouseleave="() => tooltipEl?.hide?.()"
  >
    <div class="rf-button--container inline-flex items-center gap-2">
      <slot v-if="$slots.default || $scopedSlots.default" />
      <template v-else> {{ text }} </template>
      <RfTooltip
        v-if="disabled && typeof disabled === 'string'"
        ref="tooltipEl"
        :position="tooltipPos"
        :anchor="tooltipAnchorEl?.$el || tooltipAnchorEl"
      >
        <template #tooltip> {{ disabled }} </template>
      </RfTooltip>
    </div>
  </component>
</template>

<script setup>
import { ref } from "vue";
import RfTooltip from "../tooltip/RfTooltip.vue";
import { RouterLink } from "vue-router";

const typeClasses = {
  main: "rf-button--main bg-green-2 !text-white [&.disabled]:!bg-grey-4",
  secondary:
    "rf-button--secondary border border-solid border-blue-1 bg-none [&.disabled]:!border-grey-4 !text-blue-1 [&.disabled]:!text-grey-4",
  none: "rf-button--none",
  table: "rf-button--table p-2 hover:bg-blue-1 hover:bg-opacity-10",
  white: "rf-button--white bg-white p-2 !text-black-2",
};

const sizeClasses = {
  base: "rf-button--base",
  none: "",
};

const props = defineProps({
  text: { type: String, default: "Default" },
  icon: { type: String, default: null },
  tooltipPos: { type: String, default: null },
  to: { type: [Object, String], default: null },
  disabled: { type: [String, Boolean], default: false },
  type: {
    type: String,
    default: "main",
    validator: v => ["main", "secondary", "table", "none", "white"].includes(v),
  },
  size: {
    type: String,
    default: "base",
    validator: v => ["base", "none"].includes(v),
  },
});

const tooltipEl = ref();
const tooltipAnchorEl = ref();
</script>

<style lang="scss" scoped>
::v-deep.rf-button.rf-button--none.disabled .rf-icon {
  @apply opacity-50;
}

::v-deep.rf-button.rf-button--secondary:not(.disabled) {
  &:hover .rf-icon {
    @apply fill-dark-blue-1 #{!important};
  }
  .rf-icon {
    @apply fill-blue-1 #{!important};
  }
}
.rf-button {
  &--base {
    min-height: 40px;
    padding: 8px 32px;
  }

  &--main {
    &:not(.disabled):hover {
      filter: brightness(105%) saturate(87%);
    }
  }

  &--secondary {
    &.rf-button:not(.disabled):hover {
      @apply border-dark-blue-1 text-dark-blue-1 #{!important};
    }
  }
}
</style>
