<template>
  <div class="flex flex-col gap-3 overflow-hidden rounded bg-white p-5">
    <div class="flex items-center justify-between">
      <div class="flex flex-col gap-1">
        <h2 class="text-heading-1 !text-black-1">Custom Goal</h2>
        <div class="text-action-buttons" v-if="!customGoals.length">
          No custom goals for this prompt yet
        </div>
      </div>
      <RfButtonNew
        :disabled="props.disabled"
        :text="customGoals.length ? `Edit custom goal` : `+ Add custom goal`"
        @click="emit('add')"
      />
    </div>
    <RfTable v-if="customGoals.length" class="h-full w-full" :tableData="{ totalCount: 1 }">
      <template #thead>
        <tr class="text-body !text-black-2">
          <td class="px-4 py-2">Name</td>
          <td class="px-4 py-2">Attribution duration</td>
          <td class="px-4 py-2">Label</td>
          <td class="px-4 py-2">Type</td>
          <td class="px-4 py-2">Description</td>
          <td class="px-4 py-2 text-right">Data</td>
          <td class="w-0 px-4 py-2"></td>
        </tr>
      </template>
      <template #tbody>
        <tr
          v-for="customGoal in customGoals"
          :key="customGoal.label"
          class="h-full border-t border-solid border-strokes"
        >
          <td class="text-heading-2 px-4 py-1 !text-black-2">
            {{ customGoal.name }}
          </td>
          <td class="text-body px-4 py-1 !text-black-2">
            {{ duration }}
          </td>
          <td class="text-body px-4 py-1 !text-black-2">
            {{ customGoal.label }}
          </td>
          <td class="text-body px-4 py-1 !text-black-2">
            {{ customGoal.data_type }}
          </td>
          <td class="text-body px-4 py-1 !text-black-2">
            {{ customGoal.description }}
          </td>
          <td class="text-body px-4 py-1 text-right !text-black-2">
            {{ toLocaleNumberString(data) }}
          </td>
          <td class="px-4 py-1">
            <div class="flex items-center justify-end gap-3">
              <RfButtonNew
                :disabled="props.disabled"
                type="none"
                size="none"
                class="h-6 w-6"
                @click="emit('add')"
              >
                <RfEditIcon class="!h-6 !w-6" />
              </RfButtonNew>
              <RfButtonNew
                :disabled="props.disabled"
                type="none"
                size="none"
                class="h-6 w-6"
                @click="emit('delete')"
              >
                <RfDeleteIcon class="!h-6 !w-6" />
              </RfButtonNew>
            </div>
          </td>
        </tr>
      </template>
    </RfTable>
  </div>
</template>

<script setup>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfTable from "../RfTable/RfTable.vue";
import { computed } from "vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";

const emit = defineEmits(["delete", "add"]);

const props = defineProps({
  disabled: { type: Boolean, default: false },
  customFilter: { type: Object, default: () => ({}) },
  currApp: { type: Object, default: () => ({}) },
  duration: { type: String, default: "" },
  data: { type: [Number, String], default: 0 },
});
</script>

<script>
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  mixins: [CustomFieldsMixin],
  computed: {
    customGoals() {
      return Object.entries(this.customFilter || {}).map(([key, value]) =>
        this.appCustomFields.find(item => item.label === key),
      );
    },
  },
};
</script>
