<template>
  <div>
    <RfPromoDetailsInfoRow title="Triggers">
      <template v-slot:promodetail>
        <RfCardTriggerInfo
          v-if="triggers.length"
          :triggers="[...triggers].sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))"
        />
        <div v-else>N/A</div>
        <div class="pd-info-edit pd-info-edit--trigger">
          <RfButton
            icon-size="16"
            title="edit"
            icon="edit"
            color="accent"
            x-small
            :disabled="editDisabled || latestExperimentActive"
            @click="showPromoDetailDialog"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-if="showPromotionInfoDialog"
      v-model="showPromotionInfoDialog"
      width="1200"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Triggers</v-card-title>
        <v-card-text>
          <RfTrigger
            :model="path"
            ref="triggers"
            :isDisabled="latestExperimentActive"
            :triggerType="triggerType"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeDialog"
            >Cancel</v-btn
          >
          <v-btn large @click="submitConfiguration" width="200px" color="primary" depressed>
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfCardTriggerInfo from "@/components/RfCardTriggerInfo.vue";
import RfTrigger from "@/components/RfPathCreate/RfTrigger.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { cloneDeep } from "lodash-es";
import { isLatestExperimentActive } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfPromoTriggersInfo",
  setup: () => ({ toastsStore: useToastsStore() }),
  props: ["path", "experiments", "editDisabled"],
  components: { RfPromoDetailsInfoRow, RfCardTriggerInfo, RfTrigger, RfButton },
  data() {
    return {
      showPromotionInfoDialog: false,
      triggers: this.path.triggers,
    };
  },
  computed: {
    latestExperimentActive() {
      return isLatestExperimentActive({ path: this.path, experiments: this.experiments });
    },
    triggerType() {
      return this.path.device_type === "web" ? "web" : "device";
    },
  },
  methods: {
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    async submitConfiguration() {
      try {
        let newPath = cloneDeep(this.path);
        const isPath = newPath.subject_name === "Path";
        const appId = this.$route.params.aid;
        const isModal = [
          "retention_modal",
          "interstitial",
          "video",
          "invisible",
          "widget",
          "bottom_banner",
        ].includes(newPath.path_type);

        if (isModal && isPath) {
          this.$refs.triggers.fillModel(newPath);
          await this.updatePath({ appId, pathId: newPath.id, modelPath: newPath });
        } else {
          if (isPath) newPath = cloneDeep(this.path.path_group);
          this.$refs.triggers.fillModel(newPath);
          const { toDelete: triggerId } = newPath;
          await this.updatePathGroup({ appId, model: newPath });
          if (triggerId) this.deleteTrigger({ appId, triggerId });
        }
        this.closeDialog();
        this.triggers = newPath.triggers;
        this.toastsStore.create({ type: "success", body: "Item updated successfully!" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath", "updatePathGroup", "deleteTrigger"]),
  },
};
</script>
