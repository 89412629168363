<template>
  <v-card class="promo-tab-details" data-cy="prompt-details-view">
    <div class="promo-details-container">
      <v-card class="promo-details-left">
        <RfLimitWarning />
        <div class="promo-hero-col-wrapper">
          <div class="promo-hero-col left-col">
            <RfPromoDetailsInfoRow title="Prompt ID">
              <template v-slot:promodetail>
                {{ path.id }}
                <div class="pd-info-edit">
                  <RfButton
                    icon-size="16"
                    icon="mdi-content-copy"
                    color="accent"
                    x-small
                    @click="copyToClipboard(path.id, 'Prompt ID copied to clipboard.')"
                  />
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDevicesInfo :path="path" :editDisabled="isDisabledRoleMixin" />

            <template v-if="hasZone">
              <RfPromoZoneInfo :path="path" :key="path.id" />
              <RfZoneDimension :path="pathGroup" :editDisabled="isDisabledRoleMixin" />
            </template>

            <RfPromoSegmentsInfo
              :path="path"
              :editDisabled="isDisabledRoleMixin || latestExperimentActive"
              :pathGroupDisplay="false"
              :pipelineStageId="segmentStageId"
            />
            <RfPromoStageTransition
              v-if="path.pipeline_stage_id"
              :path="path"
              :key="path.pipeline_stage_id + path.id"
              :editDisabled="isDisabledRoleMixin || latestExperimentActive"
            />
            <template v-if="!isPushNotification">
              <RfPromoTriggersInfo
                v-if="!hasZone"
                :path="path"
                :experiments="experiments"
                :editDisabled="isDisabledRoleMixin || latestExperimentActive"
                :key="path.id"
              />
              <RfPromoGoalsInfo
                :path="path"
                :editDisabled="isDisabledRoleMixin || latestExperimentActive"
              />
            </template>
            <RfPromoSequenceInfo :path="path" v-if="path.sequence_id" />
            <RfPromoPipelineInfo :path="path" v-if="path.pipeline_id" />
            <RfPromoLimitsInfo
              v-if="!isPushNotification"
              style="margin-top: 20px; border-top: 1px solid #dae1e7"
              :item="path"
              :editDisabled="isDisabledRoleMixin || latestExperimentActive"
            />
            <RfPromoScheduleInfo
              :item="path"
              :isPushNotification="isPushNotification"
              :editDisabled="isDisabledRoleMixin || latestExperimentActive"
            />
            <template v-if="isInvisibleType">
              <RfPromoInvisibleMeta
                v-if="currApp.feature_set.indexOf('invis_meta') > -1"
                :model="path"
                :actions="actions"
                :app="currApp"
                :editDisabled="isDisabledRoleMixin"
              />
              <div class="inv-showagain-div mt-3">
                <div class="d-flex flex-column">
                  <v-radio-group
                    row
                    class="mt-0 pt-0"
                    hide-details
                    v-model="actions.rf_settings_permanent_display"
                    :disabled="isDisabledRoleMixin"
                  >
                    <v-radio value="false" label="Show based on timer" />
                    <v-radio value="true" label="Show every page load" />
                  </v-radio-group>
                  <RfPromoInteractionInterval
                    interactionType="accept"
                    :action="actions.rf_settings_accept_interval"
                    v-on:setIntervalAction="setIntervalAction"
                    v-if="actions.rf_settings_permanent_display === 'false'"
                  />
                </div>

                <RfButton
                  button-text="Save"
                  color="success"
                  small
                  :disabled="isDisabledRoleMixin"
                  @click="updateActions"
                />
              </div>
            </template>
          </div>
        </div>
      </v-card>
      <v-card class="promo-details-right">
        <RfPromoCreativeModuleVertical
          :path="path"
          :key="creativeComponentKey"
          :clientActions="clientActions"
        />
      </v-card>
      <template v-if="!isPushNotification">
        <v-card class="promo-details-bottom">
          <RfPromoActionsInfoHorizontal
            :model="path"
            :action_group_connector_actions="path.action_group_connector_actions"
            :clientActions="clientActions"
            :editDisabled="isDisabledRoleMixin || latestExperimentActive"
          />
        </v-card>
      </template>
    </div>
  </v-card>
</template>

<script>
import RfLimitWarning from "@/components/RfCommonCards/RfLimitWarning.vue";
import RfPromoActionsInfoHorizontal from "@/components/RfPromotions/RfPromoActionsInfoHorizontal.vue";
import RfPromoCreativeModuleVertical from "@/components/RfPromotions/RfPromoCreativeModuleVertical.vue";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfPromoGoalsInfo from "@/components/RfPromotions/RfPromoGoalsInfo.vue";
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import RfPromoInvisibleMeta from "@/components/RfPromotions/RfPromoInvisibleMeta.vue";
import RfPromoLimitsInfo from "@/components/RfPromotions/RfPromoLimitsInfo.vue";
import RfPromoPipelineInfo from "@/components/RfPromotions/RfPromoPipelineInfo.vue";
import RfPromoScheduleInfo from "@/components/RfPromotions/RfPromoScheduleInfo.vue";
import RfPromoSegmentsInfo from "@/components/RfPromotions/RfPromoSegmentsInfo.vue";
import RfPromoSequenceInfo from "@/components/RfPromotions/RfPromoSequenceInfo.vue";
import RfPromoStageTransition from "@/components/RfPromotions/RfPromoStageTransition.vue";
import RfPromoTriggersInfo from "@/components/RfPromotions/RfPromoTriggersInfo.vue";
import RfPromoZoneInfo from "@/components/RfPromotions/RfPromoZoneInfo.vue";
import RfPromoDevicesInfo from "@/components/RfPromotions/RfPromoDevicesInfo.vue";
import RfZoneDimension from "@/components/RfPromotions/RfZoneDimension.vue";
import RfButton from "@/components/buttons/RfButton.vue";

import RoleMixin from "@/utils/RoleMixin";
import RecentActivityMixin from "@/utils/RecentActivityMixin";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import copyToClipboard from "@/utils/CopyToClipboard";
import CreativeModuleMixin from "@/utils/CreativeModuleMixin";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { isPushNotification, isStandalone } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfRetentionDetails",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: {
    RfLimitWarning,
    RfPromoActionsInfoHorizontal,
    RfPromoCreativeModuleVertical,
    RfPromoDetailsInfoRow,
    RfPromoGoalsInfo,
    RfPromoInteractionInterval,
    RfPromoInvisibleMeta,
    RfPromoLimitsInfo,
    RfPromoPipelineInfo,
    RfPromoScheduleInfo,
    RfPromoSegmentsInfo,
    RfPromoSequenceInfo,
    RfPromoStageTransition,
    RfPromoTriggersInfo,
    RfPromoZoneInfo,
    RfPromoDevicesInfo,
    RfZoneDimension,
    RfButton,
  },
  data: () => ({ creativeComponentKey: 0, copyToClipboard }),
  mixins: [UserSettingsMixin, CreativeModuleMixin, RoleMixin],
  computed: {
    ...mapState({
      pathGroup: state => cloneDeep(state.apps.currPathGroup),
      path: state => cloneDeep(state.apps.currPath),
      retentions: state => cloneDeep(state.apps.retentions),
      experiments: state => state.apps.experiments,
      lastError: state => state.lastError,
      segments: state => state.apps.segments,
      currApp: state => state.apps.currApp,
      appClientActions: state => state.apps.clientActions || [],
    }),
    segmentStageId() {
      if (!this.path.pipeline_stage_id) return null;
      const segment = this.path.segments.find(seg => !!seg.pipeline_stage_id);
      return segment?.pipeline_stage_id || null;
    },
    clientActions() {
      if (this.currApp) {
        const blank = [{ text: "None", value: "" }];
        const actions = this.appClientActions
          .filter(action => action.action_type === "website_action")
          .map(action => {
            return { text: action.name, value: action.id };
          });
        return blank.concat(actions);
      }
      return [];
    },
    isPushNotification() {
      return isPushNotification(this.path);
    },
    hasZone() {
      return !isStandalone(this.path);
    },
  },
  methods: {
    ...mapActions(["getClientActions"]),
  },
  watch: {
    path(to) {
      if (this.updating) {
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
        this.updating = false;
      } else if (this.cloning) {
        this.toastsStore.create({ type: "success", body: "Prompt cloned successfully" });
        this.$router.replace({
          path: `/apps/${this.$route.params.aid}/retentions/${to.id}`,
        });
        this.cloning = false;
      }
      this.creativeComponentKey += 1;
    },
    async lastError(to) {
      if (!to) return;
      if (to.cause === 404) {
        this.$router.replace({
          path: `/apps/${this.$route.params.aid}/prompts`,
        });
      } else {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
  mounted() {
    if (!this.appClientActions.length) this.getClientActions({ appId: this.app.id });
  },
};
</script>
