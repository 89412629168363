<template>
  <div>
    <RfPromoDetailsInfoRow title="Type">
      <template v-slot:promodetail>
        {{ promoDeviceType }} - {{ showPromoType }}
        <div v-if="isCustomDefined" class="pd-info-edit">
          <RfButton
            icon-size="16"
            icon="edit"
            color="accent"
            data-cy="prompt-view--edit-custom-devices"
            x-small
            :disabled="isDisabledRoleMixin"
            @click="$refs.rfCustomDevicesModal.show()"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <RfCustomDevicesModal ref="rfCustomDevicesModal" />
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow";
import RfCustomDevicesModal from "@/components/modals/RfCustomDevicesModal";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin.js";
import PromoTypeMixin from "@/utils/PromoTypeMixin.js";

export default {
  name: "RfPromoDevicesInfo",
  props: ["path", "editDisabled"],
  mixins: [RoleMixin, PromoTypeMixin],
  components: { RfButton, RfPromoDetailsInfoRow, RfCustomDevicesModal },
  computed: {
    isCustomDefined() {
      return this.customDevicesEnabled && this.path.device_type === "custom_defined";
    },
    promoDeviceType() {
      if (!this.path) return "None";
      if (this.customDevicesEnabled && this.path.custom_devices?.length)
        return this.path.custom_devices.map(el => el.name).join(", ");
      return this.getPromoDeviceTypeWording(this.path.device_type);
    },
    showPromoType() {
      if (!this.path) return "None";
      return this.getPromoTypeWording(this.path.path_type);
    },
  },
};
</script>
