<template>
  <div class="pd-container" :style="containerStyle">
    <div class="pd-actions">
      <div style="display: flex">
        <h4 style="font-size: 1.2rem; margin-bottom: 0.6rem">Actions</h4>
        <div class="edit-actions-button" style="margin-left: auto">
          <RfButton
            v-if="connectorActions.length"
            icon-size="16"
            title="edit"
            icon="edit"
            color="accent"
            x-small
            @click="showPromoDetailDialog('connectors')"
          />
        </div>
      </div>
      <div class="pd-actions-details-grid" v-if="connectorActions.length">
        <div v-for="value in connectorActions" :key="value.id" class="pd-actions-detail-horizontal">
          <div class="pd-actions-logo-container">
            <img
              :src="providers[value.connector_action.provider].src"
              :width="providers[value.connector_action.provider].width"
              v-if="
                providers[value.connector_action.provider] &&
                providers[value.connector_action.provider].type === 'img'
              "
            />
            <b v-else>{{ capitalize(value.connector_action.provider) }}</b>
          </div>
          <p class="pd-actions-detail-desc">
            {{ value.connector_action.args.description }}
          </p>
          <ul style="list-style: none">
            <li v-for="(inputValue, inputKey) in value.args" :key="inputKey">
              <v-icon color="success" small>check</v-icon
              >{{
                displayConnectorAction(inputKey, inputValue, value.connector_action.args.inputs)
              }}
            </li>
          </ul>
        </div>
      </div>
      <RfButton
        v-else
        class="no-actions-box"
        :disabled="editDisabled"
        @click="showPromoDetailDialog('connectors')"
      >
        <v-icon large> add </v-icon>
      </RfButton>
    </div>
    <RfPromoDetailsInfoRow
      v-if="
        (app?.flags?.custom_devices_js_sdk &&
          model.device_type === DeviceTypeWording.custom_defined.value) ||
        model.device_type === DeviceTypeWording.web.value
      "
      title="Website Actions"
      style="border-width: 1px"
    >
      <template v-slot:promodetail>
        <div class="actions-ct-inner" v-for="item in activeDisplayActions" :key="item.actionName">
          <div class="webaction-value-div">
            {{ item.title }}:
            <span>
              {{
                isRedirectAction(item.actionName)
                  ? model.actions[item.actionName]
                  : displayWebsiteActions(item.actionName)
              }}</span
            >
          </div>
        </div>
        <div class="pd-info-edit pd-website-action">
          <RfButton
            icon-size="16"
            title="edit"
            icon="edit"
            color="accent"
            x-small
            @click="showPromoDetailDialog('client_actions')"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionActionsDialog"
      width="1280"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-text>
          <RfRetentionActions
            :model="model"
            ref="actions"
            :isDisabled="editDisabled"
            :actionType="actionType"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <RfButton
            icon="save"
            button-text="Save"
            color="primary"
            :disabled="editDisabled"
            large
            width="200px"
            @click="submitConfiguration"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RfRetentionActions from "@/components/RfPathCreate/RfRetentionActions.vue";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import PromoActionMixin from "@/utils/PromoActionMixin";
import ConnectorsMixin from "@/utils/ConnectorsMixin";
import ActionsInfoMixin from "@/utils/ActionsInfoMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import { DeviceTypeWording } from "@/utils/constants/DevicesConstants";
import { mapState } from "vuex";

export default {
  name: "RfPromoActionsInfoHorizontal",
  props: ["model", "action_group_connector_actions", "clientActions", "editDisabled"],
  setup: () => ({ DeviceTypeWording }),
  data() {
    return {
      providers: {
        zendesk: { type: "img", src: require("@/assets/images/logos/zendesk.png"), width: "60" },
        custom_provider: {
          type: "img",
          src: require("@/assets/images/logos/custom.png"),
          width: "60",
        },

        sendgrid: { type: "img", src: require("@/assets/images/logos/sendgrid.png"), width: "70" },
        stripe: { type: "img", src: require("@/assets/images/logos/stripe.png"), width: "50" },
        salesforce: {
          type: "img",
          src: require("@/assets/images/logos/salesforce.png"),
          width: "60",
        },
        braze: {
          type: "img",
          src: require("@/assets/images/logos/logo-braze.svg"),
          width: "60",
        },
        vindicia: { type: "img", src: require("@/assets/images/logos/vindicia.png"), width: "60" },
        braintree: {
          type: "img",
          src: require("@/assets/images/logos/braintree.gif"),
          width: "60",
        },
        shopify: {
          type: "img",
          src: require("@/assets/images/logos/shopify.png"),
          width: "60",
        },
        zuora: {
          type: "img",
          src: require("@/assets/images/logos/zuora-sm.png"),
          width: "60",
        },
        segment: {
          type: "img",
          src: require("@/assets/images/logos/segment_io.png"),
          width: "60",
        },
        redfast: { type: "img", src: require("@/assets/images/logos/redfast.png"), width: "60" },
        chargify: { type: "img", src: require("@/assets/images/logos/chargify.png"), width: "60" },
        freshdesk: {
          type: "img",
          src: require("@/assets/images/logos/freshdesk.png"),
          width: "60",
        },
        recurly: {
          type: "img",
          src: require("@/assets/images/logos/recurly.png"),
          width: "60",
        },
        iterable: {
          type: "img",
          src: require("@/assets/images/logos/iterable_logo.png"),
          width: "80",
        },
      },
    };
  },
  mixins: [PromoActionMixin, ConnectorsMixin, ActionsInfoMixin],
  components: {
    RfPromoDetailsInfoRow,
    RfRetentionActions,
    RfButton,
  },
  computed: {
    ...mapState({ app: state => state.apps.currApp }),
    connectorActions() {
      return this.action_group_connector_actions.filter(
        item => item.connectable_type === "ConnectorAction",
      );
    },
    containerStyle() {
      if (this.model.device_type !== "web") {
        return { "border-bottom": "1px solid #dae1e7" };
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pd-actions {
  padding: 20px;
  border: 1px solid #ddd;
  border-bottom: 0px solid #ddd;
}
.pd-actions-detail-horizontal {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  > * {
    margin-bottom: 5px;

    &:last-child() {
      margin: 0;
    }
  }

  border: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 10px;

  ul {
    white-space: initial;
  }
}
.pd-actions-details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (min-width: 1080px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  > * {
    min-width: 0;
    min-height: 0;
  }
}
.actions-ct-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.pd-actions-detail-desc {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-actions-box {
  height: 128px !important;
  width: 20%;
  background-color: #ccc;
}
.no-actions-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
