export default class ObjectUtils {
  static mergeObjectByKeys(dest, src, keys) {
    if (src) {
      keys.forEach(key => {
        const value = src[key];
        if (!(value == null)) {
          if (Array.isArray(value)) {
            dest[key] = [...value];
          } else if (typeof value === "object") {
            dest[key] = { ...value };
          } else {
            dest[key] = value;
          }
        }
      });
    }
  }

  // returns true if all values in an object are falsey
  static allEmptyValues(object) {
    return !Object.values(object).filter(element => element).length;
  }
}
