export default {
  fill(model, $refs) {
    if (model.path_type === "bottom_banner") {
      this[`${model.device_type}_bottom_banner`](model, $refs);
    } else {
      this[model.device_type](model, $refs);
    }
  },
  web(model, $refs) {
    const devices = {
      web_desktop: "rf_settings_bg_image_web_desktop_dims",
      rfmodal: "rf_settings_bg_image_web_desktop_dims",
      web_mobile: "rf_settings_bg_image_web_mobile_dims",
      rfmodalMobile: "rf_settings_bg_image_web_mobile_dims",
    };

    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].getPreviewDimensions();
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "rfmodal" || device === "web_desktop") {
            model.actions["rf_settings_bg_image_web_desktop_preview_dims"] = dims;
          }
        }
      }
    });
  },
  ios(model, $refs) {
    const devices = {
      ios_ipad: "rf_settings_bg_image_ios_ipad_dims",
      ios_iphone: "rf_settings_bg_image_ios_iphone_dims",
    };

    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].getPreviewDimensions();
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "ios_ipad") {
            model.actions["rf_settings_bg_image_ios_ipad_preview_dims"] = dims;
            model.actions["rf_settings_bg_image_ios_iphone_dims"] = dims;
          }
        }
      }
    });
  },
  android_os(model, $refs) {
    const devices = {
      android_os_tablet: "rf_settings_bg_image_android_os_tv_dims",
      android_os_phone: "rf_settings_bg_image_android_os_phone_dims",
    };
    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].getPreviewDimensions();
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "android_os_phone") {
            model.actions["rf_settings_bg_image_android_os_tablet_preview_dims"] = dims;
            model.actions["rf_settings_bg_image_android_os_tablet_dims"] = dims;
          }
        }
      }
    });
  },
  web_bottom_banner(model, $refs) {
    const devices = {
      rfmodal: "rf_settings_bg_image_web_desktop_dims",
      rfwidgetMobile: "rf_settings_bg_image_web_mobile_dims",
    };

    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].dimensions;
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "rfmodal") {
            model.actions["rf_settings_bg_image_web_desktop_preview_dims"] = dims;
          }
        }
      }
    });
  },
  ios_bottom_banner(model, $refs) {
    const devices = {
      rfmodal: "rf_settings_bg_image_ios_ipad_dims",
      rfwidgetMobile: "rf_settings_bg_image_ios_iphone_dims",
    };

    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].dimensions;
        model.actions[dim_name] = dims;
        if (device === "rfmodal") {
          model.actions["rf_settings_bg_image_ios_ipad_preview_dims"] = dims;
        }
      }
    });
  },
  tv_os_bottom_banner(model, $refs) {
    const devices = {
      rfmodal: "rf_settings_bg_image_tv_os_tv_dims",
    };
    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].dimensions;
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "rfmodal") {
            model.actions["rf_settings_bg_image_tv_os_tv_preview_dims"] = dims;
          }
        }
      }
    });
  },
  roku_os_bottom_banner(model, $refs) {
    const devices = {
      rfmodal: "rf_settings_bg_image_roku_os_tv_dims",
    };
    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].dimensions;
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "rfmodal") {
            model.actions["rf_settings_bg_image_roku_tv_preview_dims"] = dims;
          }
        }
      }
    });
  },
  android_os_bottom_banner(model, $refs) {
    const devices = {
      rfmodal: "rf_settings_bg_image_android_os_tablet_dims",
      rfwidgetMobile: "rf_settings_bg_image_android_os_phone_dims",
    };
    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].dimensions;
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "rfmodal") {
            model.actions["rf_settings_bg_image_android_os_tablet_preview_dims"] = dims;
            model.actions["rf_settings_bg_image_android_os_tv_dims"] = dims;
          }
        }
      }
    });
  },
  custom_defined_bottom_banner(model, $refs) {
    const devices = {
      rfmodal: "rf_settings_bg_image_custom_defined_desktop_dims",
    };
    Object.entries(devices).forEach(([device, dim_name]) => {
      if ($refs[device]) {
        const dims = $refs[device].dimensions;
        if (dims?.length > 0) {
          model.actions[dim_name] = dims;
          if (device === "rfmodal") {
            model.actions["rf_settings_bg_image_custom_defined_desktop_preview_dims"] = dims;
          }
        }
      }
    });
  },
};
