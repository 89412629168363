<template>
  <div class="promo-design-group">
    <v-radio-group row v-model="actions.rf_settings_privacy_policy_type" v-if="!isEmail">
      <v-radio value="link" label="Simple" />
      <v-radio value="long" :label="pathTypeLinkOptionLabel" />
    </v-radio-group>
    <div v-if="actions.rf_settings_privacy_policy_type === 'link'">
      <v-text-field
        outlined
        dense
        label="Privacy Policy Link"
        v-model.trim="actions.rf_settings_privacy_policy_link"
        placeholder="e.g.: http://company.com/pagename"
        hide-details
        style="margin-bottom: 14px"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        :label="pathTypeLabel"
        v-model.trim="actions.rf_settings_tos_link"
        hide-details
        placeholder="e.g.: http://company.com/pagename"
      ></v-text-field>
    </div>
    <div v-if="actions.rf_settings_privacy_policy_type === 'long'">
      <v-textarea
        hide-details
        outlined
        dense
        :label="pathTypeLabelHtml"
        v-model.trim="
          currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')
            ? previewActions.rf_settings_privacy_policy_text
            : actions.rf_settings_privacy_policy_text
        "
        :disabled="currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')"
        rows="3"
      ></v-textarea>
    </div>
  </div>
</template>
<script>
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import { isEmail } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfRetentionActionsLinks",
  props: ["actions", "model", "pathType"],
  mixins: [LanguagePreviewMixin],
  computed: {
    isEmail() {
      return isEmail({ path_type: this.pathType });
    },
    pathTypeLinkOptionLabel() {
      return this.isEmail ? "HTML" : "Long";
    },
    pathTypeLabel() {
      return this.isEmail ? "Unsubscribe Link" : "Terms of Service Link";
    },
    pathTypeLabelHtml() {
      return this.isEmail ? "HTML" : "Terms of Service Link";
    },
  },
};
</script>
