var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"flex items-start justify-between gap-x-16"},[_c('div',{staticClass:"flex flex-col gap-1"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('button',{staticClass:"grid place-items-center",on:{"click":function($event){return _setup.emit('back')}}},[_c(_setup.RfBackIcon,{staticClass:"!h-6 !w-6 !stroke-black-2"})],1),_c('h2',{staticClass:"text-heading-0 !font-medium !text-black-1"},[_vm._v(" "+_vm._s(_setup.props.name || "Unnamed prompt")+" ")]),_c(_setup.RfButtonNew,{staticClass:"grid place-items-center",attrs:{"type":"none","size":"none","disabled":_setup.props.disabled},on:{"click":function($event){return _setup.emit('edit')}}},[_c(_setup.RfEditIcon,{staticClass:"!h-6 !w-6"})],1)],1),_c('span',{staticClass:"text-body ml-10"},[_vm._v(_vm._s(_setup.props.description))])]),_c('div',{staticClass:"inline-grid flex-shrink-0 grid-cols-1 items-center gap-5"},[_c(_setup.RfMenu,{staticClass:"rf-submenu z-10",attrs:{"disabled":_setup.props.disabled,"sameWidth":"","buttonSize":"base","buttonType":"main"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',{staticClass:"inline-flex items-center gap-2"},[_c('span',{staticClass:"text-action-buttons !text-white"},[_vm._v(" Manage ")])])]},proxy:true},{key:"menu",fn:function(){return [_c('div',{staticClass:"list flex w-full flex-col items-start"},[_vm._l(([
                {
                  if: _setup.props.showReset,
                  icon: _setup.RfResetIcon,
                  title: 'Reset',
                  onClick: () => _setup.emit('reset'),
                },
                {
                  if: _setup.props.showClone,
                  icon: _setup.RfCloneIcon,
                  title: 'Clone',
                  onClick: () => _setup.emit('clone'),
                },
                {
                  if: _setup.props.showPriority,
                  icon: _setup.RfPriorityIcon,
                  title: 'Priority',
                  onClick: () => _setup.emit('priority'),
                },
                { icon: _setup.RfDownloadIcon, title: 'Export to CSV', onClick: () => _setup.emit('export') },
                {
                  if: !_setup.props.disabled,
                  icon: _setup.RfDeleteIcon,
                  title: 'Delete',
                  onClick: () => _setup.emit('delete'),
                },
              ]),function(action){return [(action?.if ?? true)?_c('button',{staticClass:"text-action-buttons flex w-full items-center gap-2 px-4 py-3 first:!pt-6 last:!pb-6",on:{"click":action.onClick}},[_c(action.icon,{tag:"component",staticClass:"!h-6 !w-6"}),_vm._v(" "+_vm._s(action.title)+" ")],1):_vm._e()]})],2)]},proxy:true}])})],1)]),_c('div',{staticClass:"grid auto-cols-max grid-cols-3 gap-4 xl:grid-cols-prompt-header"},[_c('div',{staticClass:"inline-flex items-center justify-start"},[(!_vm.statusDisabled)?_c(_setup.RfMenu,{staticClass:"rf-status-menu",attrs:{"sameWidth":"","minFit":"","buttonType":"white","disabled":_setup.props.status.type === _setup.SimplifyStatusesMapConstants.limited.type},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('span',{staticClass:"inline-flex items-center gap-1"},[_c('span',{staticClass:"inline-flex !h-6 !w-6"},[_c(_setup.RfStatus,{staticClass:"m-auto",attrs:{"status":_setup.props.status.type}})],1),_c('span',{staticClass:"text-body inline-block"},[_vm._v(" "+_vm._s(_setup.StatusesConstants[_setup.props.status.type].title)+" ")])])]},proxy:true},{key:"menu",fn:function(){return [_vm._l((_setup.StatusesSimplifiedConstants),function(status){return [(
                status.type === _setup.SimplifyStatusesMapConstants.ready.type
                  ? !_setup.props.isEnabled
                  : ![
                      _setup.SimplifyStatusesMapConstants[_setup.props.status.type].type,
                      _setup.SimplifyStatusesMapConstants.limited.type,
                    ].includes(status.type)
              )?_c('button',{key:status.type,staticClass:"flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4",on:{"click":function($event){return _vm.$emit('update:status', status.type)}}},[_c(_setup.RfStatus,{attrs:{"status":status.type}}),_vm._v(" "+_vm._s(status.action)+" ")],1):_vm._e()]})]},proxy:true}],null,false,2098667702)}):_vm._e()],1),_c('div',{staticClass:"text-body xl:order-0 -order-1 col-span-3 flex items-center justify-start gap-2 xl:col-span-1"},[_c('span',{staticClass:"text-heading-2 !text-black-2"},[_vm._v("Info:")]),_c('button',{staticClass:"inline-flex items-center gap-1 py-1 pl-3 pr-2",on:{"click":function($event){return _vm.$emit('copyId')}}},[_c('span',{staticClass:"text-left"},[_vm._v("ID: "+_vm._s(_setup.props.id))]),_c(_setup.RfCopyIcon,{staticClass:"!h-6 !w-6"})],1),_c('span',{staticClass:"h-6 w-px bg-icon"}),_c('span',{staticClass:"py-1 pl-3 pr-2"},[_vm._v(_vm._s(_setup.props.type))]),_c('span',{staticClass:"h-6 w-px bg-icon"}),_c('span',{staticClass:"py-1 pl-3 pr-2"},[_vm._v("Created by: "+_vm._s(_setup.props.createdBy || "Redfast"))])]),_c('div',{staticClass:"col-span-2 inline-flex items-center justify-end xl:col-span-1"},[_c(_setup.RfDatePicker,{ref:"datePicker",attrs:{"sinceStartDayRef":_setup.props.startDate,"min":_setup.props.oneYearMetrics ? _setup.dayjs().subtract(1, 'year') : _setup.dayjs().subtract(90, 'days'),"max":_setup.dayjs()},on:{"input":v => _setup.emit('update:date', v)}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }