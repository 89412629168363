<template>
  <div :class="parentPathTypeClass">
    <div style="display: flex">
      <v-tabs
        v-model="viewTabModal"
        slider-color="666666"
        dark
        centered
        icons-and-text
        height="50"
        background-color="#000"
      >
        <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">Desktop</v-tab>
        <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')"
          >Mobile Web</v-tab
        >
      </v-tabs>
      <RfLanguagePreview :model="model" :actions="actions" />
    </div>
    <v-tabs-items v-model="viewTabModal">
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
        value="desktopViewTab"
        class="desktop-web-base-bg"
      >
        <RfRetentionInterstitialContent
          :model="model"
          :actions="previewActions"
          deviceType="desktop"
          ref="rfmodal"
          :zoomStyle="zoomStyle"
          :zoomValue="zoomValue"
        />
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" value="mobilewebViewTab">
        <RfWidgetMobileContent
          :actions="previewActions"
          :model="model"
          v-if="isWidgetPromo"
          ref="rfwidgetMobile"
        />
        <RfRetentionModal
          :model="model"
          :actions="previewActions"
          deviceType="mobileWeb"
          ref="web_mobile"
          v-else
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab' && !isWidgetPromo">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>
<script>
import { mapState } from "vuex";
import RfRetentionModal from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModal.vue";
import RfRetentionInterstitialContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionInterstitialContent.vue";
import RfWidgetMobileContent from "@/components/RfPathCreate/RfRetentionActions/RfWidgetMobileContent.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import DimensionSaver from "@/utils/DimensionSaver";

export default {
  name: "RfRetentionInterstitial",
  props: ["actions", "viewTypeTab", "model", "actionGroupId"],
  mixins: [ZoomPreviewMixin, LanguagePreviewMixin],
  components: {
    RfRetentionModal,
    RfRetentionInterstitialContent,
    RfWidgetMobileContent,
    RfLanguagePreview,
  },
  data() {
    return {
      showErrors: true,
      viewTabModal: this.viewTypeTab,
    };
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
    }),
    parentPathTypeClass() {
      return `${this.model.path_type}-editor-liveView editor-view-window`;
    },
    isWidgetPromo() {
      const pathType = this.model.path_type;
      return pathType === "widget";
    },
  },
  methods: {
    getMobilePreviewDimensions() {
      return this.$refs.web_mobile.getPreviewDimensions();
    },
    fillModel(model) {
      if (!model.device_type) {
        model.device_type = this.model.device_type;
      }
      DimensionSaver.fill(model, this.$refs);
    },
  },
  watch: {},
  mounted() {},
};
</script>
