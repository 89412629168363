<template>
  <div class="retention-experiment-page-wrapper">
    <RfAbTestingTable :experiments="experiments" v-on:showItem="showExperiment" :path="path" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import RfAbTestingTable from "@/components/RfPathCreate/RfRetentionExperiment/RfAbTestingTable.vue";
import { getExperimentLink } from "@/utils/getLink";

export default {
  name: "RfAbTesting",
  components: { RfAbTestingTable },
  props: ["path"],
  computed: { ...mapState({ experiments: state => state.apps.experiments }) },
  methods: {
    showExperiment({ id }) {
      this.$router.push({
        path: getExperimentLink(id, this.$route.params.pid, this.$route.params.aid),
      });
    },
  },
};
</script>
