import { getTotals, percentage, tally } from "@/utils/metricsHelpers";
import { saveToCsvFile } from "@/utils/CsvUtils";
import { isCustomGoalExists } from "./prompts/promptHelpers";

export const DISPLAYED_METRICS = {
  "goals": "Accept",
  "goals2": "Secondary Accept",
  "dismissals": "Dismiss",
  "declines": "Decline",
  "timeouts": "Timeout",
  "unique-endpoints-grouped-by-date": "Endpoints",
  "attempted-deliveries-grouped-by-date": "Attempted Deliveries",
  "unique-deliveries-grouped-by-date": "Deliveries",
};

export const CUSTOM_DISPLAYED_METRICS = {
  custom_goals_accepted: "after Accept",
  custom_goals_accepted2: "after Secondary Accept",
  custom_goals_declined: "after Decline",
  custom_goals_dismissed: "after Dismiss/Timeout",
};

const onlyDisplayed =
  displayed =>
  ([key, _]) =>
    Object.keys(displayed).includes(key);
const namingMapper = displayed => key => displayed[key] || key;

export default {
  computed: {
    onlyDisplayedMetrics() {
      return onlyDisplayed(this.DISPLAYED_METRICS);
    },
    namingMapperMetrics() {
      return namingMapper(this.DISPLAYED_METRICS);
    },
    onlyDisplayedMetricsCustom() {
      return onlyDisplayed(this.CUSTOM_DISPLAYED_METRICS);
    },
    namingMapperMetricsCustom() {
      return namingMapper(this.CUSTOM_DISPLAYED_METRICS);
    },
    DISPLAYED_METRICS() {
      return {
        "goals": this.getDisplayMetricsText("rf_retention_button1_text") || DISPLAYED_METRICS.goals,
        "goals2":
          this.getDisplayMetricsText("rf_retention_button2_text") || DISPLAYED_METRICS.goals2,
        "declines":
          this.getDisplayMetricsText("rf_retention_button3_text") || DISPLAYED_METRICS.declines,
        "dismissals": DISPLAYED_METRICS.dismissals,
        "timeouts": DISPLAYED_METRICS.timeouts,
        "unique-endpoints-grouped-by-date": DISPLAYED_METRICS["unique-endpoints-grouped-by-date"],
        "attempted-deliveries-grouped-by-date":
          DISPLAYED_METRICS["attempted-deliveries-grouped-by-date"],
        "unique-deliveries-grouped-by-date": DISPLAYED_METRICS["unique-deliveries-grouped-by-date"],
      };
    },
    CUSTOM_DISPLAYED_METRICS() {
      return {
        custom_goals_accepted: `after ${this.DISPLAYED_METRICS.goals}`,
        custom_goals_declined: `after ${this.DISPLAYED_METRICS.declines}`,
        custom_goals_dismissed: CUSTOM_DISPLAYED_METRICS.custom_goals_dismissed,
      };
    },
  },
  methods: {
    getDisplayMetricsText(key) {
      const model = this.model || this.path;
      if (
        model?.actions?.rf_settings_tile_interaction === "container" &&
        key === "rf_retention_button1_text"
      )
        return "Click";
      if (!model?.filter?.is_translated) return model.actions?.[key];
      return (
        Object.values(model?.parsed_translations || {}).find(el => el?.default === "TRUE")?.[key] ||
        "Click"
      );
    },
    exportPromotionsToCsv(pathGroups, currChartSrc) {
      let hasCustomGoal = false;
      const contents = [];
      const iPeriod = [];
      const uPeriod = [];
      const gPeriod = [];
      const g2Period = [];
      let hasGoal2 = false;
      let periods = ["prompt"];
      pathGroups.forEach((mainItem, index) => {
        const items = this.getPlacementItems(mainItem);
        if (index === 0) {
          periods = [periods, ...this.getPeriodHeader(items[0], "impressions", currChartSrc)];
        }
        hasCustomGoal = !!items.find(isCustomGoalExists);
        items.forEach(item => {
          const segments = item.segments.map(el => el.name).join(", ");
          const impressions = tally(item.data, currChartSrc, "impressions");
          const users = tally(item.data, currChartSrc, "uimpressions");
          const goals = tally(item.data, currChartSrc, "goals");
          const impressionsPeriod = this.getPeriod(item, "impressions", currChartSrc);
          const usersPeriod = this.getPeriod(item, "uimpressions", currChartSrc);
          const goalsPeriod = this.getPeriod(item, "goals", currChartSrc);
          let goals2Period = [];
          const ctr = percentage(goals, impressions, true);
          const convRate = percentage(goals, users, true);
          const totalCustomGoals = getTotals(item.data, "custom_goals_seen", currChartSrc) || 0;

          let row = [
            `"${segments}"`,
            `"${item.name}"`,
            mainItem.path_type,
            `"${mainItem.name}"`,
            impressions,
            users,
            goals,
            ctr,
            convRate,
            ...(hasCustomGoal ? [totalCustomGoals] : []),
          ];

          if (item.data.goals2) {
            hasGoal2 = true;
            const goals2 = tally(item.data, currChartSrc, "goals2");
            goals2Period = this.getPeriod(item, "goals2", currChartSrc);
            const ctr2 = percentage(goals2, impressions, true);

            const convRate2 = percentage(goals2, users, true);
            row = [...row, goals2, ctr2, convRate2];
          }
          contents.push(row);
          uPeriod.push([`"${item.name}"`, ...usersPeriod]);
          iPeriod.push([`"${item.name}"`, ...impressionsPeriod]);
          gPeriod.push([`"${item.name}"`, ...goalsPeriod]);
          if (hasGoal2) {
            g2Period.push(goals2Period);
          }
        });
      });
      let header = [
        "segments",
        "prompt",
        "type",
        "zone",
        "impressions",
        "users",
        "clicks",
        "ctr",
        "conv. rate",
        ...(hasCustomGoal ? ["custom goal"] : []),
      ];
      if (hasGoal2) {
        header = [...header, "clicks 2", "ctr 2", "conv. rate 2"];
      }
      contents.unshift(header);

      contents.push("\u2028", "\u2028", "users");
      uPeriod.unshift(periods);
      uPeriod.push("\u2028", "\u2028", "impressions");
      iPeriod.unshift(periods);
      iPeriod.push("\u2028", "\u2028", "clicks");
      gPeriod.unshift(periods);
      if (hasGoal2) {
        gPeriod.push("\u2028", "\u2028", "clicks 2");
        g2Period.unshift(periods);
      }

      let finalContents = [...contents, ...uPeriod, ...iPeriod, ...gPeriod];
      if (hasGoal2) {
        finalContents = [...finalContents, ...g2Period];
      }

      saveToCsvFile(finalContents, `prompts_${currChartSrc}`);
    },
    getPeriod(item, statType, currChartSrc) {
      const tempStat = item.data[statType];
      if (tempStat && tempStat[currChartSrc]) {
        const chart = tempStat[currChartSrc];
        if (chart) {
          return chart.data.map(el => el.count);
        }
      }
      return [];
    },
    getPeriodHeader(item, statType, currChartSrc) {
      let chart;
      const tempStat = item.data[statType];
      if (tempStat) {
        chart = tempStat[currChartSrc];
      } else {
        const tempStatType = Object.keys(item.data)[0];
        if (!tempStatType) return [];
        chart = item.data[tempStatType][currChartSrc];
      }
      return chart.data.map(dataItem => {
        const tz_offset = 0;
        const symbol = tz_offset < 0 ? "-" : "+";
        let paddedTz = tz_offset.toString().padStart(2, "0");
        paddedTz = `${symbol}${paddedTz}00`;
        const text = this.$moment.utc(`${dataItem.date}${paddedTz}`, "YYYYMMDDHHmmZZ");
        if (currChartSrc === "today") return text.format("hA");

        return text.format("MM/DD");
      });
    },
    exportPipelineToCsv(pipeline, currChartSrc) {
      const contents = [];
      pipeline.pipeline_stages.forEach(stage => {
        const row = [`"${stage.name}"`];
        stage.segments.forEach(segment => {
          const count = this.segmentUsersCount(stage.id, segment.id);
          row.push(`"${segment.name}"`, count);
        });
        contents.push(row);
      });
      const header = [
        "stage",
        "trending up",
        "trending up count",
        "trending down",
        "trending down count",
      ];

      contents.unshift(header);
      contents.push("\u2028", "\u2028", "stage transitions");

      const contents2 = [];
      pipeline.pipeline_stages.forEach(fromStage => {
        pipeline.pipeline_stages.forEach(toStage => {
          const promos = this.getTransitionPromos(fromStage.id, toStage.id);
          if (promos.length > 0) {
            promos.forEach(item => {
              const users = tally(item.data, currChartSrc, "users");
              const goals = tally(item.data, currChartSrc, "goals");
              const convRate = percentage(goals, users, true);
              contents.push([fromStage.name, toStage.name, users, goals, convRate]);
            });
          }
        });
      });

      const header2 = ["from stage", "to stage", "users", "goals", "conv. rate"];
      contents2.unshift(header2);

      saveToCsvFile([...contents, ...contents2], `pipeline_${currChartSrc}`);
    },
  },
};
