<template>
  <RfBaseCard>
    <Transition mode="out-in">
      <RfBaseSkeleton v-if="props.loading" height="20" dynamic width="100" />
      <span v-else class="rf-stats-card--title">{{ title }} </span>
    </Transition>
    <Transition mode="out-in">
      <RfBaseSkeleton v-if="props.loading" height="32" dynamic />
      <span v-else class="rf-stats-card--value">{{ value }}</span>
    </Transition>

    <Transition
      v-if="props.timePeriod === METRICS_PERIODS.last_seven_to_fourteen_days.value"
      mode="out-in"
    >
      <RfBaseSkeleton v-if="props.loading" height="24" dynamic width="80" />
      <span v-else class="rf-stats-card--grow !text-black-2">
        <span ref="tooltipAnchorEl" class="rf-stats-card--grow-icon" :class="growData.class">
          <component v-if="growData.icon" :is="growData.icon" />
          <span v-else aria-hidden="true" class="text-grey-4">—</span>
        </span>
        <span> {{ `${growData.sign}${Math.abs(grow)}%` }} </span>
        <button
          class="w-full h-full absolute"
          @focus="() => tooltipEl.show()"
          @mouseenter="() => tooltipEl.show()"
          @blur="() => tooltipEl.hide()"
          @mouseleave="() => tooltipEl.hide()"
        ></button>
        <RfTooltip ref="tooltipEl" class="w-fit mr-0" :anchor="tooltipAnchorEl">
          <template #tooltip> compared to the {{ timePeriodName }} </template>
        </RfTooltip>
      </span>
    </Transition>

    <span class="absolute top-5 right-5">
      <Transition mode="out-in">
        <span v-if="props.loading"> <RfBaseSkeleton height="40" width="40" radius="6" /> </span>
        <span v-else class="rf-stats-card--icon"> <component :is="icon" /> </span>
      </Transition>
    </span>
  </RfBaseCard>
</template>

<script setup>
import { computed, ref } from "vue";
import RfArrowIcon from "@/components/icons/RfArrowIcon.vue";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfBaseCard from "./RfBaseCard.vue";
import RfBaseSkeleton from "../skeletons/RfBaseSkeleton.vue";
import { METRICS_PERIODS } from "@/utils/constants/MetricsConstants";

const tooltipAnchorEl = ref();
const tooltipEl = ref();

const props = defineProps({
  title: { type: String, default: "" },
  value: { type: [String, Number], default: 0 },
  icon: { type: Object, default: null },
  grow: { type: [String, Number], default: 0 },
  timePeriod: { type: String, default: "" },
  timePeriodName: { type: String, default: "" },
  loading: { type: Boolean, default: true },
});

const growData = computed(() => {
  if (+props.grow > 0) return { class: "rf-stats-card--grow-plus", icon: RfArrowIcon, sign: "+" };
  if (+props.grow < 0) return { class: "rf-stats-card--grow-minus", icon: RfArrowIcon, sign: "-" };
  return { class: "", icon: null, sign: "" };
});
</script>

<style lang="scss" scoped>
.rf-stats-card {
  &--title {
    @apply text-body;
  }

  &--value {
    @apply text-heading-0;
  }

  &--grow {
    @apply gap-2 text-action-buttons items-center inline-flex  relative;

    &-icon {
      @apply rounded-full w-6 h-6 grid place-content-center bg-input-background flex-shrink-0;
    }

    &-plus {
      @apply bg-green-4;
      > * {
        transform: translateY(-1px);
        @apply fill-green-1 #{!important};
      }
    }
    &-minus {
      @apply bg-red-3;
      > * {
        transform: translateY(2px) rotateX(180deg);
        @apply fill-red-2 #{!important};
      }
    }
  }

  &--icon {
    @apply rounded-md bg-input-background inline-flex;
    padding: 10px;

    > * {
      @apply m-auto;
      @apply fill-grey-2 w-5 h-5 #{!important};
    }
  }
}
</style>
