import { mapActions } from "vuex";
import { cloneDeep } from "lodash-es";
import { getButtonTitle } from "./prompts/promptHelpers";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  data() {
    return {
      actionType: "connector",
      showPromotionActionsDialog: false,
      actionGroupConnectorActions: this.action_group_connector_actions || [],
    };
  },
  computed: {
    displayActions() {
      const button1Title =
        getButtonTitle(this.model, "rf_retention_button1_text") ||
        (this.model.path_type === "invisible" ? "accept" : "button 1 click");
      const button2Title =
        getButtonTitle(this.model, "rf_retention_button2_text") || "button 2 click";
      const button3Title =
        getButtonTitle(this.model, "rf_retention_button3_text") || "button 3 click";
      return [
        { actionName: "rf_settings_redirect_location", title: `Redirect upon ${button1Title}` },
        { actionName: "accept", title: `Actions upon ${button1Title}` },
        {
          actionName: "rf_settings_accept2_redirect_location",
          title: `Redirect upon ${button2Title}`,
        },
        { actionName: "accept2", title: `Actions upon ${button2Title}` },
        { actionName: "rf_settings_dismiss_redirect_location", title: "Redirect upon dismiss" },
        { actionName: "dismiss", title: "Actions upon dismiss" },
        {
          actionName: "rf_settings_decline_redirect_location",
          title: `Redirect upon ${button3Title}`,
        },
        { actionName: "decline", title: `Actions upon ${button3Title}` },
        { actionName: "rf_settings_timeout_redirect_location", title: "Redirect upon timeout" },
        { actionName: "timeout", title: "Actions upon timeout" },
      ];
    },
    actions() {
      if (this.model) {
        return this.model.actions;
      }
      return {};
    },
    activeActions() {
      const actions = this.getActiveActions();
      return actions.reduce((hash, [_, value]) => {
        hash[value] = true;
        return hash;
      }, {});
    },
    activeDisplayActions() {
      return this.displayActions.filter(item => this.activeActions[item.actionName]);
    },
  },
  methods: {
    showPromoDetailDialog(type) {
      this.showPromotionActionsDialog = true;
      this.actionType = type;
    },
    closeDialog() {
      this.showPromotionActionsDialog = false;
      this.actionType = null;
    },
    displayWebsiteActions(interactionType) {
      if (this.clientActions) {
        const action =
          this.action_group_connector_actions.find(
            item =>
              item.interaction_type === interactionType && item.connectable_type === "ClientAction",
          ) || {};
        const websiteAction = this.clientActions.find(
          model => model.value === action.connector_action.id,
        );
        if (websiteAction) {
          return websiteAction.text;
        }
        return "WEBSITE ACTION DELETED; PLEASE REMOVE";
      }
      return "None";
    },
    displayConnectorAction(key, value, inputs) {
      let displayKey = key;
      let displayValue = value;
      const displayData = inputs.find(input => input.value_description === key);
      if (displayData) {
        displayKey = displayData.value_label;
        if (displayData.hide_id) {
          const possibleValues = this.fetchValues(displayData);
          if (possibleValues) {
            const name = possibleValues.find(possibleValue => possibleValue.id === value);
            if (name) {
              displayValue = name.name;
            }
          }
        }
      }
      return `${displayKey}: ${displayValue}`;
    },
    capitalize(string) {
      if (string) {
        return string.replace(/^\w/, c => c.toUpperCase());
      }
      return null;
    },
    async submitConfiguration() {
      const toastsStore = useToastsStore();
      try {
        const newPath = cloneDeep(this.model);
        if (this.$refs.actions) {
          this.$refs.actions.fillModel(newPath);
        }
        this.showPromotionActionsDialog = false;
        await this.updatePath({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
          modelPath: newPath,
        });
        toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {
        toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath"]),
  },
};
