<template>
  <div class="rf-category-pg-wrapper" v-if="path">
    <div class="rf-retention-view">
      <div class="all-segments-list-rightcol">
        <div class="rf-detail-content-wrapper retention-detail-container placement-detail-pg">
          <div class="promo-detail-pg-title-btns">
            <div class="promo-dtlpg-title-left">
              <RfPromoNameInfo
                :item="path"
                :promotionView="true"
                :pathGroupDisplay="false"
                :editDisabled="isActiveExperiment"
                :showPromoName="true"
                :showPromoError="false"
              />
            </div>
            <div class="promo-dtlpg-title-right">
              <RfDetailHeader
                v-on:submitDelete="submitDelete"
                v-on:showPriorityListDialog="() => $refs.priorityListDialog.show()"
                v-on:clone="cloneModal?.show()"
                v-on:resetGoals="resetGoals"
                :locked="false"
                :showEdit="false"
                :showClone="!path.sequence_id"
                :showPriority="!isPlacement"
                :objectType="getObjectType"
                :showReset="!!path.last_published_date"
                :showStatus="true"
                :item="path"
              />
            </div>
          </div>
          <div>
            <v-tabs v-model="promoTab" background-color="#E9F0F4">
              <v-tab to="analysis" router>
                <v-icon small style="margin-right: 4px">mdi-chart-line</v-icon>Analysis
              </v-tab>
              <v-tab to="details" data-cy="prompt-view--details-tab" router>
                <v-icon small style="margin-right: 4px">description</v-icon>Details
              </v-tab>
              <v-tab v-if="currApp?.flags?.cohort_card" to="retention" router>
                <v-icon small style="margin-right: 4px">group</v-icon>Retention
              </v-tab>
              <v-tab
                v-if="!this.isPushNotification"
                to="experiments"
                router
                data-cy="prompt-view--experiments-tab"
              >
                <v-icon small style="margin-right: 4px">science</v-icon>Experiments
              </v-tab>
              <v-tab to="history" router>
                <v-icon small dense style="margin-right: 4px">history</v-icon>History
              </v-tab>
            </v-tabs>
            <component :is="tabs[$route.params.tslug]"></component>
          </div>
          <RfRetentionPriority
            v-if="!isPlacement"
            ref="priorityListDialog"
            :retentions="filteredRetentions"
            :currRetention="this.path"
          />
        </div>
      </div>
    </div>
    <RfClonePromptModal
      ref="cloneModal"
      :item="path.path_group"
      :isSuperAdmin="isSuperAdminRoleMixin"
      :isCompanyAdmin="isCompanyAdminMixin"
      :currUser="currUser"
      :currApp="currApp"
      @clone="submitClone"
    />
  </div>
  <RfModalLoading :showDialog="true" v-else />
</template>

<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep, debounce } from "lodash-es";
import PromoTypeMixin from "@/utils/PromoTypeMixin";
import RecentActivityMixin from "@/utils/RecentActivityMixin";
import RfDetailHeader from "@/components/RfDetailHeader.vue";
import RfModalLoading from "@/views/RfModalLoading.vue";
import RfPromoNameInfo from "@/components/RfPromotions/RfPromoNameInfo.vue";
import RfRetentionPriority from "@/views/RfRetentionPriority.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import RfRetentionAnalysis from "@/views/retentions/tabs/RfRetentionAnalysis.vue";
import RfRetentionDetails from "@/views/retentions/tabs/RfRetentionDetails.vue";
import RfRetentionStats from "@/views/retentions/tabs/RfRetentionStats.vue";
import RfRetentionExperimentsList from "@/views/retentions/tabs/RfRetentionExperimentsList.vue";
import RfRetentionHistory from "@/views/retentions/tabs/RfRetentionHistory.vue";
import { isPlacement, isPushNotification, isStandalone } from "@/utils/prompts/promptHelpers";
import { useToastsStore } from "@/pinia/toastsStore";
import RfClonePromptModal from "@/blocks/RfModals/RfClonePromptModal.vue";
import RoleMixin from "@/utils/RoleMixin";
import { ref } from "vue";
import { getPromptLink } from "@/utils/getLink";

export default {
  name: "RfRetentionView",
  setup: () => {
    const cloneModal = ref();
    return { cloneModal, toastsStore: useToastsStore() };
  },
  components: {
    RfDetailHeader,
    RfModalLoading,
    RfPromoNameInfo,
    RfRetentionPriority,
    RfRetentionAnalysis,
    RfRetentionDetails,
    RfRetentionStats,
    RfRetentionExperimentsList,
    RfRetentionHistory,
    RfClonePromptModal,
  },
  mixins: [PromoTypeMixin, RecentActivityMixin, UserSettingsMixin, RoleMixin],
  data() {
    return {
      updating: false,
      creativeComponentKey: 0,
      zoneDimensionsKey: 0,
      deleting: false,
      promoTab: 0,
    };
  },
  computed: {
    ...mapState({
      pathGroup: state => cloneDeep(state.apps.currPathGroup),
      path: state => cloneDeep(state.apps.currPath),
      pathGroups: state => state.apps.pathGroups,
      retentions: state => state.apps.retentions,
      metrics: state => state.apps.currPathMetrics,
      activities: state =>
        state.apps.currPathActivities ? [...state.apps.currPathActivities] : [],
      experiments: state => state.apps.experiments,
      lastError: state => state.lastError,
      sequences: state => state.apps.sequences,
      currApp: state => state.apps.currApp,
      currUser: state => state.apps.currUser,
    }),
    isPushNotification() {
      return isPushNotification(this.path);
    },
    tabs() {
      return {
        analysis: RfRetentionAnalysis,
        details: RfRetentionDetails,
        ...(this.currApp?.flags?.cohort_card && { retention: RfRetentionStats }),
        ...(!this.isPushNotification && { experiments: RfRetentionExperimentsList }),
        history: RfRetentionHistory,
      };
    },
    firstTabKey() {
      return Object.keys(this.tabs)[0];
    },
    getObjectType() {
      const isPromotion = isStandalone(this.path.path_group);
      return isPromotion ? "Prompt" : "Placement item";
    },
    isModalOrVideo() {
      return ["video", "retention_modal"].indexOf(this.path.path_type) > -1;
    },
    isPlacement() {
      return isPlacement(this.path.path_group.path_type);
    },
    isSequencePage() {
      return !!this.$route.path.match("/experiences/");
    },
    isPipelinePage() {
      return !!this.$route.path.match("/pipelines/");
    },
    isRetentionPage() {
      return !!this.$route.path.match("/retentions/");
    },
    isActiveExperiment() {
      if (!this.experiments.length) return false;
      const latestExperiment = this.experiments[this.experiments.length - 1];
      return latestExperiment.is_active;
    },
    deviceTypeFilter() {
      return item => item.device_type === this.path.device_type;
    },
    promoDisplayTypeVal() {
      if (this.isModalOrVideo) {
        return item => ["video", "retention_modal"].indexOf(item.path_type) > -1;
      }
      return item => item.path_type === this.path.path_type;
    },
    filteredRetentions() {
      return (this.retentions || []).filter(this.deviceTypeFilter).filter(this.promoDisplayTypeVal);
    },
    currChartSrc() {
      if (this.$refs.stats) {
        return this.refs.currChartSrc || "last_seven_days";
      }
      return "last_seven_days";
    },
  },
  methods: {
    promoDeviceType(item) {
      return this.getPromoDeviceTypeWording(item.device_type);
    },
    showPromoType(item) {
      return this.getPromoTypeWording(item.path_type);
    },
    submitClone(zone, app, defaultCompany) {
      this.clonePath({
        appId: this.currApp.id,
        pathId: this.path.id,
        pathGroupId: zone,
        targetAppId: app.value,
        defaultCompany,
      })
        .then(path => {
          this.cloneModal?.close();
          if (this.currApp.id === app.value) {
            if (!path?.id) return;
            this.$router.push(`${getPromptLink(path.id, this.currApp.id)}/details`);
          } else {
            this.toastsStore.create({
              type: "success",
              body: `Cloned ${this.path.name} to ${app.text}`,
            });
          }
        })
        .catch(error =>
          this.toastsStore.create({
            type: "error",
            body: error.message,
          }),
        );
    },
    async submitDelete() {
      try {
        if (
          ["horizontal", "text", "tile", "vertical"].indexOf(this.path.path_group.path_type) > -1 &&
          this.path.path_group
        ) {
          const promoPathGroupId = this.path.path_group.id;
          await this.deletePath({
            appId: this.$route.params.aid,
            pathId: this.$route.params.pid,
            path: this.path,
          });
          if (this.isSequencePage) {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/experiences/${this.$route.params.sid}`,
            });
          } else if (this.isPipelinePage) {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/pipelines/${this.$route.params.plid}`,
            });
          } else if (this.isRetentionPage) {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/prompts`,
            });
          } else {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/placements/${promoPathGroupId}`,
            });
          }
        } else {
          await this.deletePathGroupAndPath({
            appId: this.$route.params.aid,
            path: this.path,
          });
          this.deleting = true;
          if (this.isSequencePage) {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/experiences/${this.$route.params.sid}`,
            });
          } else if (this.isPipelinePage) {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/pipelines/${this.$route.params.plid}`,
            });
          } else {
            this.$router.replace({
              path: `/apps/${this.$route.params.aid}/prompts`,
            });
          }
        }
      } catch (e) {}
    },
    resetGoals() {
      this.resetPathGoals({ appId: this.path.app_id, pathId: this.path.id })
        .then(() => this.getPath({ appId: this.$route.params.aid, pathId: this.$route.params.pid }))
        .then(() =>
          this.toastsStore.create({ type: "success", body: "Clicks reset successfully" }),
        );
    },
    isTabSlugAllowed(tabSlug) {
      return tabSlug && Object.keys(this.tabs).includes(tabSlug);
    },
    ...mapActions([
      "getPath",
      "getPathGroups",
      "getExperiments",
      "deletePath",
      "clonePath",
      "resetPathGoals",
      "deletePathGroupAndPath",
    ]),
  },
  mounted() {
    const { pid: pathId, aid: appId, tslug: tabSlug, plid, sid } = this.$route.params;
    Promise.all([this.getPath({ appId, pathId }), this.getExperiments({ pathId })]);

    if (this.isTabSlugAllowed(tabSlug)) return null;

    const tab = this.getUserSetting(`last_visited_path_tab-${pathId}`) || this.firstTabKey;

    if (this.isSequencePage)
      return this.$router.replace({
        path: `/apps/${appId}/experiences/${sid}/promotions/${pathId}/${tab}`,
      });
    if (this.isPipelinePage)
      return this.$router.replace({
        path: `/apps/${appId}/pipelines/${plid}/promotions/${pathId}/${tab}`,
      });

    return this.$router.replace({
      path: `/apps/${appId}/retentions/${pathId}/${tab}`,
    });
  },
  watch: {
    path() {
      if (this.updating) {
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
        this.updating = false;
      }
      this.creativeComponentKey += 1;
    },
    pathGroups() {
      if (this.deleting && !this.isSequencePage && !this.isPipelinePage) {
        this.deleting = false;
        this.$router.replace({
          path: `/apps/${this.$route.params.aid}/prompts`,
        });
      }
    },
    $route: {
      handler: debounce(async function (to) {
        const { pid: pathId, aid: appId, tslug: tabSlug } = to.params;

        if (!this.path || this.path.id !== pathId)
          await Promise.all([this.getPath({ appId, pathId }), this.getExperiments({ pathId })]);

        this.updatePathActivity(appId, pathId);

        if (this.isTabSlugAllowed(tabSlug))
          this.setUserSetting(`last_visited_path_tab-${pathId}`, tabSlug);
      }, 1000),
      immediate: true,
    },
    async lastError(to) {
      if (!to) return;
      if (to.cause === 404) {
        this.$router.replace({
          path: `/apps/${this.$route.params.aid}/prompts`,
        });
      } else {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
