<template>
  <RfRetentionModalMobileContent
    :actions="actions"
    :model="model"
    v-if="deviceType === 'mobileWeb'"
    ref="rfmodalMobile"
  />
  <RfRetentionModalContent
    :actions="actions"
    :deviceType="deviceType"
    :model="model"
    :zoomStyle="zoomStyle"
    :isNewDeviceEditor="isNewDeviceEditor"
    :isMobile="deviceType === 'androidSquare'"
    ref="rfmodal"
    v-else
  />
</template>

<script>
import RfRetentionModalContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModalContent.vue";
import RfRetentionModalMobileContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModalMobileContent.vue";

export default {
  name: "RfRetentionModal",
  props: ["actions", "deviceType", "model", "zoomStyle"],
  props: {
    actions: Object,
    deviceType: String,
    model: Object,
    zoomStyle: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
  },
  components: { RfRetentionModalContent, RfRetentionModalMobileContent },
  methods: {
    getPreviewDimensions() {
      let dimensions;
      if (this.deviceType === "mobileWeb") {
        dimensions = this.$refs.rfmodalMobile.dimensions;
      } else {
        dimensions = this.$refs.rfmodal.dimensions;
      }
      return dimensions;
    },
  },
};
</script>
