export const AppColors = [
  "#1E5AAE",
  "#4887E0",
  "#146BB2",
  "#4D4D4D",
  "#666666",
  "#A56186",
  "#B57D9C",
  "#508747",
  "#67AD5B",
];
