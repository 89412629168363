var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rf-inline-skeleton inline-block relative overflow-hidden after:absolute after:inset-0",style:({
    '--rf-inline-skeleton--width': `${
      _vm.dynamic ? Math.floor(+_vm.width - +_vm.range + Math.random() * (+_vm.range * 2 + 1)) : _vm.width
    }px`,
    '--rf-inline-skeleton--height': `${_vm.height}px`,
    '--rf-inline-skeleton--border-radius': `${_vm.radius}px`,
  }),attrs:{"aria-hidden":"true"}})
}
var staticRenderFns = []

export { render, staticRenderFns }