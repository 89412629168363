<template>
  <RfPromptView v-if="currApp.flags?.prompt_new_ui && !classicViewStore.classicView.prompt" />
  <RfRetentionOldView v-else />
</template>

<script>
import { mapState } from "vuex";
import RfRetentionOldView from "@/views/RfPrompt/RfRetentionOldView.vue";
import RfPromptView from "@/views/RfPrompt/RfPromptView.vue";
import { useClassicViewStore } from "@/pinia/classicViewStore";

export default {
  name: "RfDashboard",
  setup: () => ({ classicViewStore: useClassicViewStore() }),
  components: { RfRetentionOldView, RfPromptView },
  computed: { ...mapState({ currApp: state => state.apps.currApp }) },
};
</script>
