<template>
  <div style="width: 100%">
    <div class="tile-promo-display-selections-div">
      <div class="tile-promo-display-selections-opt" v-if="model.device_type === 'web'">
        <a
          @click="$emit('clickTab', 'desktopViewTab')"
          v-bind:class="[currActiveTab === 'desktopViewTab' ? 'active' : '']"
          >Desktop</a
        >
        <a
          @click="$emit('clickTab', 'mobilewebViewTab')"
          v-bind:class="[currActiveTab === 'mobilewebViewTab' ? 'active' : '']"
          >Mobile</a
        >
      </div>
    </div>
    <RfLanguagePreview :model="model" :actions="actions" />
    <div
      v-if="currActiveTab === 'desktopViewTab'"
      class="promo-text-wrapper"
      :style="tileStyles"
      ref="desktop"
    >
      <span class="rfmodal-close" v-if="closeButtonEnabled">
        <v-icon :style="{ color: fillColor }">close</v-icon>
      </span>
      <div class="promo-text-wrapper-container">
        <div>
          <!-- extra div layer to match brig -->
          <div
            class="promo-text-title"
            :style="titleStyle"
            v-html="previewActions.rf_retention_title"
          ></div>
          <div class="promo-text-body">
            <RfBodyCountdown :actions="actions" />
            <div
              class="promo-text-message"
              :style="messageStyle"
              v-html="previewActions.rf_retention_message"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="mobile"
      class="retention-tile-mweb-preview"
      v-if="currActiveTab === 'mobilewebViewTab'"
    >
      <div class="promo-text-wrapper-mobile-preview">
        <div class="promo-text-wrapper-mobile" :style="mobileTileStyles">
          <span class="rfmodal-close" v-if="closeButtonEnabled">
            <v-icon :style="{ color: fillColor }">close</v-icon>
          </span>
          <div class="promo-text-wrapper-container">
            <div
              class="promo-text-title"
              :style="titleStyleMobile"
              v-html="previewActions.rf_mobile_title"
            ></div>
            <div class="promo-text-body">
              <RfBodyCountdown :actions="actions" />
              <div
                class="promo-text-message"
                :style="messageStyleMobile"
                v-html="previewActions.rf_mobile_message"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component :is="'style'" v-if="previewActions.rf_settings_custom_css">{{
      previewActions.rf_settings_custom_css
    }}</component>
  </div>
</template>

<script>
import InlineMixin from "@/utils/InlineMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import RfBodyCountdown from "./RfBodyCountdown.vue";

export default {
  name: "RfRetentionTileText",
  components: { RfLanguagePreview, RfBodyCountdown },
  mixins: [InlineMixin, LanguagePreviewMixin],
  props: ["actions", "viewTypeTab", "model"],
  computed: {
    currActiveTab() {
      return this.viewTypeTab;
    },
    tileStyles() {
      return {
        "width": this.previewActions.rf_settings_tile_width,
        "height": this.previewActions.rf_settings_tile_height,
        "background-color": this.previewActions.rf_settings_bg_image_color,
        [this.isClickDisabled ? "pointer-events" : null]: "none",
      };
    },
    mobileTileStyles() {
      return {
        "width": this.previewActions.rf_mobile_width,
        "height": this.previewActions.rf_mobile_height,
        "background-color": this.previewActions.rf_settings_bg_image_color,
        [this.isClickDisabled ? "pointer-events" : null]: "none",
      };
    },
    isClickDisabled() {
      return ["true", true].indexOf(this.previewActions.rf_settings_disable_click) > -1;
    },
    messageStyle() {
      return {
        "font-size": this.previewActions.rf_settings_message_font_size,
        "color": this.fillColor,
      };
    },
    fillColor() {
      return this.previewActions.rf_settings_fill_color;
    },
    titleStyle() {
      return {
        "font-size": this.previewActions.rf_settings_title_font_size,
        "color": this.fillColor,
        "padding-top": this.previewActions.rf_settings_title_padding_top,
      };
    },
    titleStyleMobile() {
      return {
        "font-size": this.previewActions.rf_settings_mobile_title_font_size,
        "color": this.fillColor,
      };
    },
    messageStyleMobile() {
      return {
        "font-size": this.previewActions.rf_settings_mobile_message_font_size,
        "color": this.fillColor,
      };
    },
    closeButtonEnabled() {
      return this.previewActions.rf_settings_close_button_enabled === "true" && !this.isSticky;
    },
    isSticky() {
      return (
        this.previewActions.rf_settings_tile_interaction === "container" &&
        this.previewActions.rf_settings_permanent_display === "true"
      );
    },
  },
  mounted() {
    this.previewDimensions = this.getPreviewDimensions();
    this.mobilePreviewDimensions = this.getPreviewDimensions("mobile");
  },
};
</script>
