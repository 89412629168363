import dayjs from "dayjs";

export const dateDisplay = (date, { utc, time } = { utc: false, time: false }) => {
  const dayjsDate = utc ? dayjs(date).utc() : dayjs(date);

  const formatString = time ? "MMM Do YYYY HH:mm" : "MMM Do YYYY";
  return dayjsDate.format(formatString);
};

export const getDateFormat = () => {
  const userLocale = navigator.language || navigator.userLanguage;
  if (userLocale === 'en-US') {
   return "MM/DD/YY"; 
  } else {
    return "DD/MM/YY"; 
  }
}