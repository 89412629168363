<template>
  <RfBaseModal ref="connectorErrorModal" title="Details">
    <template #body>
      <v-card-text v-if="errorDetails" class="action-error-table-modal">
        <v-row>
          <v-col class="action-error-col-1">Date:</v-col>
          <v-col class="action-error-col-2">
            {{ dayjs(errorDetails.timestamp * 1000).format("MMM Do YYYY, h:mm a") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="action-error-col-1">User ID:</v-col>
          <v-col>{{ errorDetails.parsed_message.user_id }}</v-col>
        </v-row>
        <v-row>
          <v-col class="action-error-col-1">Error:</v-col>
          <v-col class="action-error-col-2" v-if="individualErrors || clientAction">
            {{ errorDetails.error }}
          </v-col>
          <v-col class="action-error-col-2" v-else
            >All actions failed with message: {{ errorDetails.error }}</v-col
          >
        </v-row>
        <v-row v-if="individualErrors">
          <v-col class="action-error-col-1">Note:</v-col>
          <v-col class="action-error-col-2">Actions that succeeded are not included here</v-col>
        </v-row>
        <v-simple-table class="rf-settings-table-data" style="margin-top: 20px">
          <thead>
            <tr>
              <th v-if="clientAction">Action Type</th>
              <th v-else>Connector</th>
              <th style="width: 120px" v-if="clientAction">Action Name</th>
              <th style="width: 120px" v-else>Action Type</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody v-if="individualErrors || clientAction">
            <tr v-for="(error, index) in errorDetails.error_messages" :key="index">
              <td>{{ displayConnectorType(error.message) }}</td>
              <td>{{ error.message.description || error.message.operation }}</td>
              <td>{{ error.error }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in errorDetails.parsed_message.items" :key="index">
              <div v-if="item">
                <td>{{ displayConnectorType(item) }}</td>
                <td>{{ item.description }}</td>
                <td>{{ errorDetails.error }}</td>
              </div>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </template>
    <template #actions>
      <RfButton
        icon="clear"
        button-text="Delete"
        color="error"
        class="px-7"
        :disabled="props.disabled"
        @click="deleteError"
      />
      <RfButton
        v-if="!clientAction"
        icon="autorenew"
        button-text="Retry"
        color="primary"
        class="px-7"
        :disabled="props.disabled"
        @click="retryAndDelete"
      />
      <RfButton button-text="Close" outlined class="cancel-btn px-5" @click="close" />
    </template>
  </RfBaseModal>
</template>

<script setup>
import RfButton from "@/components/buttons/RfButton.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import store from "@/store";
import StringUtils from "@/utils/StringUtils";
import dayjs from "dayjs";
import { computed, ref } from "vue";

const props = defineProps({
  disabled: { type: Boolean, default: false },
  prompt: { type: Object, default: () => ({}) },
});

const toastsStore = useToastsStore();
const connectorErrorModal = ref();
const errorDetails = ref(null);

const clientAction = computed(() => errorDetails.value?.parsed_message?.client_action);

const individualErrors = computed(
  () => errorDetails.value?.error_messages?.length && !clientAction.value,
);

const show = error => {
  errorDetails.value = error;
  connectorErrorModal.value.show?.();
};

const close = () => {
  connectorErrorModal.value.close?.();
  errorDetails.value = null;
};

const displayConnectorType = error =>
  error?.provider ? StringUtils.capitalize(error.provider) : "";

const retryAndDelete = async () => {
  await store.dispatch("retryAndDeleteConnectorError", {
    appId: props.prompt.app_id,
    pathId: props.prompt.id,
    connectorError: errorDetails.value,
  });
  await store.dispatch("getPath", {
    appId: props.prompt.app_id,
    pathId: props.prompt.id,
  });
  toastsStore.create({ type: "success", body: "Retry Successful" });
  close();
};

const deleteError = async () => {
  await store.dispatch("deleteConnectorError", {
    appId: props.prompt.app_id,
    pathId: props.prompt.id,
    connectorError: errorDetails.value,
  });
  await store.dispatch("getPath", {
    appId: props.prompt.app_id,
    pathId: props.prompt.id,
  });
  toastsStore.create({ type: "success", body: "Error deleted" });
  close();
};

defineExpose({ show });
</script>
