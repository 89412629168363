import { defineStore } from "pinia";
import { ref } from "vue";

export const useClassicViewStore = defineStore("classicView", () => {
  const classicView = ref({
    prompt: false,
  });

  return { classicView };
});
