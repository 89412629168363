import { render, staticRenderFns } from "./RfBaseSkeleton.vue?vue&type=template&id=a052662c&scoped=true"
import script from "./RfBaseSkeleton.vue?vue&type=script&setup=true&lang=js"
export * from "./RfBaseSkeleton.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RfBaseSkeleton.vue?vue&type=style&index=0&id=a052662c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a052662c",
  null
  
)

export default component.exports