<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    <RfStatsCard
      title="Total users"
      :loading="props.loading"
      :value="toLocaleNumberString(props.users.curr)"
      :grow="~~calcDiff(props.users.curr, props.users.last)"
      :icon="RfNounUserIcon"
      :timePeriod="timePeriod"
      :timePeriodName="timePeriodName"
    />
    <RfStatsCard
      title="Total impressions"
      :loading="props.loading"
      :value="toLocaleNumberString(props.impressions.curr)"
      :grow="~~calcDiff(props.impressions.curr, props.impressions.last)"
      :icon="RfNounEyeIcon"
      :timePeriod="timePeriod"
      :timePeriodName="timePeriodName"
    />
    <RfStatsCard
      title="Total clicks"
      :loading="props.loading"
      :value="toLocaleNumberString(props.clicks.curr)"
      :grow="~~calcDiff(props.clicks.curr, props.clicks.last)"
      :icon="RfNounClickIcon"
      :timePeriod="timePeriod"
      :timePeriodName="timePeriodName"
    />
    <RfStatsCard
      title="Average CTR"
      :loading="props.loading"
      :value="`${props.ctr.curr}%`"
      :grow="~~calcDiff(+props.ctr.curr, +props.ctr.last)"
      :icon="RfNounCtrIcon"
      :timePeriod="timePeriod"
      :timePeriodName="timePeriodName"
    />
  </div>
</template>

<script setup>
import RfNounUserIcon from "@/components/icons/RfNounUserIcon.vue";
import RfNounEyeIcon from "@/components/icons/RfNounEyeIcon.vue";
import RfNounClickIcon from "@/components/icons/RfNounClickIcon.vue";
import RfNounCtrIcon from "@/components/icons/RfNounCtrIcon.vue";
import RfStatsCard from "@/components/cards/RfStatsCard.vue";
import MetricsUtils from "@/utils/MetricsUtils";
import { computed } from "vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";

const calcDiff = (v2, v1) => (!v2 && !v1 ? 0 : ((v2 - v1) / v1) * 100);

const props = defineProps({
  users: { type: Object, default: () => ({ curr: 0, last: 0 }) },
  impressions: { type: Object, default: () => ({ curr: 0, last: 0 }) },
  clicks: { type: Object, default: () => ({ curr: 0, last: 0 }) },
  ctr: { type: Object, default: () => ({ curr: 0, last: 0 }) },
  timePeriod: { type: String, default: MetricsUtils.All_Periods[0].value },
  loading: { type: Boolean, default: true },
});

const timePeriodName = computed(
  () =>
    props.timePeriod &&
    MetricsUtils.All_Periods.find(el => el.value === props.timePeriod).name.toLowerCase(),
);
</script>
