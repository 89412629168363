<template>
  <div>
    <RfCustomGoalInteractionSelector
      :model="path"
      ref="interaction_selector"
      v-if="hasCustomGoal && !isInvisible"
      style="padding-bottom: 20px; justify-content: right"
      :key="selectedExperiment.id"
    />
    <v-simple-table class="experiments-results-table">
      <thead>
        <tr>
          <th>Variation</th>
          <th class="tbl-width-1">Users</th>
          <template v-if="!isInvisible">
            <th class="tbl-width-1" v-if="customGoalInteraction !== 'seen'">Impressions</th>
            <th class="tbl-width-1">{{ interactionsTitle }}</th>
            <th v-if="shouldShowCTR">{{ ctrTitle }}</th>
          </template>
          <th v-if="hasCustomGoal">Conversions</th>
          <th>Conversion Rate</th>
          <th>Statistical Significance</th>
          <th>Winner</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in variationsDisplay"
          :key="idx"
          :class="item.is_winner ? 'winner-row' : 'default-row'"
        >
          <td>
            <a class="exp-var-name-href" @click="$emit('showVariantDetails', item)">{{
              item.name
            }}</a>
          </td>
          <template v-if="!isInvisible">
            <td>{{ formatUsers(item) }}</td>
            <td v-if="customGoalInteraction !== 'seen'">{{ formatImpressions(item) }}</td>
          </template>
          <td>{{ formatGoals(item) }}</td>
          <td v-if="!isInvisible && shouldShowCTR">{{ formatCTR(item) }}</td>
          <td v-if="hasCustomGoal">
            {{ formatCustomGoals(item) }}
          </td>
          <td>{{ formatConversion(item) }}</td>
          <td>
            <span :style="confidenceIndicator(item).style">{{
              confidenceIndicator(item).confidence
            }}</span>
          </td>
          <td>
            <v-icon size="30" color="#dcc214" v-if="item.is_winner">emoji_events</v-icon>
          </td>
        </tr>
        <tr>
          <td>Total</td>
          <template v-if="!isInvisible">
            <td>{{ totalUsersData }}</td>
            <td v-if="customGoalInteraction !== 'seen'">{{ totalImpressionsData }}</td>
          </template>
          <td>{{ totalGoalsData }}</td>
          <td v-if="!isInvisible && shouldShowCTR"></td>
          <td v-if="hasCustomGoal">{{ totalCustomGoalsData }}</td>
          <td></td>
          <td v-if="accept2Enabled"></td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="flex justify-end">
      <v-btn
        depressed
        class="mr-2"
        text
        color="primary"
        @click="exportExperimentsToCsv(variationsDisplay)"
      >
        Export to CSV
      </v-btn>
    </div>
  </div>
</template>
<script>
import MetricsMixin from "@/utils/MetricsMixin";
import RfCustomGoalInteractionSelector from "@/components/RfCommonCards/RfCustomGoalInteractionSelector.vue";
import CustomGoalInteractionMixin from "@/utils/CustomGoalInteractionMixin";
import { percentage } from "@/utils/metricsHelpers";
import { isAccept2Enabled, isInvisible } from "@/utils/prompts/promptHelpers";
import ExperimentCsvExportMixin from "@/utils/ExperimentCsvExportMixin";
import StringUtils from "@/utils/StringUtils";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { interactionDenominators } from "@/utils/constants/PromoInteractionConstants";

export default {
  name: "RfRetentionExperimentResultTable",
  props: ["selectedExperiment", "path"],
  setup: () => ({ formatStatsFixed: StringUtils.formatStatFixed }),
  mixins: [MetricsMixin, CustomGoalInteractionMixin, ExperimentCsvExportMixin],
  components: { RfCustomGoalInteractionSelector },
  data: () => ({}),
  methods: {
    totalData(field, skipHoldout, additional) {
      let count = this.selectedExperiment.variations.reduce((accum, item) => {
        if (skipHoldout && item.is_control) return accum;
        return accum + item.stats[field];
      }, 0);
      if (additional) count += additional;
      if (count >= 0) {
        return this.formatStatsLocale(count);
      }
    },
    formatImpressions(item) {
      if (item.is_control) return "-";
      return this.formatStatsLocale(item.stats.impressions);
    },
    formatUsers(item) {
      let stats = item.stats[this.usersKey];
      if (item.is_control) stats = item.stats.uholdouts;
      return this.formatStatsLocale(stats);
    },
    formatStatsLocale: stat => (stat ? toLocaleNumberString(stat) : "N/A"),
    formatGoals(item) {
      if (item.is_control)
        return this.isInvisible && item.uholdouts ? toLocaleNumberString(item.uholdouts) : "-";

      const statType = interactionDenominators[this.customGoalInteraction];
      const stats = item.stats[statType];

      return this.formatStatsLocale(stats);
    },
    formatCustomGoals(item) {
      let stats;
      if (item.is_control) {
        stats = item.stats.custom_goals_holdout;
      } else {
        stats = item.stats[`custom_goals_${this.customGoalInteraction}`];
      }
      return this.formatStatsLocale(stats);
    },
    formatConversion(item) {
      if (item.is_control && !this.hasCustomGoal) return "-";
      let numerator = item.stats[`custom_goals_${this.customGoalInteraction}`] || 0;
      if (item.is_control) numerator = item.stats.custom_goals_holdout || 0;
      const denominator = item.stats[item.is_control ? "uholdouts" : this.usersKey] || 0;
      return this.formatStatsFixed(percentage(numerator, denominator));
    },
    formatCTR(item) {
      if (item.is_control) return "-";
      if (this.customGoalInteraction === "accepted2") return this.formatCTR2(item);

      return this.formatStatsFixed(percentage(item.stats.goals, item.stats.impressions));
    },
    formatCTR2(item) {
      return item.is_control
        ? "-"
        : this.formatStatsFixed(percentage(item.stats.goals2, item.stats.impressions));
    },
    totalStatByKey(item, key) {
      // just an alias
      return item && item.stats && item.stats[key];
    },
    totalVisitorsData(key) {
      const count = this.selectedExperiment.variations.reduce((accum, item) => {
        return accum + item.stats[key];
      }, 0);
      if (count >= 0) {
        return this.formatStatsLocale(count);
      }
      return "N/A";
    },
  },
  computed: {
    isInvisible() {
      return isInvisible(this.path);
    },
    usersKey() {
      return this.isInvisible ? "users" : "uimpressions";
    },
    model() {
      // just an alias
      return this.path;
    },
    accept2Enabled() {
      return !!this.selectedExperiment.variations.find(isAccept2Enabled);
    },
    totalGoalsData() {
      const statType = interactionDenominators[this.customGoalInteraction];
      return this.totalData(statType, true);
    },
    totalCustomGoalsData() {
      const custom_goals_holdout =
        (this.holdoutVariant && this.holdoutVariant.stats.custom_goals_holdout) || 0;
      return this.totalData(
        `custom_goals_${this.customGoalInteraction}`,
        true,
        custom_goals_holdout,
      );
    },
    totalImpressionsData() {
      const count = this.selectedExperiment.variations
        .filter(item => item.is_visible || !item.is_control)
        .reduce((accum, item) => {
          return accum + item.stats.impressions;
        }, 0);
      if (count >= 0) {
        return this.formatStatsLocale(count);
      }

      return this.totalVisitorsData("impressions");
    },
    totalUsersData() {
      const count = this.selectedExperiment.variations.reduce(
        (accum, item) => accum + item.stats[item.is_control ? "uholdouts" : this.usersKey],
        0,
      );
      if (count >= 0) {
        return this.formatStatsLocale(count);
      }
      return "";
    },
    holdoutVariant() {
      return this.selectedExperiment.variations.find(item => item.is_control);
    },
    holdoutTraffic() {
      if (this.holdoutVariant) return this.holdoutVariant.traffic_percent;
      return 0;
    },
    variationsDisplay() {
      return this.selectedExperiment.variations.filter(item => item.traffic_percent > 0);
    },
  },
};
</script>
<style scoped>
.exp-var-name-href {
  min-width: 150px;
  display: inline-block;
}
.tbl-width-1 {
  min-width: 110px;
}
</style>
