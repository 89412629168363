<template>
  <div :class="parentPathTypeClass">
    <div class="flex">
      <v-tabs
        v-if="['web', 'android_os'].includes(model.device_type)"
        v-model="viewTabModal"
        slider-color="666666"
        dark
        centered
        icons-and-text
        height="50"
        background-color="#000"
      >
        <template v-if="model.device_type === 'android_os'">
          <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">
            {{ "TV" }}
          </v-tab>
          <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')">
            {{ "Mobile & Tablet" }}
          </v-tab>
        </template>
        <template v-else-if="model.device_type === 'web'">
          <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">Desktop</v-tab>
          <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')">
            Mobile Web
          </v-tab>
        </template>
      </v-tabs>
      <RfLanguagePreview :model="model" :actions="actions" />
    </div>
    <v-tabs-items class="h-full" v-model="viewTabModal">
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
        value="desktopViewTab"
        class="desktop-web-base-bg"
      >
        <RfRetentionBannerContent
          :model="model"
          :actions="previewActions"
          deviceType="desktop"
          ref="rfmodal"
          :zoomStyle="zoomStyle"
          :zoomValue="zoomValue"
        />
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" value="mobilewebViewTab">
        <RfWidgetMobileContent :actions="previewActions" :model="model" ref="rfwidgetMobile" />
      </v-tab-item>
    </v-tabs-items>
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab' && !isWidgetPromo">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>

<script>
import RfRetentionBannerContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionBannerContent.vue";
import RfWidgetMobileContent from "@/components/RfPathCreate/RfRetentionActions/RfWidgetMobileContent.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import DimensionSaver from "@/utils/DimensionSaver";

export default {
  name: "RfRetentionBanner",
  props: ["actions", "viewTypeTab", "model", "actionGroupId"],
  mixins: [ZoomPreviewMixin, LanguagePreviewMixin],
  components: {
    RfRetentionBannerContent,
    RfWidgetMobileContent,
    RfLanguagePreview,
  },
  data() {
    return { showErrors: true, viewTabModal: this.viewTypeTab };
  },
  computed: {
    parentPathTypeClass() {
      return `${this.model.path_type}-editor-liveView editor-view-window`;
    },
    isWidgetPromo() {
      const pathType = this.model.path_type;
      return pathType === "widget";
    },
  },
  methods: {
    getMobilePreviewDimensions() {
      return this.$refs.rfwidgetMobile.getPreviewDimensions();
    },
    fillModel(model) {
      if (!model.device_type) {
        model.device_type = this.model.device_type;
      }
      DimensionSaver.fill(model, this.$refs);
    },
  },
};
</script>
