<template>
  <RfBaseTag v-if="getComputedData" :class="getComputedData.class">
    {{ getComputedData.text }}
  </RfBaseTag>
</template>

<script>
import RfBaseTag from "./RfBaseTag.vue";

export default {
  components: { RfBaseTag },
  props: {
    date: { type: Date, default: new Date() },
    isExpired: { type: Boolean, default: false },
    isLimited: { type: Boolean, default: false },
  },
  computed: {
    getComputedData() {
      try {
        const amountOfDaysFromToday = Math.floor((new Date() - this.date) / (24 * 3600 * 1000));

        if (this.isLimited) return { class: "rf-date-tag--limited", text: "Limited" };

        const expiresSoon = amountOfDaysFromToday >= -6 && amountOfDaysFromToday <= 0;
        if (expiresSoon) return { class: "rf-date-tag--expiring-soon", text: "Expiring soon" };

        const isExpired = amountOfDaysFromToday >= -1;
        if (isExpired || this.isExpired) return { class: "rf-date-tag--expired", text: "Expired" };

        return null;
      } catch (error) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-date-tag {
  &--expired {
    @apply text-grey-3 bg-input-background;
  }
  &--expiring-soon {
    @apply text-orange-1 bg-orange-2;
  }
  &--limited {
    @apply text-orange-1 bg-orange-2;
    filter: hue-rotate(310deg);
  }
}
</style>
