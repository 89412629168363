export default {
  data() {
    return {
      previewDimensions: "",
      mobilePreviewDimensions: "",
    };
  },
  methods: {
    getPreviewDimensions(refKey = "desktop") {
      const htmlItem = this.$refs[refKey];
      if (!htmlItem) return "";
      const { clientWidth, clientHeight } = htmlItem;
      if (clientWidth && clientHeight) {
        return `${clientWidth}x${clientHeight}`;
      }
      return "";
    },
    fillModel(model) {
      const devices = [
        "rf_settings_bg_image_web_desktop_dims",
        "rf_settings_bg_image_web_desktop_preview_dims",
        "rf_settings_bg_image_web_mobile_dims",
      ];
      devices.forEach(device => {
        if (device === "rf_settings_bg_image_web_mobile_dims") {
          model.actions[device] =
            this.mobilePreviewDimensions || this.getPreviewDimensions("mobile");
        } else {
          model.actions[device] = this.previewDimensions || this.getPreviewDimensions();
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.previewDimensions = this.getPreviewDimensions();
      this.mobilePreviewDimensions = this.getPreviewDimensions("mobile");
    });
  },
};
