import { render, staticRenderFns } from "./RfTableRow.vue?vue&type=template&id=89a4b814&scoped=true"
import script from "./RfTableRow.vue?vue&type=script&setup=true&lang=js"
export * from "./RfTableRow.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RfTableRow.vue?vue&type=style&index=0&id=89a4b814&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89a4b814",
  null
  
)

export default component.exports