<template>
  <div class="w-full">
    <RfLanguagePreview
      v-if="model.device_type !== 'tv_os' || currApp?.flags?.legacy_apple_editor"
      :model="model"
      :actions="actions"
    />
    <RfRetentionModal
      :model="model"
      :actions="previewActions"
      :zoomStyle="zoomStyle"
      deviceType="tv_os"
      ref="tv"
      :retentionTypeVideo="retentionTypeVideo"
      :isNewDeviceEditor="!currApp?.flags?.legacy_apple_editor && model.device_type === 'tv_os'"
    />
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab'">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>
<script>
import RfRetentionModal from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModal.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import { mapState } from "vuex";
import { DeviceTypeWording } from "@/utils/constants/DevicesConstants";

const keys = {
  [DeviceTypeWording.tv_os.value]: "rf_settings_bg_image_tv_os_tv_dims",
  [DeviceTypeWording.roku_os.value]: "rf_settings_bg_image_roku_os_tv_dims",
  [DeviceTypeWording.custom_defined.value]: "rf_settings_bg_image_custom_defined_desktop_dims",
};

export default {
  name: "RfRetentionDeviceTvOs",
  props: ["actions", "viewTypeTab", "model", "retentionTypeVideo"],
  components: { RfRetentionModal, RfLanguagePreview },
  mixins: [ZoomPreviewMixin, LanguagePreviewMixin],
  data() {
    return {
      showErrors: true,
      viewTabModal: this.viewTypeTab,
    };
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
  },
  methods: {
    fillModel(model) {
      model.actions[keys[this.model.device_type || DeviceTypeWording.roku_os.value]] =
        this.$refs.tv.getPreviewDimensions();
    },
  },
};
</script>
