<template>
  <tr>
    <td>
      <div class="items-start flex h-full w-full">
        <div>{{ trigger.name }}</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div>{{ upperCaseEachWord(displayTriggerType) }}</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div>{{ displayPage(trigger) }}</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div>{{ regexDisplay(trigger) }}</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div>{{ trigger.delay_seconds || "0" }}</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div class="settings-triggers-tbl-ea trig-clickid">{{ trigger.click_id }}</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div class="settings-triggers-tbl-ea" v-if="displayClickCount">
          {{ displayClickCount }}
        </div>
        <div class="setting-empty-val" v-else>N/A</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div class="settings-triggers-tbl-ea" v-if="displayCustomTrigger">
          {{ displayCustomTrigger }}
        </div>
        <div class="setting-empty-val" v-else>N/A</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div class="settings-triggers-tbl-ea" v-if="displayCustomInterval">
          {{ displayCustomInterval }}
        </div>
        <div class="setting-empty-val" v-else>N/A</div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <div class="settings-triggers-tbl-ea center-ct-align">
          <v-icon size="16" color="info" v-if="trigger.trigger_type === 'easy_button'"
            >fas fa-check</v-icon
          >
          <v-icon size="16" color="#dddddd" v-else>check_box_outline_blank</v-icon>
        </div>
      </div>
    </td>
    <td>
      <div class="items-start flex h-full w-full">
        <ul class="list-deleted-trigger">
          <li v-for="prompt in triggerPrompts.slice(0, showPromptEntries)" :key="prompt.id">
            <router-link :to="getPromptLink(prompt.id, $route.params.aid)">{{
              prompt.name
            }}</router-link>
          </li>
          <template v-if="triggerPrompts.length > 3">
            <template v-if="showPromptEntries < triggerPrompts.length">
              <li>...</li>
              <button class="text-blue-1" @click="showPromptEntries = triggerPrompts.length">
                Show more
              </button>
            </template>
            <template v-else-if="showPromptEntries === triggerPrompts.length">
              <button class="text-blue-1" @click="showPromptEntries = 3">Show less</button>
            </template>
          </template>
        </ul>
      </div>
    </td>
    <td class="triggers_edit_column" align="right">
      <div class="items-start flex h-full w-full">
        <div class="settings-triggers-tbl-btn">
          <RfButton
            :icon="!!isDisabledRoleMixin ? 'mdi-information-variant' : 'edit'"
            color="accent"
            @click="$emit('edit')"
          />
          <RfButton
            icon="clear"
            color="error"
            :disabled="isDisabledRoleMixin"
            @click="$emit('remove')"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import RoleMixin from "@/utils/RoleMixin";
import StringUtils from "@/utils/StringUtils";
import { getPromptLink } from "@/utils/getLink";
import {
  displayCustomInterval,
  displayCustomTrigger,
  displayPage,
  regexDisplay,
} from "@/utils/triggerHelpers";
import RfButton from "../buttons/RfButton.vue";

export default {
  props: ["trigger", "customTriggers", "retentions"],
  components: { RfButton },
  mixins: [RoleMixin],
  setup: () => ({
    regexDisplay,
    displayPage,
    getPromptLink,
    upperCaseEachWord: StringUtils.upperCaseEachWord,
  }),
  data: () => ({ showPromptEntries: 3 }),
  computed: {
    displayCustomInterval() {
      return displayCustomInterval(this.trigger, "device");
    },
    displayClickCount() {
      return this.trigger.trigger_type !== "device" ? this.trigger.click_count_threshold : null;
    },
    displayTriggerType() {
      return this.trigger.trigger_type === "easy_button" ? "live" : this.trigger.trigger_type;
    },
    displayCustomTrigger() {
      return displayCustomTrigger(this.trigger, this.customTriggers, "device");
    },
    triggerPrompts() {
      return this.retentions.filter(ret => !!ret.triggers.find(({ id }) => id === this.trigger.id));
    },
  },
};
</script>

<style lang="scss" scoped></style>
