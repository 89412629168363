<template>
  <div class="rf-main-content-wrapper gap-4">
    <div class="flex min-h-10 min-w-px">
      <h2 class="my-auto text-xl font-medium uppercase not-italic">Live</h2>
    </div>
    <div class="flex items-center justify-between gap-4">
      <div class="inline-flex items-center gap-4">
        <RfTableFilter
          buttonSize="base"
          buttonType="white"
          class="rf-status-menu z-10"
          :selected="filters"
          :filters="{
            devices: { el: getAppDevices, key: 'value', value: 'text', name: 'Device Platform' },
            prompts: { el: getAllPrompts(), key: 'key', value: 'value', name: 'Prompt type' },
          }"
          @selected="items => (filters = { ...filters, ...items })"
        >
          <template #activator>
            <span class="text-body !text-black-2"> Device / type </span>
          </template>
        </RfTableFilter>
        <RfTableFilter
          buttonSize="base"
          buttonType="white"
          class="rf-status-menu z-10"
          :selected="filters"
          :filters="{
            activities: { el: allActivites, key: 'key', value: 'value' },
          }"
          @selected="items => (filters = { ...filters, ...items })"
        >
          <template #activator>
            <span class="text-body !text-black-2"> Activity type </span>
          </template>
        </RfTableFilter>
      </div>
      <div class="inline-flex items-center gap-4">
        <RfInput v-model="searchInput" placeholder="Search by User ID" />
        <RfButtonNew
          :disabled="skeleton"
          class="w-40 px-0"
          @click="fetchMetrics(), startAutoUpdate()"
        >
          <span v-if="skeleton" class="flex items-center justify-center gap-2">
            <RfSpinnerIcon class="!h-6 !w-6 animate-spin !fill-white" />
            Updating
          </span>
          <span v-else class="flex items-center justify-center gap-2">
            <RfResetIcon class="!h-5 !w-5 !stroke-white" />
            Update
            {{ interval.currentTick ? `in ${interval.MAX_TICKS - interval.currentTick}` : "" }}
          </span>
        </RfButtonNew>
        <RfDatePicker
          ref="datePicker"
          :min="
            currApp?.flags?.one_year_metrics
              ? dayjs().subtract(1, 'year')
              : dayjs().subtract(90, 'days')
          "
          :max="dayjs()"
          @input="v => (date = v)"
        />
      </div>
    </div>
    <div class="flex items-center gap-4">
      <TransitionGroup
        class="flex flex-grow-0 items-center gap-4 overflow-y-hidden overflow-x-scroll"
        tag="div"
      >
        <template v-for="(filter, filterKey) in filters">
          <RfBaseTag
            v-for="(el, key) in filter"
            :key="`${filterKey}-${key}`"
            @clear="removeFilter(filterKey, key)"
          >
            {{ el }}
          </RfBaseTag>
        </template>
      </TransitionGroup>
      <Transition mode="out-in">
        <button
          v-if="filtersLength"
          key="clear-button"
          class="text-body flex-shrink-0"
          @click="filters = {}"
        >
          Clear all filters ({{ filtersLength }})
        </button>
      </Transition>
    </div>
    <div class="flex flex-col gap-3 rounded bg-white p-5">
      <Transition mode="out-in">
        <h2 class="text-heading-1 !text-black-1">Activity</h2>
      </Transition>
      <Transition mode="out-in">
        <div class="flex flex-col">
          <div
            v-if="!skeleton && hasData && dataValues?.total"
            class="text-action-buttons mx-auto inline-flex gap-1 !text-black-2"
          >
            <span class="font-bold">{{ toLocaleNumberString(dataValues?.total) }}</span>
            events
          </div>
          <div
            v-if="!skeleton && hasData"
            class="rf-prompt-data--chart-wrapper flex flex-col gap-2 pt-6"
          >
            <div class="rf-prompt-data--chart">
              <canvas ref="chart"></canvas>
            </div>
          </div>
          <div v-else-if="!skeleton && !hasData" class="flex h-72">
            <span class="text-action-buttons m-auto font-bold"> There is no data. </span>
          </div>
          <div v-else class="py-9 pt-9">
            <div class="flex items-start gap-5">
              <div class="flex flex-grow-0 flex-col items-end gap-6">
                <RfBaseSkeleton v-for="i in 5" :key="i" width="40" height="16" />
                <RfBaseSkeleton width="24" height="16" />
              </div>
              <div class="flex w-px flex-col">
                <div
                  class="bg-stripes mt-2 flex h-52 flex-grow gap-2 before:border-b-2 before:border-solid before:border-b-grey-1"
                >
                  <div
                    v-for="i in 6"
                    :key="i"
                    class="flex h-52 flex-col items-center justify-end gap-3"
                    style="margin-top: 26px"
                  >
                    <RfBaseSkeleton
                      height="100"
                      dynamicHeight
                      rangeHeight="50"
                      class="!w-full !max-w-24 !rounded-b-none"
                    />
                    <RfBaseSkeleton width="40" height="16" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div class="flex flex-col gap-3 rounded">
      <RfTable
        :tableData="table"
        class="rf-table"
        :rowSkeleton="RfTableRowSkeleton"
        :rowSkeletonProps="{ alternative: tabs[tab].key === 'live-errors' }"
        :loading="skeleton && !hasData"
        @loadMore="() => initTable(true)"
      >
        <template #pretable>
          <div class="flex flex-col gap-3 rounded-t bg-white px-5 pb-3 pt-5">
            <Transition mode="out-in">
              <h2 class="text-heading-1 !text-black-1">Live</h2>
            </Transition>
            <RfButtonTabs :active="tab">
              <RfButtonNew
                v-for="({ name, key }, i) in tabs"
                type="none"
                :key="key"
                class="px-4 py-2 !text-black-2"
                :class="{ '!font-bold': i === tab }"
                @click="tab = i"
              >
                {{ name }}
              </RfButtonNew>
            </RfButtonTabs>
          </div>
        </template>
        <template #thead>
          <RfTableHeader
            :errorTab="tabs[tab].key === 'live-errors'"
            :sort="sort"
            @update:sort="v => (sort = v)"
            @update:filters="refetch()"
          />
        </template>
        <template #tbody>
          <template v-if="renderLength">
            <RfTableRow
              v-for="i in renderLength"
              :errorTab="tabs[tab].key === 'live-errors'"
              v-bind="data[tabs[tab].key]?.[date.preset?.value]?.hits[i - 1]"
              :key="data[tabs[tab].key]?.[date.preset?.value]?.hits[i - 1]?.document_id || i"
              :activity="
                allActivites?.[data[tabs[tab].key]?.[date.preset?.value]?.hits?.[i - 1]?.activity]
                  ?.value
              "
              :appId="currApp.id"
            />
          </template>
          <tr v-if="table.showing >= 500" key="max">
            <td colspan="100%">
              <div class="text-body w-full text-center">
                These are the first 500 results matching your search, refine your search to see
                others
              </div>
            </td>
          </tr>
        </template>
      </RfTable>
    </div>
  </div>
</template>

<script>
import RfInput from "@/components/inputs/RfInput.vue";
import { computed, onUnmounted, reactive, ref, watch, watchEffect } from "vue";
import { getAllDevices, getAllPrompts } from "@/utils/getDeviceName";
import RfTableFilter from "@/blocks/RfTable/RfTableFilter.vue";
import RfTableRow from "@/blocks/RfLive/RfTableRow.vue";
import RfTableHeader from "@/blocks/RfLive/RfTableHeader.vue";
import RfTableRowSkeleton from "@/blocks/RfLive/RfTableRowSkeleton.vue";
import { useTableFilter } from "@/utils/composables/useTableFilter";
import RfBaseTag from "@/components/tags/RfBaseTag.vue";
import { getAllActivities } from "@/utils/constants/PromoInteractionConstants";
import dayjs from "dayjs";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import { METRICS_PERIODS } from "@/utils/constants/MetricsConstants";
import RfButtonTabs from "@/components/buttons/RfButtonTabs.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import ApiApps from "@/apis/ApiApps";
import { useRoute } from "vue-router/composables";
import { debounce } from "@/utils/debounce";
import { useTableData } from "@/pinia/piniaUtils";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import store from "@/store";
import { useTableSort } from "@/utils/composables/useTableSort";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import RfNoChartDataPlaceholderAlter from "@/components/placeholder/RfNoChartDataPlaceholderAlter.vue";
import { chartColors, useBarChart } from "@/utils/composables/useBarChart";
import RfResetIcon from "@/components/icons/RfResetIcon.vue";
import RfSpinnerIcon from "@/components/icons/RfSpinnerIcon.vue";
import MetricsUtils from "@/utils/MetricsUtils";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { toLocaleNumberString } from "@/utils/stringHelpers";

export default {
  name: "RfLiveView",
  components: {
    RfInput,
    RfTableFilter,
    RfTableRow,
    RfTableHeader,
    RfBaseTag,
    RfDatePicker,
    RfButtonTabs,
    RfButtonNew,
    RfTable,
    RfBaseSkeleton,
    RfNoChartDataPlaceholderAlter,
    RfResetIcon,
    RfSpinnerIcon,
  },
  mixins: [UserSettingsMixin],
  setup() {
    const colors = ref([...chartColors]);
    const tabs = [
      {
        name: "Activities",
        key: "live-activities",
        color: colors.value.splice(~~(Math.random() * colors.value.length), 1)[0],
      },
      {
        name: "Errors",
        key: "live-errors",
        color: colors.value.splice(~~(Math.random() * colors.value.length), 1)[0],
      },
    ];
    const route = useRoute();
    const data = ref({});
    const interval = reactive({ value: null, currentTick: 0, MAX_TICKS: 30 });
    const searchInput = ref("");
    const skeleton = ref(true);
    const tab = ref(0);
    const date = ref({});
    const debugData = ref(false);
    const chart = ref(null);
    const destroyChart = ref(() => null);
    const { filters, removeFilter, filtersLength, filtersConverted } = useTableFilter();
    const { table, resetPage } = useTableData();
    const sort = useTableSort(["default"]);

    const currApp = computed(() => store.state?.apps?.currApp);
    const getAppDevices = computed(() =>
      getAllDevices(currApp.value.custom_devices, currApp.value.flags.custom_devices),
    );

    const dataValues = computed(
      () => data.value?.[tabs[tab.value].key]?.[date.value?.preset?.value],
    );
    const renderLength = computed(() => {
      const dataLength = dataValues.value?.hits?.length || 0;
      return Math.min(dataLength, (table.page || 1) * table.perPage);
    });

    const requestMetrics = debounce(async () => {
      try {
        resetPage();
        table.perPage = 50;
        table.totalCount = 50;
        if (debugData.value) date.value.preset = METRICS_PERIODS.custom;
        const device_types = filtersConverted.value.devices?.array;
        const path_types = filtersConverted.value.prompts?.array;
        const activities = filtersConverted.value.activities?.array;

        const filters = {
          ...(device_types && { device_types }),
          ...(path_types && { path_types }),
          ...(activities && { activities }),
        };

        const params = {
          metric_key: tabs[tab.value].key,
          metric_periods: [
            {
              period: date.value.preset.value || METRICS_PERIODS.last_week.value,
              ...(date.value.preset.value === "custom" && {
                date_range: [
                  date.value.startDate.format("YYYY-MM-DD"),
                  date.value.endDate.format("YYYY-MM-DD"),
                ],
              }),
            },
          ],
          ...(Object.keys(filters).length && { filters }),
          ...(searchInput.value && { search: { user_id: searchInput.value } }),
          ...(debugData.value && { dummy_response: true }),
        };

        const v = await ApiApps.getLiveData(route.params.aid, params);
        data.value = {
          ...data.value,
          [tabs[tab.value].key]: {
            ...data.value[tabs[tab.value].key],
            [date.value.preset.value]: v[date.value.preset.value].agg_data,
          },
        };
        initTable();
      } catch (error) {
      } finally {
        skeleton.value = false;
      }
    }, 330);

    const fetchMetrics = () => {
      skeleton.value = true;
      return requestMetrics();
    };

    const initTable = (next = false) => {
      if (next) return (table.page = table.page + 1);
      table.page = 1;
      table.totalCount = data.value[tabs[tab.value].key][date.value.preset.value].hits.length;
      table.totalPages = table.totalCount / table.perPage;
    };

    const startAutoUpdate = () => {
      const shouldUpdate =
        [
          METRICS_PERIODS.this_month.value,
          METRICS_PERIODS.this_week.value,
          METRICS_PERIODS.today.value,
          METRICS_PERIODS.last_seven_days.value,
        ].includes(date.value.preset.value) ||
        (date.value.preset.value === METRICS_PERIODS.custom.value &&
          (date.value.startDate.isToday() || date.value.endDate.isToday()));
      clearInterval(interval.value);
      interval.currentTick = 0;
      if (shouldUpdate) {
        interval.value = setInterval(autoUpdate, 1000);
        interval.currentTick++;
      }
    };

    const autoUpdate = () => {
      if (interval.currentTick < interval.MAX_TICKS) return interval.currentTick++;

      interval.currentTick = 0;
      fetchMetrics();
    };

    onUnmounted(() => {
      clearInterval(interval.value);
      destroyChart.value();
    });

    watch(date, startAutoUpdate, { deep: true });

    watch([searchInput, tab, date, filters, sort], () => fetchMetrics(), { deep: true });

    const metricsFormatted = computed(() => {
      const dataLength = dataValues.value?.aggregations?.length || 0;
      if (dataLength === 0) return [];
      const chartData = [];
      chartData.push({
        label: "Users",
        data: Object.fromEntries(dataValues.value.aggregations.map(el => [el.date, el.count])),
        backgroundColor: tabs[tab.value].color,
      });
      return chartData;
    });

    const hasData = computed(
      () =>
        !!metricsFormatted.value?.find(
          metricType =>
            !!metricType &&
            !!Object.values(metricType.data || {}).find(
              el => !!(typeof el === "object" ? el.label : el),
            ),
        ),
    );

    watchEffect(() => {
      if (!skeleton.value && hasData.value && chart.value)
        destroyChart.value = useBarChart(chart, metricsFormatted.value);
    });

    return {
      colors,
      tabs,
      route,
      data,
      interval,
      searchInput,
      skeleton,
      tab,
      date,
      debugData,
      chart,
      filters,
      removeFilter,
      filtersLength,
      filtersConverted,
      table,
      resetPage,
      sort,
      currApp,
      getAppDevices,
      dataValues,
      renderLength,
      fetchMetrics,
      initTable,
      startAutoUpdate,
      autoUpdate,
      metricsFormatted,
      hasData,
      RfTableRowSkeleton,
      getAllDevices,
      getAllPrompts,
      dayjs,
      chart,
      datePicker: ref(),
      allActivites: getAllActivities(),
      toLocaleNumberString,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const preset = MetricsUtils.All_Periods.find(
        ({ value }) =>
          value !== "since_start_date" && value === this.getUserSetting("liveDateRange"),
      )?.value;

      this.$refs.datePicker?.setTimeFromPreset(preset || METRICS_PERIODS.last_seven_days.value);
    });
  },
  watch: {
    "date.preset.value"(v) {
      if (v !== "custom") this.setUserSetting("liveDateRange", v);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.rf-status-menu {
  .rf-menu--button {
    @apply px-6;
  }
}

::v-deep.rf-table .rf-table--wrapper {
  @apply rounded-b bg-white px-5 pb-5;
}

.rf-prompt-data--chart {
  height: 240px;
}
.bg-stripes {
  position: relative;
  background: repeating-linear-gradient(
    0deg,
    #999999 0px,
    #999999 1px,
    transparent 1px,
    transparent 40px
  );
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(
      90deg,
      white 4px,
      white 8px,
      transparent 8px,
      transparent 13px
    );
  }
}
</style>
