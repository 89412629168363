<template>
  <v-dialog v-model="newExperimentDialog" width="640" max-width="640">
    <v-card>
      <v-card-title class="headline">Create a new experiment</v-card-title>
      <v-card-text style="margin-top: 10px">
        <v-text-field
          outlined
          dense
          label="Name"
          v-model.trim="experimentName"
          name="experimentName"
          required
          hide-details
          style="margin-bottom: 1rem"
          v-validate="'required'"
          placeholder="Experiment's name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn x-large depressed class="cancel-btn" outlined @click="$emit('closeCreateDialog')"
          >Cancel</v-btn
        >
        <v-btn x-large depressed color="primary" style="width: 170px" @click="saveExperiment"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { getExperimentLink } from "@/utils/getLink";

export default {
  name: "RfPromoCreateExperimentModal",
  props: ["newExperimentDialog", "path"],
  setup: () => ({ toastsStore: useToastsStore() }),
  data: () => ({ experimentName: "", updating: false }),
  computed: {
    ...mapState({
      experiment: state => state.tmp.experiment,
      currExperiment: state => cloneDeep(state.apps.currExperiment),
    }),
  },
  methods: {
    ...mapActions(["createExperiment"]),
    async saveExperiment() {
      try {
        const newExperiment = cloneDeep(this.experiment);
        newExperiment.algorithm = "weighed_sample";
        if (!this.experimentName) throw new Error("Experiment name can't be empty");
        newExperiment.name = this.experimentName;
        this.updating = true;
        newExperiment.variations = [
          {
            name: "Control",
            traffic_percent: 0,
            is_control: true,
            is_baseline: false,
            is_visible: false,
            actions: {},
            action_group_connector_actions: [],
            triggers: [],
          },
          {
            name: "Original",
            traffic_percent: 0,
            is_control: false,
            is_baseline: true,
            is_visible: true,
            actions: this.path.actions,
            action_group_connector_actions: this.path.action_group_connector_actions,
            triggers: this.path.triggers,
          },
        ];
        this.$emit("closeCreateDialog");
        await this.createExperiment({
          pathId: this.path.id,
          modelExperiment: newExperiment,
        });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
  watch: {
    currExperiment(to) {
      if (this.updating) {
        this.updating = false;
        this.toastsStore.create({ type: "success", body: "Experiment created successfully" });
        this.$router.replace({
          path: getExperimentLink(to.id, this.$route.params.pid, this.$route.params.aid),
        });
      }
    },
  },
};
</script>
