import { mapState, mapMutations } from "vuex";
import { confidenceIndicatorColor } from "@/utils/StatsUtils";
import { percentage } from "./metricsHelpers";
import { isCustomGoalExists } from "./prompts/promptHelpers";
import CustomFieldsMixin from "./CustomFieldsMixin";
export default {
  mixins: [CustomFieldsMixin],
  computed: {
    ...mapState({
      customGoalInteraction: state => state.apps.customGoalInteraction,
      app: state => state.apps.currApp,
    }),
    interactionText() {
      return {
        seen: "Seen",
        accepted: this.getDisplayMetricsText("rf_retention_button1_text") || "Accepted (1st)",
        accepted2: this.getDisplayMetricsText("rf_retention_button2_text") || "Accepted (2nd)",
        declined: this.getDisplayMetricsText("rf_retention_button3_text") || "Declined",
        dismissed: "Dismissed",
      };
    },
    customGoalInteractionName() {
      return this.interactionText[this.customGoalInteraction];
    },
    hasCustomGoal() {
      return isCustomGoalExists(this.model);
    },
    goalsTitle() {
      if (this.hasCustomGoal)
        return (
          this.appCustomFields.find(el => el.label === Object.keys(this.model.custom_filter)[0])
            ?.name || "Custom Goal"
        );
      return "Goals";
    },
    interactionsTitle() {
      return this.customGoalInteractionName;
    },
    shouldShowCTR() {
      return (
        this.customGoalInteraction === "accepted" || this.customGoalInteraction === "accepted2"
      );
    },
    ctrTitle() {
      if (this.customGoalInteraction === "accepted2") return "CTR (additional)";
      return "CTR";
    },
    significanceInteraction() {
      return this.customGoalInteraction ? `(${this.customGoalInteractionName})` : "";
    },
    conversionRateValue() {
      return percentage(this.totalCustomGoals, this.totalUsers);
    },
    displayConversionRate() {
      return this.conversionRateValue || this.conversionRateValue === 0
        ? `${this.conversionRateValue.toFixed(2)}%`
        : "N/A";
    },
    conversionRateDefaultValue() {
      return this.totalUsers ? percentage(this.totalGoals, this.totalUsers) : 0;
    },
    displayConversionRateDefault() {
      return this.conversionRateDefaultValue
        ? `${this.conversionRateDefaultValue.toFixed(2)}%`
        : "N/A";
    },
    conversionRateAdditionalValue() {
      return this.totalUsers ? percentage(this.totalGoals2, this.totalUsers) : 0;
    },
    displayConversionRateAdditional() {
      return this.conversionRateAdditionalValue
        ? `${this.conversionRateAdditionalValue.toFixed(2)}%`
        : "N/A";
    },
    CTRValue() {
      return this.totalImpressions ? percentage(this.totalGoals, this.totalImpressions) : 0;
    },
    displayCTR() {
      return this.CTRValue?.toFixed ? `${this.CTRValue.toFixed(2)}%` : "N/A";
    },
  },
  methods: {
    ...mapMutations(["mutateCustomGoalInteraction"]),
    confidenceKey() {
      const confidence =
        this.holdoutTraffic > 0 ? "confidence_vs_holdout" : "confidence_vs_baseline";

      return `${confidence}_${this.customGoalInteraction}`;
    },
    confidenceIndicator(item) {
      const key = this.confidenceKey();
      let confidence;
      if (item.is_control || (item.is_baseline && this.holdoutTraffic <= 0)) confidence = "-";

      if (!confidence) {
        const data = this.totalStatByKey(item, key) || {};
        if (data.confidence) {
          confidence = data.confidence;
        } else {
          confidence = "-";
        }
      }

      return { style: { color: confidenceIndicatorColor(confidence) }, confidence };
    },
  },
  watch: {
    hasCustomGoal(to) {
      if (!to) this.mutateCustomGoalInteraction(null);
    },
  },
};
