import moment from "moment";
import {
  isAccept2Enabled,
  isCustomGoalExists,
  isEmail,
  isInvisible,
} from "./prompts/promptHelpers";
import {
  getTotals,
  percentage,
  getPeriodHeader,
  getPeriod,
  getHigh,
  tally,
} from "./metricsHelpers";

export const exportSinglePromptToCsv = (prompt, period) => {
  const impressionsLabel = isEmail(prompt) ? "Sends" : "Impressions";
  const accept2Enabled = isAccept2Enabled(prompt);
  const hasCustomGoal = isCustomGoalExists(prompt);

  //! rewrite
  const statType = isInvisible(prompt) ? "goals" : "uimpressions";
  const totalUsers = prompt.data?.[statType]?.[period]?.uniques || 0;

  const totalGoals = getTotals(prompt.data, "goals", period);
  const totalGoals2 = getTotals(prompt.data, "goals2", period);
  const totalImpressions = getTotals(prompt.data, "impressions", period);
  const totalCustomGoals = getTotals(prompt.data, "custom_goals_seen", period) || 0;

  const CTRValue = (
    (totalImpressions ? percentage(totalGoals, totalImpressions) : 0) / 100
  ).toFixed(4);
  const displayConversionRateDefault = (
    (totalUsers ? percentage(totalGoals, totalUsers) : 0) / 100
  ).toFixed(4);
  const conversionRate = (
    (totalCustomGoals ? percentage(totalCustomGoals, totalUsers) : 0) / 100
  ).toFixed(4);
  const conversionRateAdditionalValue = (
    (totalUsers ? percentage(totalGoals2, totalUsers) : 0) / 100
  ).toFixed(4);

  const headers = [
    "Users",
    impressionsLabel,
    "CTR",
    "Clicks",
    ...(hasCustomGoal ? ["Custom Goal"] : []),
    "Conv. Rate",
    ...(!hasCustomGoal && accept2Enabled ? ["Custom Goal (additional)"] : []),
  ];
  const values = [
    totalUsers,
    totalImpressions,
    CTRValue,
    totalGoals,
    ...(hasCustomGoal ? [totalCustomGoals] : []),
    ...(hasCustomGoal ? [conversionRate] : [displayConversionRateDefault]),
    ...(!hasCustomGoal && accept2Enabled ? [conversionRateAdditionalValue] : []),
  ];
  saveToCsvFile([headers, values], `prompt_${prompt.name}_${period}`);
};

export const exportMultipleSegmentsToCSV = (segments = [], period) => {
  const contents = [];
  const periods = getPeriodHeader(segments[0], "users", period);
  periods.forEach((date, dateIndex) => {
    const row = [date];
    segments.forEach(segment => {
      const periodRange = getPeriod(segment, "users", period);
      row.push(periodRange[dateIndex]);
    });
    contents.push(row);
  });

  const header = ["date", ...segments.map(item => item.name)];

  contents.unshift(header);
  saveToCsvFile(contents, `segments_${period}`);
};

export const exportMultipleGuidesToCSV = (guides = [], period) => {
  const contents = [];
  let hasGoal2 = false;
  guides.forEach(mainItem => {
    const items = mainItem.paths;
    items.forEach(item => {
      const segments = mainItem.segments.map(({ name }) => name).join(", ");
      const impressions = tally(item.data, period, "impressions");
      const users = tally(item.data, period, "uimpressions");
      const goals = tally(item.data, period, "goals");
      const ctr = percentage(goals, impressions, true);
      const convRate = percentage(goals, users, true);
      let row = [
        `"${mainItem.name}"`,
        `"${segments}"`,
        `"${item.name}"`,
        item.path_type,
        impressions,
        users,
        goals,
        ctr,
        convRate,
      ];

      if (item.data.goals2) {
        hasGoal2 = true;
        const goals2 = tally(item.data, period, "goals2");
        const ctr2 = percentage(goals2, impressions, true);

        const convRate2 = percentage(goals2, users, true);
        row = [...row, goals2, ctr2, convRate2];
      }
      contents.push(row);
    });
  });
  let header = [
    "guides",
    "segments",
    "prompt",
    "type",
    "impressions",
    "users",
    "clicks",
    "ctr",
    "conv. rate",
  ];
  if (hasGoal2) {
    header = [...header, "clicks 2", "ctr 2", "conv. rate 2"];
  }
  contents.unshift(header);

  saveToCsvFile(contents, `guides_${period}`);
};

export const exportMultiplePromptToCsv = (prompts = [], period) => {
  const promptsEmailAspect = prompts.reduce((acc, el) => acc + (isEmail(el) ? -1 : +1), 0);
  const impressionsLabel = !promptsEmailAspect
    ? "Sends/Impressions"
    : promptsEmailAspect < 0
      ? "Sends"
      : "Impressions";
  const accept2Enabled = !!prompts.find(isAccept2Enabled);
  const hasCustomGoal = !!prompts.find(isCustomGoalExists);

  const headers = [
    "Prompt name",
    "Users",
    impressionsLabel,
    "CTR",
    "Clicks",
    ...(hasCustomGoal ? ["Custom Goal"] : []),
    "Conv. Rate",
    ...(!hasCustomGoal && accept2Enabled ? ["Custom Goal (additional)"] : []),
  ];

  const values = [];

  prompts.forEach(prompt => {
    const statType = isInvisible(prompt) ? "goals" : "uimpressions";
    const totalUsers = prompt.data?.[statType]?.[period]?.uniques || 0;

    const totalGoals = getTotals(prompt.data, "goals", period);
    const totalGoals2 = getTotals(prompt.data, "goals2", period);
    const totalImpressions = getTotals(prompt.data, "impressions", period);
    const totalCustomGoals = getTotals(prompt.data, "custom_goals_seen", period) || 0;

    const CTRValue = (
      (totalImpressions ? percentage(totalGoals, totalImpressions) : 0) / 100
    ).toFixed(4);
    const displayConversionRateDefault = (
      (totalUsers ? percentage(totalGoals, totalUsers) : 0) / 100
    ).toFixed(4);
    const conversionRate = (
      (totalCustomGoals ? percentage(totalCustomGoals, totalUsers) : 0) / 100
    ).toFixed(4);
    const conversionRateAdditionalValue = (
      (totalUsers ? percentage(totalGoals2, totalUsers) : 0) / 100
    ).toFixed(4);

    values.push([
      prompt.name,
      totalUsers,
      totalImpressions,
      CTRValue,
      totalGoals,
      ...(hasCustomGoal ? [totalCustomGoals] : []),
      ...(hasCustomGoal ? [conversionRate] : [displayConversionRateDefault]),
      ...(!hasCustomGoal && accept2Enabled ? [conversionRateAdditionalValue] : []),
    ]);
  });

  saveToCsvFile([headers, ...values], `prompt_${prompts.length}_${period}`);
};

export const saveToCsvFile = (contents, fname) => {
  const csvContent = contents.join("\n");
  const link = window.document.createElement("a");
  link.setAttribute("href", `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
  const dateComponent = moment().format("MM_D_YYYY");
  link.setAttribute("download", `${dateComponent}_${fname}.csv`);
  link.click();
};

export const saveLinkToCsv = (url, fname) => {
  const link = window.document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${fname}.csv`);
  link.click();
};
