<template>
  <v-dialog
    :value="value"
    @input="v => (v ? show() : close())"
    data-cy="base-modal"
    :width="$attrs.width || 'auto'"
    v-bind="$attrs"
  >
    <v-card flat>
      <v-card-title class="headline">
        <slot v-if="$slots.title" name="title" />
        <template v-else> {{ title }} </template>
      </v-card-title>
      <v-card-text>
        <slot v-if="$slots.body || $scopedSlots.body" name="body" :opened="value" />
        <template v-else> {{ message }} </template>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <slot v-if="$slots.actions || $scopedSlots.actions" name="actions" />
        <template v-else-if="!noButtons">
          <v-btn
            large
            depressed
            data-cy="base-modal--close"
            class="px-7"
            color="#6a6a6a"
            outlined
            @click="close"
          >
            Close
          </v-btn>
          <RfButton
            v-if="$listeners.primary || $listeners.secondary"
            large
            depressed
            :disabled="disabledPrimary"
            data-cy="base-modal--submit"
            class="px-10"
            :color="$listeners.primary ? 'success' : 'primary'"
            @click="v => $emit($listeners.primary ? 'primary' : 'secondary', v)"
          >
            {{ primaryText || secondaryText || "Save" }}
          </RfButton>
          <v-btn
            v-if="$listeners.error"
            large
            depressed
            data-cy="base-modal--error"
            class="px-10"
            color="error"
            @click="v => $emit('error', v)"
          >
            {{ errorText || "Save" }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RfButton from "../buttons/RfButton.vue";

export default {
  name: "RfModalBase",
  components: { RfButton },
  props: {
    title: { type: String, default: null },
    message: { type: String, default: null },
    primaryText: { type: String, default: null },
    errorText: { type: String, default: null },
    secondaryText: { type: String, default: null },
    noButtons: { type: Boolean, default: false },
    initValue: { type: Boolean, default: false },
    disabledPrimary: { type: [Boolean, String], default: false },
  },
  data() {
    return { value: this.initValue };
  },
  methods: {
    show() {
      this.$emit("show");
      this.value = true;
    },
    close() {
      this.$emit("close");
      this.value = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  font-size: 1.2rem !important;
}
</style>
