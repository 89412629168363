<template>
  <tr class="rf-row" :class="{ 'after:!opacity-5': selected }">
    <td class="whitespace-nowrap pr-0" style="min-width: calc(24px + 1rem)">
      <RfCheckbox
        class="rf-row--checkbox"
        :class="{ 'rf-row--checkbox-col-selected': selected }"
        :value="selected"
        @input="$emit(selected ? 'deselect' : 'select', prompt.id)"
      />
    </td>
    <td class="whitespace-nowrap !px-5 5xl:!px-7">
      <span class="flex items-center !justify-center gap-0.5 5xl:!justify-start">
        <RfMenu
          preset="promptStatus"
          class="!justify-center 5xl:!justify-start"
          :disabled="
            disabled ||
            !!prompt.sequence?.id ||
            !!prompt.pipeline?.id ||
            getPromptStatus.type === SimplifyStatusesMapConstants.limited.type
          "
        >
          <template #activator>
            <span class="inline-flex items-center gap-2">
              <RfStatus data-cy="prompt-row--status" :status="getPromptStatus.type" />
              <span class="text-body hidden 5xl:inline-block">
                {{ StatusesConstants[getPromptStatus.type].title }}
              </span>
            </span>
          </template>
          <template #menu>
            <template v-for="status in StatusesSimplifiedConstants">
              <button
                v-if="
                  status.type === SimplifyStatusesMapConstants.ready.type
                    ? !prompt.is_enabled && dayjs(prompt.start_date).isAfter(dayjs())
                    : ![
                        SimplifyStatusesMapConstants[getPromptStatus.type].type,
                        SimplifyStatusesMapConstants.limited.type,
                      ].includes(status.type)
                "
                :key="status.type"
                class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4"
                @click="$emit('status', status.type)"
              >
                <RfStatus :status="status.type" />
                {{ status.action }}
              </button>
            </template>
          </template>
        </RfMenu>
      </span>
    </td>
    <td class="pl-0">
      <div class="flex items-center gap-5 5xl:gap-7">
        <span
          ref="imagePreviewRef"
          class="rf-row--thumbnail rf-thumbnail"
          :class="{ 'rf-thumbnail--preview': imagePreview }"
        >
          <div class="h-full w-full" :class="{ 'overflow-hidden rounded': !imagePreview }">
            <RfPromotionThumbnail :item="prompt" :key="prompt.id" />
            <button class="rf-thumbnail--overlay rounded" @click="clickPreview">
              <span class="rf-thumbnail--text">
                <RfEyeOpenIcon class="!h-6 !w-6 !fill-white" />
                Preview
              </span>
            </button>
          </div>
        </span>
        <span class="flex flex-col gap-1">
          <div class="flex items-start gap-2">
            <RouterLink
              class="text-action-buttons line-clamp-1 text-ellipsis text-left !font-bold !text-blue-1"
              :title="prompt.name"
              :to="getPromptLink(prompt.id, $route.params.aid)"
            >
              {{ prompt.name }}
            </RouterLink>
            <button v-if="!disabled" class="rf-row--edit-inline" @click="$emit('edit')">
              <RfEditIcon class="!h-5 !w-5" />
            </button>
          </div>
          <span class="text-body">{{ getPromptDeviceType(prompt, customDevicesEnabled) }}</span>
          <span v-if="prompt.sequence?.id || prompt.pipeline?.id" class="text-body">
            Inherited from:
            <RouterLink
              v-if="prompt.sequence?.id"
              :to="getGuideLink(prompt.sequence.id, $route.params.aid)"
              class="!text-blue-1"
              v-text="prompt.sequence.name"
            />
            <RouterLink
              v-if="prompt.pipeline?.id"
              :to="getPipelineLink(prompt.pipeline.id, $route.params.aid)"
              class="!text-blue-1"
              v-text="prompt.pipeline.name"
            />
          </span>
          <span class="text-body"
            >Creator:
            <!-- forcing <span></span> over <button></button> while filter by creator TBD -->
            <component
              :is="false && prompt.creator?.id ? 'button' : 'span'"
              class="!text-blue-1"
              @click=""
            >
              {{ prompt.creator?.name || "N/A" }}
            </component>
          </span>
        </span>
      </div>
    </td>

    <template v-if="!performanceCols">
      <td class="w-0">
        <div class="rf-row--segment-wrapper">
          <template v-if="prompt.segments.length">
            <RouterLink
              v-for="segment in [...prompt.segments].sort((a, b) =>
                `${a.name}`.localeCompare(`${b.name}`),
              )"
              :title="segment.name"
              class="!text-blue-1"
              :to="getSegmentLink(segment.id, $route.params.aid)"
            >
              {{ segment.name }}
            </RouterLink>
          </template>
          <span v-else>No segments</span>
        </div>
      </td>
      <td class="whitespace-nowrap px-4">
        <span class="text-body !text-black-2">
          {{ dayjs(prompt.start_date).utc().format("MM/DD/YY") }}
        </span>
      </td>
      <td class="whitespace-nowrap px-4">
        <span class="text-body relative !text-black-2">
          {{ dayjs(prompt.end_date).utc().format("MM/DD/YY") }}
          <RfPromptDateTag
            class="absolute -left-2 top-full mt-3"
            :date="new Date(prompt.end_date)"
            :isExpired="prompt.is_expired"
            :isLimited="getPromptStatus.type === StatusesSimplifiedConstants.limited.type"
          />
        </span>
      </td>
    </template>

    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!currUsers">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span v-else class="text-body text-decoration-none !text-black-2">{{
        toLocaleNumberString(currUsers)
      }}</span>
    </td>

    <template v-if="performanceCols">
      <td v-for="colVal in [currImpressions, currClicks]" class="rf-row--metric-col">
        <RfTooltip class="mr-0 w-fit" v-if="!colVal">
          <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
          <template #tooltip>
            <div class="w-64">{{ emptyDataText }}</div>
          </template>
        </RfTooltip>
        <span v-else class="text-body text-decoration-none !text-black-2">{{
          toLocaleNumberString(colVal)
        }}</span>
      </td>
      <td class="rf-row--metric-col">
        <RfTooltip class="mr-0 w-fit" v-if="!currCtr">
          <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
          <template #tooltip>
            <div class="w-64">{{ emptyDataText }}</div>
          </template>
        </RfTooltip>
        <span v-else class="text-body text-decoration-none !text-black-2">{{ currCtr }}</span>
      </td>
    </template>

    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!hasCustomGoalAndSinglePath || !currGoals">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span v-else class="text-body text-decoration-none !text-black-2">{{
        toLocaleNumberString(currGoals)
      }}</span>
    </td>

    <td class="rf-row--metric-col">
      <RfTooltip class="mr-0 w-fit" v-if="!currConversions">
        <template #activator> <RfHelpIcon class="!h-6 !w-6" /> </template>
        <template #tooltip>
          <div class="w-64">{{ emptyDataText }}</div>
        </template>
      </RfTooltip>
      <span v-else class="text-body text-decoration-none !text-black-2">{{ currConversions }}</span>
    </td>
    <td class="whitespace-nowrap px-2">
      <div class="flex items-center justify-center">
        <RfMenu hideIcon preset="promptMenu">
          <template #activator="{ opened }">
            <RfDotsIcon class="rf-row--menu" :class="{ 'rf-row--menu-opened': opened }" />
          </template>
          <template #menu>
            <button
              v-if="!disabled"
              class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4"
              @click="$emit('edit')"
            >
              <RfEditIcon class="!h-6 !w-6" />
              Edit
            </button>
            <button
              v-if="!prompt.sequence?.id && !disabled"
              class="flex w-full items-center gap-3 px-4 py-2"
              @click="$emit('clone')"
            >
              <RfCloneIcon class="!h-6 !w-6" />
              Clone
            </button>
            <RouterLink
              :to="`${getPromptLink(prompt.id, $route.params.aid)}/analysis`"
              class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4"
              style="color: inherit"
            >
              <RfDiagramsIcon class="!h-6 !w-6" />
              Performance
            </RouterLink>
            <button
              class="flex w-full items-center gap-3 px-4 py-2"
              @click="exportSinglePromptToCsv(prompt, timePeriod)"
            >
              <RfDownloadIcon class="!h-6 !w-6" />
              Export to CSV
            </button>
            <RouterLink
              :to="`${getPromptLink(prompt.id, $route.params.aid)}/experiments`"
              class="flex w-full items-center gap-3 px-4 py-2 last:!pb-4"
              style="color: inherit"
            >
              <RfExperimentIcon class="!h-6 !w-6" />
              Experiments
            </RouterLink>
            <button
              v-if="!disabled"
              class="flex w-full items-center gap-3 px-4 py-2 last:!pb-4"
              @click="$emit('delete')"
            >
              <RfDeleteIcon class="!h-6 !w-6" />
              Delete
            </button>
          </template>
        </RfMenu>
      </div>
    </td>
  </tr>
</template>

<script>
import RfCheckbox from "@/components/inputs/RfCheckbox.vue";
import RfStatus from "./RfStatus.vue";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import RfMenu from "@/components/menus/RfMenu.vue";
import StatusesConstants, {
  StatusesSimplifiedConstants,
  SimplifyStatusesMapConstants,
} from "@/utils/constants/PromptStatusesConstants";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfDotsIcon from "@/components/icons/RfDotsIcon.vue";
import { getSegmentLink, getPromptLink, getGuideLink, getPipelineLink } from "@/utils/getLink";
import { getPromptDeviceType } from "@/utils/getDeviceName";
import { tally, percentage } from "@/utils/metricsHelpers";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfDiagramsIcon from "@/components/icons/RfDiagramsIcon.vue";
import RfCloneIcon from "@/components/icons/RfCloneIcon.vue";
import RfHelpIcon from "@/components/icons/RfHelpIcon.vue";
import RfExperimentIcon from "@/components/icons/RfExperimentIcon.vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import RfPromptDateTag from "@/components/tags/RfPromptDateTag.vue";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfEyeOpenIcon from "@/components/icons/RfEyeOpenIcon.vue";
import dayjs from "dayjs";
import { exportSinglePromptToCsv } from "@/utils/CsvUtils";
import RfDownloadIcon from "@/components/icons/RfDownloadIcon.vue";
import { isInvisible, isLatestExperimentActive } from "@/utils/prompts/promptHelpers";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { usePreviewToggle } from "@/utils/composables/usePreviewToggle ";
import { computed, ref } from "vue";

const emptyDataText =
  "There is no data for this period of time. Try selecting a different time range or make sure the prompt has been active.";

export default {
  name: "RfPromptsRow",
  setup: ({ prompt }) => {
    const imagePreviewRef = ref(null);
    const imagePreview = ref(false);
    const clickPreview = usePreviewToggle(imagePreviewRef, imagePreview);

    return {
      StatusesSimplifiedConstants,
      SimplifyStatusesMapConstants,
      StatusesConstants,
      getSegmentLink,
      getPromptLink,
      getGuideLink,
      getPipelineLink,
      getPromptDeviceType,
      emptyDataText,
      dayjs,
      exportSinglePromptToCsv,
      toLocaleNumberString,
      imagePreview,
      imagePreviewRef,
      clickPreview,
    };
  },
  components: {
    RfCheckbox,
    RfStatus,
    RfMenu,
    RfPromotionThumbnail,
    RfDotsIcon,
    RfEditIcon,
    RfDiagramsIcon,
    RfCloneIcon,
    RfExperimentIcon,
    RfDeleteIcon,
    RfPromptDateTag,
    RfHelpIcon,
    RfTooltip,
    RfEyeOpenIcon,
    RfDownloadIcon,
  },
  props: {
    prompt: { type: Object, default: () => ({}) },
    selected: { type: Boolean, default: false },
    timezone: { type: String, default: null },
    timePeriod: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    performanceCols: { type: Boolean, default: false },
    customDevicesEnabled: { type: Boolean, default: false },
  },
  computed: {
    getPromptStatus() {
      return getPromptStatus(this.prompt, this.timezone);
    },
    promptCustomFiltersKeys() {
      return Object.keys(this.prompt?.custom_filter || {});
    },
    hasCustomGoalAndSinglePath() {
      return this.promptCustomFiltersKeys.length > 0;
    },
    currUsers() {
      return tally(
        this.prompt.data,
        this.timePeriod,
        isInvisible(this.prompt) ? "users" : "uimpressions",
      );
    },
    currImpressions() {
      return tally(this.prompt.data, this.timePeriod, "impressions");
    },
    currCtr() {
      if (!this.currImpressions) return 0;

      return percentage(this.currClicks, this.currImpressions, true);
    },
    currClicks() {
      return tally(this.prompt.data, this.timePeriod, "goals");
    },
    currGoals() {
      return tally(this.prompt.data, this.timePeriod, "custom_goals_seen");
    },
    currConversions() {
      if (!this.currUsers) return 0;
      return percentage(
        this.hasCustomGoalAndSinglePath ? this.currGoals : this.currClicks,
        this.currUsers,
        true,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rf-row {
  @apply relative;

  &--metric-col {
    @apply text-body whitespace-nowrap bg-goal px-4 text-right no-underline;
    @apply text-black-2 #{!important};
  }

  &--segment-wrapper {
    @apply text-body flex w-24 flex-col break-keep 4xl:w-40;
    @apply ml-0 #{!important};

    > * {
      @apply truncate break-keep;
    }
  }

  &--edit-inline {
    @apply flex items-center gap-3 opacity-0;
  }

  &::after {
    @apply pointer-events-none absolute inset-0 bg-blue-1 opacity-0 transition-opacity duration-300 ease-in-out;
    content: "";
  }

  &--checkbox:not(.rf-row--checkbox-col-selected),
  &--menu:not(.rf-row--menu-opened) {
    opacity: 0;
  }

  &--checkbox,
  &--edit-inline,
  &--menu {
    transition: opacity 0.3s ease-out;
  }

  &--menu {
    @apply m-auto flex;
    @apply h-6 w-6 #{!important};
  }

  &:hover {
    &::after {
      @apply opacity-5;
    }

    .rf-row--checkbox {
      opacity: 1;
    }

    .rf-row--menu {
      opacity: 1;
    }

    .rf-row--edit-inline {
      opacity: 1;
    }
  }

  &--thumbnail {
    --duration-z-index: 350ms;
    @apply relative block h-24 w-32 flex-shrink-0;
    transform-origin: center top;
    transition:
      transform 300ms cubic-bezier(0.5, 1.2, 0.5, 1.2),
      z-index var(--duration-z-index) linear;
    z-index: 1;

    &.rf-thumbnail--preview {
      --duration-z-index: 0ms;
      transform: scale(2);
      z-index: 2;
    }

    .promo-preview-image {
      min-width: auto;
      height: 100%;

      img {
        @apply h-full w-full object-cover;
      }
    }

    &:not(.rf-thumbnail--preview) {
      .rf-thumbnail--overlay:hover {
        background: rgb(36, 36, 36, 0.6);
      }

      .rf-thumbnail--text {
        @apply inline-flex;
      }
    }

    .rf-thumbnail--overlay {
      @apply text-body absolute inset-0 flex opacity-0 hover:opacity-100;
      @apply text-white #{!important};
      transition: opacity 300ms ease-in-out;
    }

    .rf-thumbnail--text {
      @apply m-auto hidden items-center gap-1;
    }
  }
}
</style>
