<template>
  <div class="promo-tab-history">
    <RfUserHistory :activities="activities" />
    <RfConnectorErrors />
  </div>
</template>

<script>
import { mapState } from "vuex";
import RfUserHistory from "@/components/RfUserHistory.vue";
import RfConnectorErrors from "@/components/RfConnectorErrors.vue";

export default {
  name: "RfRetentionHistory",
  components: { RfConnectorErrors, RfUserHistory },
  computed: { ...mapState({ activities: state => [...(state?.apps?.currPathActivities || [])] }) },
};
</script>
