import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";
import { PATH_CUSTOM_METRIC_PIECHART } from "@/utils/constants/CustomMetricsConstants";

// const SEGMENT_CUSTOM_METRIC_TYPES = [];

export default class ApiMetrics {
  static getOneSegment(
    appId,
    segmentId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/${segmentId}/metrics${query}`);
  }

  // static getOneSegmentCustom(
  //   appId,
  //   segmentId,
  //   params = {
  //     metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
  //   },
  // ) {
  //   params.metric_keys = SEGMENT_CUSTOM_METRIC_TYPES;
  //   let query = serializeQuery(params);
  //   if (query) query = `?${query}`;
  //   return authenticatedFetch(
  //     `${ApiBaseUrl}apps/${appId}/segments/${segmentId}/custom_metrics${query}`,
  //   );
  // }
  static getAppDashboard(
    appId,
    params = {
      metric_periods: [{ period: "this_week" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/dashboard_metrics${query}`);
  }

  static getSegments(
    appId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/all_metrics${query}`);
  }

  static getOnePath(
    appId,
    pathId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/metrics${query}`);
  }

  static getOnePathCustom(
    appId,
    pathId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    params.metric_keys ??= [PATH_CUSTOM_METRIC_PIECHART];
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/custom_metrics${query}`);
  }

  static getOnePathCohort(
    appId,
    pathId,
    params = {
      activity: "eligible",
      days: 30,
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/paths/${pathId}/cohort_metrics${query}`);
  }

  static getRetentions(
    appId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    params.path_type =
      params.path_type ??
      "retention_modal,video,horizontal,vertical,tile,text,invisible,widget,interstitial,email,bottom_banner";
    const query = serializeQuery(params);
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/all_metrics${query ? `?${query}` : ``}`,
    );
  }

  static getPathExperiments(
    appId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `&${query}`;
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/experiments/all_metrics?path_type=custom${query}`,
    );
  }

  static getRetentionExperiments(
    appId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
      path_type:
        "retention_modal,video,horizontal,vertical,tile,text,invisible,widget,interstitial,email,bottom_banner",
    },
  ) {
    const query = serializeQuery(params);
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/experiments/all_metrics${query ? `?${query}` : query}`,
    );
  }

  static getOnePathActionGroups(
    appId,
    pathId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/paths/${pathId}/action_group_metrics${query}`,
    );
  }

  static getOnePipeline(
    appId,
    pipelineId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/${pipelineId}/metrics${query}`);
  }

  static getExperimentCustomMetrics(
    pathId,
    experimentId,
    params = {
      metric_periods: [{ period: "last_seven_days" }],
    },
  ) {
    params.metric_keys ??= [PATH_CUSTOM_METRIC_PIECHART];
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(
      `${ApiBaseUrl}paths/${pathId}/experiments/${experimentId}/custom_metrics${query}`,
    );
  }

  static getSequences(
    appId,
    params = {
      metric_periods: [{ period: "last_seven_to_fourteen_days" }, { period: "last_seven_days" }],
    },
  ) {
    const query = serializeQuery(params);
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/sequences/all_metrics?${query ? `${query}` : ""}`,
    );
  }
}
