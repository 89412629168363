<template>
  <div>
    <RfPromoDetailsInfoRow title="Custom Goal">
      <template v-slot:promodetail>
        <span v-if="customGoal"
          >{{ goalsName }};<span v-if="duration">{{ duration }} hours</span></span
        >
        <span v-else>-</span>
        <div class="pd-info-edit">
          <RfButton
            icon-size="16"
            title="edit"
            icon="edit"
            color="accent"
            x-small
            :disabled="editDisabled"
            @click="showPromoDetailDialog"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="500"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Add a custom conversion goal</v-card-title>
        <v-card-text>
          <RfGoal :model="path" ref="goal" />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn
            large
            @click="submitConfiguration"
            width="200px"
            color="primary"
            depressed
            :disabled="editDisabled"
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfGoal from "@/components/RfPathCreate/RfGoal.vue";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfPromoGoalsInfo",
  props: ["path", "editDisabled"],
  mixins: [CustomFieldsMixin],
  setup: () => ({ toastsStore: useToastsStore() }),
  data: () => ({ showPromotionInfoDialog: false }),
  components: { RfPromoDetailsInfoRow, RfGoal, RfButton },
  computed: {
    ...mapState({ app: state => state.apps.currApp }),
    customGoal() {
      return Object.keys(this.path.custom_filter)[0];
    },
    goalsName() {
      return this.appCustomFields?.find(el => el.label === this.customGoal)?.name || "Custom Goal";
    },
    duration() {
      return this.path.actions.rf_settings_custom_goal_expire_hours;
    },
  },
  methods: {
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    async submitConfiguration() {
      try {
        const newRetention = cloneDeep(this.path);
        if (this.$refs.goal) {
          this.$refs.goal.fillModel(newRetention);
        }
        await this.updatePath({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
          modelPath: newRetention,
        });
        this.showPromotionInfoDialog = false;
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath"]),
  },
};
</script>
