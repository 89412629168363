<template>
  <div class="list-promo-stats">
    <div class="list-promo-stats-ctr">
      <div class="list-promo-charts-tabs">
        <div>
          <button
            v-if="!isInvisible"
            :class="{ active: activetab === 'impressions' }"
            @click.stop="showChartType('impressions')"
          >
            Impressions
          </button>
          <button :class="{ active: activetab === 'users' }" @click.stop="showChartType('users')">
            Users
          </button>
        </div>
      </div>
      <div>
        <div class="all-segment-stat promotion-stat">
          <div class="all-segment-stat-single promotion-impr-num" v-if="!isInvisible">
            <span class="stat-label stat-label-impressions">
              <span class="sml-box"></span>
              <span v-if="impressionsActive">Impressions</span>
              <span v-else>Users</span>
            </span>
            <span class="stat-value">{{ toLocaleNumberString(totalDenominator) }} </span>
          </div>
          <div class="all-segment-stat-single promotion-clicks-num">
            <span class="stat-label stat-label-clicks">
              <span class="sml-box"></span>
              <span v-if="isInvisible">Users</span>
              <span v-else>Clicks</span>
            </span>
            <span class="stat-value">{{ toLocaleNumberString(totalGoals) }}</span>
          </div>
          <div class="all-segment-stat-single promotion-ctr-num" v-if="!isInvisible">
            <span class="stat-label">{{ conversionType }}</span>
            <span class="stat-value">
              {{ percentage(totalGoals, totalDenominator, true) }}
            </span>
          </div>
        </div>
        <GChart
          v-if="buildChartData"
          :data="buildChartData"
          :options="buildChartOptions"
          :create-chart="createColumnChart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MetricsMixin from "@/utils/MetricsMixin";
import { tally, percentage, statsAggregator } from "@/utils/metricsHelpers";
import { isInvisible } from "@/utils/prompts/promptHelpers";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { createColumnChart } from "@/utils/vueGoogleChartUtils";

export default {
  name: "RfSinglePromoStats",
  props: ["model", "metricsKey", "singleDayCustom"],
  data: () => ({ activetab: "impressions" }),
  mixins: [MetricsMixin],
  setup: () => ({ createColumnChart, percentage, toLocaleNumberString }),
  computed: {
    totalGoals() {
      return this.getTotal("goals");
    },
    totalDenominator() {
      return this.getTotal(this.denominator);
    },
    data() {
      return Array.isArray(this.model)
        ? statsAggregator(this.model, this.metricsKey)
        : this.model.data;
    },
    impressionsActive() {
      return this.activetab === "impressions";
    },
    hasMetric() {
      const foundMetric = Object.entries(this.data).find(([_, v]) => v[this.metricsKey]);
      return !!foundMetric && this.data;
    },
    chartColors() {
      return this.isInvisible ? ["#336699"] : ["#70B9F7", "#336699", "#CCCCCC"];
    },
    buildChartOptions() {
      let maxWindowVal = 100;
      if (this.hasMetric) {
        const currentMetricValues = Object.entries(this.data).reduce((total, [_, v]) => {
          const vals = (v[this.metricsKey].data || []).map(item => item.count);
          return total.concat(vals);
        }, []);
        const chartCeilingRounder = 10;
        const value = Math.max(...currentMetricValues, chartCeilingRounder);
        maxWindowVal = Math.ceil(value / chartCeilingRounder) * chartCeilingRounder;
      }

      const opts = {
        height: 220,
        width: "100%",
        animation: { duration: 1000, easing: "inAndOut", startup: true },
        tooltip: { isHtml: true, textStyle: { bold: false, fontSize: 13 } },
        backgroundColor: { fill: "transparent" },
        legend: { position: "none" },
        colors: this.chartColors,
        vAxis: {
          viewWindow: { min: 0, max: maxWindowVal },
          baselineColor: "#cccccc",
          gridlines: { color: "#eee", count: 3 },
          minorGridlines: { color: "#eeeeee", count: 0 },
        },
        bar: { groupWidth: "90%" },
        seriesType: "bars",
        series: {},
        chartArea: { left: 70, top: 25, right: 10, width: "85%", height: "80%" },
        enableInteractivity: false,
      };

      if (["this_month", "today", "yesterday"].includes(this.metricsKey) || this.singleDayCustom) {
        opts.hAxis = { maxAlternation: 1, showTextEvery: 5 };
      }

      return opts;
    },
    denominator() {
      if (this.isInvisible) return "goals";
      return this.impressionsActive ? "impressions" : "uimpressions";
    },
    hasSecondaryAccept() {
      return !!this.data.goals2;
    },
    conversionType() {
      return this.impressionsActive ? "CTR" : "Conversion";
    },
    buildChartData() {
      if (this.hasMetric) {
        const dataSlct = [];
        if (this.activetab === "impressions" && this.data.impressions) {
          dataSlct.push(this.data.impressions);
        } else if (this.data.uimpressions && !this.isInvisible) {
          dataSlct.push(this.data.uimpressions);
        }
        if (this.data.goals) {
          dataSlct.push(this.data.goals);
        }
        if (this.hasSecondaryAccept && this.data.goals2) {
          dataSlct.push(this.data.goals2);
        }

        const currentMetricValues = Object.entries(dataSlct).reduce((total, [_, v]) => {
          const vals = (v[this.metricsKey].data || []).map(item => item.count);
          return total.concat(vals);
        }, []);
        if (currentMetricValues.length) {
          const chartCeilingRounder = 10;
          const value = Math.max(...currentMetricValues, chartCeilingRounder);
          const maxWindowVal = Math.ceil(value / chartCeilingRounder) * chartCeilingRounder;
          const labelColumn = 1;
          const columns = Object.keys(dataSlct).length + labelColumn;
          const header = new Array(columns).fill("");
          const rows = Object.entries(dataSlct).map(([_, v]) => {
            return v[this.metricsKey].data;
          });

          const transposeRows = rows[0].map((col, i) => {
            return rows.map(row => {
              let colVal = row[i].count;

              if (colVal === 0) {
                colVal = maxWindowVal / 40;
              } else if (colVal === null) {
                colVal = 0;
              }
              return { ...row[i], count: colVal };
            });
          });
          const datedRows = transposeRows.map(row => {
            const dataItem = row[0];
            const tz_offset = 0;
            const symbol = tz_offset < 0 ? "-" : "+";
            let paddedTz = tz_offset.toString().padStart(2, "0");
            paddedTz = `${symbol}${paddedTz}00`;
            const text = this.$moment.utc(`${dataItem.date}${paddedTz}`, "YYYYMMDDHHmmZZ");

            const rowItems = row.map(item => item.count);
            if (
              this.metricsKey === "today" ||
              this.metricsKey === "yesterday" ||
              this.singleDayCustom
            ) {
              return [text.format("hA"), ...rowItems];
            }

            return [text.format("MM/DD"), ...rowItems];
          });
          return [header, ...datedRows];
        }
      }
      return [
        ["", ""],
        ["N/A", 0],
        ["N/A", 0],
        ["N/A", 0],
        ["N/A", 0],
        ["N/A", 0],
        ["N/A", 0],
        ["N/A", 0],
      ];
    },
    isInvisible() {
      return isInvisible({ path_type: this.pathType });
    },
    pathType() {
      return Array.isArray(this.model)
        ? this.model[0] && this.model[0].path_type
        : this.model.path_type;
    },
  },
  methods: {
    showChartType(type) {
      event.preventDefault();
      this.activetab = type;
    },
    getTotal(statType) {
      return tally(this.data, this.metricsKey, statType);
    },
  },
  mounted() {
    if (this.isInvisible) this.activetab = "users";
  },
};
</script>
