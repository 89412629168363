<template>
  <div>
    <div class="modal-privacy-tos" v-if="hasLinks" v-html="links" :style="containerStyle"></div>
    <div
      class="modal-terms-long-type modal-privacy-tos"
      v-else
      v-html="actions.rf_settings_privacy_policy_text"
      :style="containerStyle"
    ></div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionLinksPreview",
  props: ["actions"],
  methods: {
    createLink(href, text) {
      const fontColor = this.actions.rf_settings_fill_color;
      return `<a href="${href}" style="color:${fontColor};text-decoration:underline" target="_BLANK">${text}</a>`;
    },
  },
  data() {
    return {};
  },
  computed: {
    containerStyle() {
      return { color: this.actions.rf_settings_fill_color };
    },
    hasLinks() {
      return (
        this.actions.rf_settings_privacy_policy_type === "link" &&
        (this.actions.rf_settings_privacy_policy_link || this.actions.rf_settings_tos_link)
      );
    },
    links() {
      if (this.hasLinks) {
        const pp = this.actions.rf_settings_privacy_policy_link;
        const tos = this.actions.rf_settings_tos_link;
        const ppLink = this.createLink(pp, "privacy policy");
        const tosLink = this.createLink(tos, "terms of service");
        const prefix = "By accepting, you agree to our";
        if (pp && tos) {
          return `<span>${prefix} ${ppLink} and ${tosLink}</span>`;
        }
        if (pp) {
          return `<span>${prefix} ${ppLink}</span>`;
        }
        if (tos) {
          return `<span>${prefix} ${tosLink}</span>`;
        }
      }
    },
  },
};
</script>
