<template>
  <div class="promo-preview-image" :class="previewClass[item.path_type]" :id="elementId">
    <div v-if="skipSnappyImage">
      <img :src="item.actions.rf_settings_email_preview_composite" loading="lazy" />
    </div>
    <div
      v-else-if="
        ![PromptTypes.invisible.value, PromptTypes.scheduled_push.value].includes(item.path_type)
      "
    >
      <v-icon size="24" color="white" v-if="isLoading">fas fa-spinner fa-spin</v-icon>
      <img :src="snappyImage" v-if="promoCreativeRefreshUrl" loading="lazy" />
      <img :src="backgroundImage" loading="lazy" v-else />
    </div>
  </div>
</template>

<script>
import { previewClass } from "@/utils/constants/PreviewClassConstants";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";
import { isEmail } from "@/utils/prompts/promptHelpers";
import { mapState, mapMutations } from "vuex";

export default {
  name: "RfPromotionThumbnail",
  setup: () => ({ previewClass, PromptTypes }),
  props: ["item", "imagewiz"],
  data() {
    return {
      timerId: null,
      promoCreativeRefreshUrl: null,
      lastModified: null,
      elementId: `el-${this.item.id}`,
      isImagePollerRunning: false,
      thumbSizes: {
        subnav: { width: 50, height: 33 },
        pipelines: { width: 70, height: 44 },
      },
    };
  },
  computed: {
    ...mapState({
      currPath: state => state.apps.currPath,
      pathsLoading: state => state.apps.pathsLoading,
      actionGroupsLoading: state => state.apps.actionGroupsLoading,
      isSafari: state => state.tmp.isSafari,
    }),
    isExperiment() {
      return this.item.actionable_type === "Experiment";
    },
    isLoading() {
      if (this.isExperiment) {
        return this.currPath && this.actionGroupsLoading[this.item.id];
      }
      return this.item && this.pathsLoading[this.item.id];
    },
    actionPreviewKey() {
      const { item } = this;
      let deviceType = item.device_type;
      if (!deviceType && this.currPath) {
        deviceType = this.currPath.device_type;
      }

      const values = {
        ios: "rf_settings_bg_image_ios_ipad_preview_composite",
        tv_os: "rf_settings_bg_image_tv_os_tv_preview_composite",
        android_os: "rf_settings_bg_image_android_os_tablet_preview_composite",
        roku_os: "rf_settings_bg_image_roku_os_tv_preview_composite",
        web: "rf_settings_bg_image_web_desktop_preview_composite",
        custom_defined: "rf_settings_bg_image_custom_defined_desktop_preview_composite",
      };
      return values[deviceType];
    },
    renderPromoCreativeImg() {
      if (this.skipSnappyImage) return null;
      if (this.promoCreativeRefreshUrl) return this.promoCreativeRefreshUrl;
      const url = this.item.actions[this.actionPreviewKey];
      if (!(url && url.length)) return null;
      if (this.isSafari) {
        const startOfDayTs = +new Date();
        return `${url}&r1=${startOfDayTs}`;
      }
      return url;
    },
    backgroundImage() {
      let { actions } = this.item;
      if (this.item.filter && this.item.filter.is_translated && this.item.parsed_translations) {
        Object.keys(this.item.parsed_translations).forEach(language => {
          const values = this.item.parsed_translations[language];
          if (values.default) {
            actions = { ...actions, ...values };
          }
        });
      }
      return this.imagewizThumbUrl(actions ? actions.rf_settings_bg_image : "");
    },
    snappyImage() {
      return this.imagewizThumbUrl(this.promoCreativeRefreshUrl);
    },
    skipSnappyImage() {
      return isEmail(this.item);
    },
  },
  methods: {
    ...mapMutations([
      "mutateReplacePathImage",
      "mutateUpdatePathImageLoad",
      "mutateReplaceActionGroupImage",
      "mutateUpdateActionGroupImageLoad",
    ]),
    clearTimer() {
      if (this.timerId) clearInterval(this.timerId);
    },
    isElementVisible(el) {
      const elemTop = el.getBoundingClientRect().top;
      const elemBottom = el.getBoundingClientRect().bottom;

      const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
      return isVisible;
    },
    triggerImagePoller() {
      if (this.isImagePollerRunning) return;

      const el = document.querySelector(`#${this.elementId}`);
      if (el && !this.lastModified && this.isElementVisible(el)) {
        this.isImagePollerRunning = true;
        this.enableImagePoller();
      }
    },
    imagewizThumbUrl(url) {
      if (!this.imagewiz) return url;

      const { width, height } = this.thumbSizes[this.imagewiz];

      try {
        const thumb = new URL(url);

        thumb.searchParams.append("screen_size", "thumb");
        thumb.searchParams.append("width", width);
        thumb.searchParams.append("height", height);

        return thumb.toString();
      } catch (e) {
        return url;
      }
    },
    async enableImagePoller() {
      if (!this.renderPromoCreativeImg) return;

      try {
        const originalRes = await fetch(this.renderPromoCreativeImg);

        if (originalRes.status !== 200) {
          this.lastModified = -1;
        } else {
          this.lastModified = originalRes.headers.get("last-modified");
          this.promoCreativeRefreshUrl = this.renderPromoCreativeImg;
        }

        const alreadyLoaded = new Date(this.lastModified).getTime() > this.isLoading;

        if (!this.isLoading) return;

        let counter = 0;

        this.timerId = setInterval(async () => {
          if (counter > 45 || alreadyLoaded) {
            counter = 0;
            clearInterval(this.timerId);
            if (this.isExperiment) {
              this.mutateUpdateActionGroupImageLoad({
                actionGroupId: this.item.id,
                isLoading: false,
              });
            } else if (this.currPath) {
              this.mutateUpdatePathImageLoad({
                pathId: this.currPath.id,
                isLoading: false,
              });
            }

            this.timerId = null;
          } else {
            const promoCreativeRefreshUrl = `${this.renderPromoCreativeImg}&r2=${parseInt(
              new Date() / 1000,
            )}`;
            const res = await fetch(promoCreativeRefreshUrl);
            const value = res.headers.get("last-modified");
            if (res.status !== 200) {
              this.lastModified = -1;
            } else if (!value) {
              this.promoCreativeRefreshUrl = null;
            } else if (!this.lastModified) {
              this.lastModified = value;
              if (!this.promoCreativeRefreshUrl)
                this.promoCreativeRefreshUrl = promoCreativeRefreshUrl;
            } else if (this.lastModified !== value) {
              counter = 0;
              clearInterval(this.timerId);
              this.promoCreativeRefreshUrl = promoCreativeRefreshUrl;

              if (this.isExperiment) {
                this.mutateReplaceActionGroupImage({
                  actionGroupId: this.item.id,
                  previewKey: this.actionPreviewKey,
                  previewUrl: promoCreativeRefreshUrl,
                });
                this.mutateUpdateActionGroupImageLoad({
                  actionGroupId: this.item.id,
                  isLoading: false,
                });
              } else {
                this.mutateReplacePathImage({
                  pathId: this.currPath.id,
                  pathType: this.currPath.path_type,
                  previewKey: this.actionPreviewKey,
                  previewUrl: promoCreativeRefreshUrl,
                });
                this.mutateUpdatePathImageLoad({
                  pathId: this.currPath.id,
                  isLoading: false,
                });
              }
              this.timerId = null;
            }
            counter += 1;
          }
        }, 3000);
      } catch (error) {}
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
  watch: {
    isLoading(loading) {
      if (loading) {
        this.clearTimer();
        this.enableImagePoller();
      }
    },
  },
  mounted() {
    this.triggerImagePoller();
  },
  created() {
    document.addEventListener("scroll", this.triggerImagePoller);
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.triggerImagePoller);
  },
};
</script>

<style lang="scss" scoped>
.promo-preview-image {
  position: relative;
  line-height: normal;
  i {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
