<template>
  <div>
    <RfPromoDetailsInfoRow title="Metadata">
      <template v-slot:promodetail>
        <div>
          <div>{{ metaString }}</div>
          <div class="pd-info-edit edit-meta">
            <RfButton
              icon-size="16"
              title="edit"
              icon="edit"
              color="accent"
              x-small
              :disabled="editDisabled"
              @click="showPromoDetailDialog"
            />
          </div>
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="600"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Set Metadata</v-card-title>
        <v-card-text>
          <div class="metadata-div-header">
            <div @click="addField" class="add-metadata-btn">
              <v-icon color="primary" size="16" left>add</v-icon>Add metadata
            </div>
          </div>
          <div class="device-metadata" v-for="(item, index) in metadata">
            <v-text-field
              outlined
              dense
              hide-details
              label="Key"
              v-model.trim="item.key"
              name="device_key"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              hide-details
              label="Value"
              v-model.trim="item.value"
              name="device_value"
            ></v-text-field>
            <v-icon size="18" @click="onRemove(index)">clear</v-icon>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn
            large
            @click="submitConfiguration"
            width="200px"
            color="primary"
            depressed
            :disabled="editDisabled"
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfPromoInvisibleMeta",
  setup: () => ({ toastsStore: useToastsStore() }),
  props: ["app", "actions", "model", "editDisabled"],
  components: { RfPromoDetailsInfoRow, RfButton },
  data: () => ({ metadata: [], showPromotionInfoDialog: false }),
  computed: {
    metaString() {
      if (!this.metadata || !this.metadata.length) return "N/A";
      return this.metadata.map(({ key, value }) => `${key}: ${value}`).join("; ");
    },
  },
  watch: {
    metadata: {
      handler(to) {
        const results = to
          .filter(item => !!item.key)
          .reduce((hash, { key, value }) => {
            hash[key] = value;
            return hash;
          }, {});
        const stringified = JSON.stringify(results);
        this.actions.rf_metadata = stringified;
      },
      deep: true,
    },
    actions(to) {
      this.metadata = this.getMetadata(to);
    },
  },
  methods: {
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    addField() {
      this.metadata.push({
        key: null,
        value: null,
      });
    },
    onRemove(index) {
      this.metadata.splice(index, 1);
    },
    getMetadata(actions) {
      let values = {};
      if (actions.rf_metadata && actions.rf_metadata.length) {
        values = JSON.parse(actions.rf_metadata);
      }

      const res = Object.entries(values).reduce(
        (arr, [key, value]) => [...arr, { key, value }],
        [],
      );
      return res;
    },
    async submitConfiguration() {
      try {
        const newPath = cloneDeep(this.model);
        console.log(this.actions.rf_metadata);
        newPath.actions.rf_metadata = this.actions.rf_metadata;

        await this.updatePath({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
          modelPath: newPath,
        });
        this.showPromotionInfoDialog = false;
        this.toastsStore.create({ type: "success", body: "Metadata updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath"]),
  },

  mounted() {
    this.metadata = this.getMetadata(this.actions);
  },
};
</script>
<style lang="scss" scoped>
.add-metadata-btn {
  display: inline-block;
  padding: 0 10px 5px;
  color: #3096ed;
  font-weight: bold;
  cursor: pointer;
}
.metadata-div-header {
  border-bottom: 1px solid #ccc;
  text-align: right;
  margin-bottom: 10px;
}
.device-metadata {
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  > div.v-input {
    flex: 1;
    padding-right: 16px;
  }
  > div.v-input:last-child {
    flex: 1;
    padding-right: 0px;
  }
}
</style>
