<template>
  <div class="tile-preview-container">
    <div class="tile-promo-display-selections-div">
      <div class="tile-promo-display-selections-opt" v-if="model.device_type === 'web'">
        <a
          @click="$emit('clickTab', 'desktopViewTab')"
          v-bind:class="[currActiveTab === 'desktopViewTab' ? 'active' : '']"
          >Desktop</a
        >
        <a
          @click="$emit('clickTab', 'mobilewebViewTab')"
          v-bind:class="[currActiveTab === 'mobilewebViewTab' ? 'active' : '']"
          >Mobile</a
        >
      </div>
    </div>
    <RfLanguagePreview :model="model" :actions="actions" />
    <div
      v-if="currActiveTab === 'desktopViewTab'"
      :class="desktopContClassName"
      :style="tileStyles"
    >
      <span class="rfmodal-close" v-if="closeButtonEnabled">
        <v-icon :style="{ color: fillColor }">close</v-icon>
      </span>
      <div :class="backgroundContentAlignmentClass">
        <div
          class="promo-tile-wrapper-container"
          :class="messageAlignClass"
          :style="headerWrapperStyle"
        >
          <div class="tile-header-msg-wrp">
            <div
              class="promo-tile-wrapper-header"
              :style="titleStyle"
              v-html="previewActions.rf_retention_title"
            ></div>
            <div class="promo-tile-wrapper-body">
              <RfBodyCountdown :actions="actions" />
              <div :style="messageStyle" v-html="previewActions.rf_retention_message"></div>
            </div>
            <div class="promo-tile-wrapper-footer" :style="buttonPositioning">
              <div
                class="promo-tile-wrapper-btn"
                :class="buttonFullWidth"
                v-if="previewActions.rf_settings_tile_interaction === 'button'"
              >
                <RfRetentionTileButtons :actions="actions" />
              </div>
            </div>
          </div>
        </div>
        <div :style="columnspacerWidth" class="promo-colm-spacer"></div>
      </div>
      <div ref="desktop" :style="containerBackgroundImage" :class="containerBackgroundClass"></div>
    </div>
    <div :class="containerClassName" v-if="currActiveTab === 'mobilewebViewTab'">
      <div class="rtile-mweb-content-wrapper">
        <div class="rtile-mweb-content" :style="cssVarsMobileWebSize">
          <div class="rtile-mweb-content-msg" :style="mobileHeaderWrapperStyle">
            <div
              class="promo-tile-wrapper-header"
              :style="mobileWebTitleFontSize"
              v-html="previewActions.rf_mobile_title"
            ></div>
            <div class="promo-tile-wrapper-body">
              <RfBodyCountdown :actions="actions" />
              <div :style="mobileWebBodyFontSize" v-html="previewActions.rf_mobile_message"></div>
            </div>
            <div class="rtile-mweb-content-footer" :style="buttonPositioning">
              <div
                class="promo-tile-wrapper-btn"
                :class="buttonFullWidth"
                v-if="previewActions.rf_settings_tile_interaction === 'button'"
              >
                <RfRetentionTileButtons :actions="actions" />
              </div>
            </div>
          </div>
          <div
            :style="containerMobileWebBackgroundImage"
            :class="containerMobileWebBackgroundClass"
            ref="mobile"
          ></div>
        </div>
      </div>
    </div>
    <div class="preview-control-buttons" v-if="currActiveTab === 'desktopViewTab'">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="previewActions.rf_settings_custom_css">{{
      previewActions.rf_settings_custom_css
    }}</component>
  </div>
</template>

<script>
import InlineMixin from "@/utils/InlineMixin";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import RfRetentionTileButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionTileButtons.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import RfBodyCountdown from "./RfBodyCountdown.vue";

export default {
  name: "RfRetentionTile",
  mixins: [InlineMixin, ZoomPreviewMixin, LanguagePreviewMixin],
  components: { RfRetentionTileButtons, RfLanguagePreview, RfBodyCountdown },
  props: ["actions", "viewTypeTab", "model", "currPathGroup"],
  computed: {
    currActiveTab() {
      return this.viewTypeTab;
    },
    tileStyles() {
      return {
        "width": this.previewActions.rf_settings_tile_width,
        "height": this.previewActions.rf_settings_tile_height,
        [this.isClickDisabled ? "pointer-events" : null]: "none",
        "margin-top": "60px",
        ...this.zoomStyle,
      };
    },
    isClickDisabled() {
      return ["true", true].indexOf(this.previewActions.rf_settings_disable_click) > -1;
    },
    buttonPositioning() {
      if (this.previewActions.rf_settings_bar_position) {
        if (this.previewActions.rf_settings_bar_position === "bottom") {
          return {
            position: "absolute",
            bottom: "0",
          };
        }
        return { position: "relative" };
      }
    },
    buttonFullWidth() {
      if (this.previewActions.rf_settings_text_container_max_width === "full") {
        return "full-width-btn";
      }
      return "";
    },

    messageAlignClass() {
      if (this.previewActions.rf_retention_align) {
        return `${this.previewActions.rf_retention_align}-align-mode`;
      }
    },
    headerWrapperStyle() {
      return {
        width: this.previewActions.rf_settings_text_container_max_width,
      };
    },
    mobileHeaderWrapperStyle() {
      return {
        "padding-top": this.previewActions.rf_settings_mobile_title_padding_top,
        "width": "100%",
        "max-width": "100%",
      };
    },
    cssVarsMobileWebSize() {
      const data = {
        "--font-size": this.previewActions.rf_settings_mobile_message_font_size,
        "--txt-color": this.fillColor,
        "width": this.previewActions.rf_mobile_width,
        "height": this.previewActions.rf_mobile_height,
      };
      return data;
    },
    messageStyle() {
      return {
        "font-size": this.previewActions.rf_settings_message_font_size,
        "color": this.fillColor,
      };
    },
    fillColor() {
      return this.previewActions.rf_settings_fill_color;
    },
    titleStyle() {
      return {
        "font-size": this.previewActions.rf_settings_title_font_size,
        "color": this.fillColor,
        "padding-top": this.previewActions.rf_settings_title_padding_top,
      };
    },
    containerBackgroundClass() {
      return `promo-tile-backgroundimage ${this.previewActions.rf_settings_tile_is_responsive}-mode`;
    },
    containerBackgroundImage() {
      const styles = {
        "background-image": "url(https://assets.redfastlabs.com/images/inline-bg-default.jpg)",
        "background-color": this.previewActions.rf_settings_bg_image_color,
      };
      if (this.previewActions.rf_settings_bg_image) {
        styles["background-image"] = `url(${this.previewActions.rf_settings_bg_image})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    closeButtonEnabled() {
      return this.previewActions.rf_settings_close_button_enabled === "true" && !this.isSticky;
    },
    isSticky() {
      return (
        this.previewActions.rf_settings_tile_interaction === "container" &&
        this.previewActions.rf_settings_permanent_display === "true"
      );
    },
    containerMobileWebBackgroundClass() {
      return `rfmodal-backgroundimage ${this.previewActions.rf_settings_tile_is_responsive}-mode`;
    },
    containerMobileWebBackgroundImage() {
      const styles = {
        "background-image": "url(https://assets.redfastlabs.com/images/inline-bg-default.jpg)",
        "background-color": this.previewActions.rf_settings_bg_image_color,
      };
      if (this.previewActions.rf_settings_mobile_bg_image) {
        styles["background-image"] = `url(${this.previewActions.rf_settings_mobile_bg_image})`;
      } else if (this.previewActions.rf_settings_bg_image) {
        styles["background-image"] = `url(${this.previewActions.rf_settings_bg_image})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    mobileWebTitleFontSize() {
      return {
        "font-size": this.previewActions.rf_settings_mobile_title_font_size,
        "color": this.fillColor,
      };
    },
    mobileWebBodyFontSize() {
      return {
        "font-size": this.previewActions.rf_settings_mobile_message_font_size,
        "color": this.fillColor,
      };
    },
    containerClassName() {
      if (this.model.device_type === "web") {
        return `retention-tile-mweb-preview ${this.model.path_type}_type`;
      }
      return `retention-tile-mweb-preview mobileapp-design ${this.model.path_type}_type`;
    },
    desktopContClassName() {
      if (this.model.device_type === "web") {
        return `promo-tile-wrapper dt_${this.model.path_type}_type`;
      }
      return `promo-tile-wrapper mobileapp d_${this.model.path_type}_type`;
    },
    backgroundContentAlignmentClass() {
      if (this.previewActions.rf_retention_img_align === "left") {
        return "bgimg-align-class b-left-align";
      }
      return "promo-bg-image-align dt_right_type";
    },
    columnspacerWidth() {
      return {
        width: `calc(100% - ${this.previewActions.rf_settings_text_container_max_width})`,
        height: "4px",
      };
    },
  },
};
</script>
