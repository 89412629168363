<template>
  <span class="rf-menu inline-flex items-center justify-center">
    <RfButtonNew
      ref="menuActivator"
      :tooltipPos="tooltipPos"
      :type="props.buttonType"
      :size="props.buttonSize"
      :disabled="props.disabled"
      class="rf-menu--button inline-flex items-center"
      :class="{ 'rf-menu--opened': modalOpened }"
      @click="disabled || (modalOpened ? hideMenu() : showMenu())"
    >
      <slot name="activator" :opened="modalOpened"></slot>
      <div v-if="!hideIcon && !disabled" class="rf-menu--icon-wrapper flex">
        <Transition mode="out-in">
          <RfChevronMiniClosedIcon v-if="!modalOpened" class="my-auto !h-7 !w-7" />
          <RfChevronMiniOpenIcon v-else class="my-auto !h-7 !w-7" />
        </Transition>
      </div>
    </RfButtonNew>
    <Transition>
      <div
        ref="menu"
        v-if="modalOpened && !disabled"
        class="rf-menu--container list text-body absolute left-0 top-0 z-50 box-border inline-flex w-max flex-col items-start overflow-y-auto rounded bg-white shadow-grey-1/25"
      >
        <slot name="menu" :close="hideMenu"></slot>
      </div>
    </Transition>
  </span>
</template>

<script setup>
import RfChevronMiniClosedIcon from "@/components/icons/RfChevronMiniClosedIcon.vue";
import RfChevronMiniOpenIcon from "@/components/icons/RfChevronMiniOpenIcon.vue";
import { computePosition, flip, shift, offset, size } from "@floating-ui/dom";
import { onClickOutside } from "@vueuse/core";
import { nextTick, ref } from "vue";
import RfButtonNew from "../buttons/RfButtonNew.vue";

const apply = ({ availableWidth, availableHeight, elements }) => {
  Object.assign(elements.floating.style, {
    maxWidth: `${availableWidth}px`,
    maxHeight: `${availableHeight - 16}px`,
  });
};

const presets = {
  promptStatus: {
    placement: "bottom-start",
    middleware: [
      offset({ mainAxis: 6, crossAxis: -5 }),
      flip(),
      shift({ padding: 5 }),
      size({ apply }),
    ],
  },
  promptMenu: {
    middleware: [
      offset({ mainAxis: 6, crossAxis: -65 }),
      flip(),
      shift({ padding: 5 }),
      size({ apply }),
    ],
  },
  datePicker: {
    placement: "bottom-end",
    middleware: [offset({ mainAxis: 6 }), flip(), size({ apply })],
  },
  appSelectorSmall: {
    placement: "bottom-end",
    middleware: [offset({ mainAxis: -10, crossAxis: 28 }), flip(), size({ apply })],
  },
  default: {
    middleware: [offset({ mainAxis: 6 }), flip(), size({ apply })],
  },
};

const props = defineProps({
  preset: { type: String, default: null },
  hideIcon: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  sameWidth: { type: Boolean, default: false },
  minFit: { type: Boolean, default: false },
  maxHeight: { type: String, default: null },
  buttonType: { type: String, default: "none" },
  buttonSize: { type: String, default: "none" },
  tooltipPos: { type: String, default: null },
});

const emit = defineEmits(["hide", "show"]);

const modalOpened = ref(false);
const stop = ref(() => {});
const menu = ref();
const menuActivator = ref();

const update = () => {
  if (props.disabled) return;
  nextTick(() => {
    const activatorValue = menuActivator.value?.$el || menuActivator.value;
    const menuValue = menu.value;

    Object.assign(menuValue.style, {
      ...(props.sameWidth && { width: `${activatorValue.clientWidth}px` }),
      ...(props.minFit && { minWidth: "fit-content" }),
    });

    computePosition(activatorValue, menuValue, presets[props.preset] || presets.default).then(
      ({ x, y }) =>
        Object.assign(menuValue.style, {
          transform: `translate(${x}px, ${y}px)`,
          ...(props.maxHeight && { maxHeight: props.maxHeight }),
        }),
    );
  });
};

const hideMenu = () => {
  stop.value?.();
  modalOpened.value = false;
  emit("hide");
};

const showMenu = () => {
  if (props.disabled) return;
  modalOpened.value = true;
  update();

  nextTick(
    () =>
      (stop.value = onClickOutside(menu.value, hideMenu, {
        ignore: [menuActivator.value],
      })),
  );
  emit("show");
};

defineExpose({ show: showMenu, hide: hideMenu });
</script>

<style lang="scss" scoped>
.rf-menu {
  &--container {
    box-shadow: 0px 4px 27px 0px var(--tw-shadow-color);
  }
}

::v-deep.rf-menu--button .rf-button--container {
  @apply gap-1;
}

::v-deep.rf-menu--container.list,
::v-deep.rf-menu--container .list {
  > a:hover,
  > button:hover {
    @apply bg-background;
  }
}
</style>
