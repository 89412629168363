<template>
  <div :class="widgetParentClassName" :style="wrapperStyle">
    <div
      class="rf-widgetpromo-wrapper rfmodal-content rf-modal-content-wrapper"
      :style="widgetDimension"
      :class="widgetPositionClassName"
      v-if="isWidgetPromo"
    >
      <!-- note- in brig, widget position class name is a level higher -->
      <div class="rfmodal-wrapper inner-modal" :style="interstitialBackgroundImage" ref="rfdim">
        <RfRetentionMessage :actions="actions" :hideCloseButton="isInterstitial" />
      </div>
      <div :style="containerBackgroundImage" class="rfmodal-backgroundimage"></div>
    </div>
    <div class="rfmodal-content outer-modal" :style="modalWrapperStyle" v-else>
      <div class="rfmodal-wrapper inner-modal" :style="interstitialBackgroundImage" ref="rfdim">
        <RfRetentionMessage :actions="actions" :hideCloseButton="isInterstitial" />
      </div>
    </div>
    <span class="rfmodal-close" v-if="closeButtonEnabled && isInterstitial">
      <v-icon id="rfmodal-close-icon" :style="{ color: fillColor }">close</v-icon>
    </span>
  </div>
</template>
<script>
import RfRetentionMessage from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessage.vue";
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";

export default {
  name: "RfRetentionInterstitialContent",
  props: ["actions", "deviceType", "model", "zoomStyle", "zoomValue"],
  mixins: [PromoPreviewMixin],
  components: { RfRetentionMessage },
  computed: {
    wrapperStyle() {
      return {
        "background-color": this.actions.rf_settings_bg_image_color,
      };
    },
    interstitialBackgroundImage() {
      const styles = {
        "background-color": this.actions.rf_settings_bg_image_color,
        "background-size": this.actions.rf_settings_tile_is_responsive,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] = `url(${this.actions.rf_settings_bg_image})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    containerBackgroundImage() {
      const styles = {
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] = `url(${this.actions.rf_settings_bg_image})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    modalWrapperStyle() {
      return {
        width: this.actions.rf_settings_window_max_width,
        ...this.zoomStyle,
      };
    },
    widgetPositionClassName() {
      return `widget-${this.actions.rf_widget_position}`;
    },
    widgetDimension() {
      return {
        "width": this.actions.rf_widget_width,
        "height": this.actions.rf_widget_height,
        "z-index": "3 !important",
      };
    },
    widgetParentClassName() {
      if (this.isWidgetPromo) {
        return "modal-overlay-style widget-rf-promo";
      }
      return `modal-overlay-style div--${this.actions.rf_settings_window_max_width} rfmodal rFastModalWrapper interstitialModal`;
    },
    overlayBackgroundColor() {
      return {
        background: this.actions.rf_settings_overlay_background,
      };
    },
    isWidgetPromo() {
      const pathType = this.model.path_type;
      return pathType === "widget";
    },
    isInterstitial() {
      return this.model.path_type === "interstitial";
    },
  },
  methods: {
    getPreviewDimensions() {
      const htmlItem = this.$refs.rfdim;
      if (htmlItem) {
        const { clientWidth, clientHeight } = htmlItem;
        if (clientWidth && clientHeight) {
          return `${clientWidth}x${clientHeight}`;
        }
      }
      return "";
    },
  },
  updated() {
    this.$nextTick(() => {
      this.dimensions = this.getPreviewDimensions();
    });
  },
  mounted() {
    setTimeout(() => {
      this.dimensions = this.getPreviewDimensions();
    }, 0);
  },
};
</script>
