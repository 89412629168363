var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pd-container",style:(_vm.containerStyle)},[_c('div',{staticClass:"pd-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('h4',{staticStyle:{"font-size":"1.2rem","margin-bottom":"0.6rem"}},[_vm._v("Actions")]),_c('div',{staticClass:"edit-actions-button",staticStyle:{"margin-left":"auto"}},[(_vm.connectorActions.length)?_c('RfButton',{attrs:{"icon-size":"16","title":"edit","icon":"edit","color":"accent","x-small":""},on:{"click":function($event){return _vm.showPromoDetailDialog('connectors')}}}):_vm._e()],1)]),(_vm.connectorActions.length)?_c('div',{staticClass:"pd-actions-details-grid"},_vm._l((_vm.connectorActions),function(value){return _c('div',{key:value.id,staticClass:"pd-actions-detail-horizontal"},[_c('div',{staticClass:"pd-actions-logo-container"},[(
              _vm.providers[value.connector_action.provider] &&
              _vm.providers[value.connector_action.provider].type === 'img'
            )?_c('img',{attrs:{"src":_vm.providers[value.connector_action.provider].src,"width":_vm.providers[value.connector_action.provider].width}}):_c('b',[_vm._v(_vm._s(_vm.capitalize(value.connector_action.provider)))])]),_c('p',{staticClass:"pd-actions-detail-desc"},[_vm._v(" "+_vm._s(value.connector_action.args.description)+" ")]),_c('ul',{staticStyle:{"list-style":"none"}},_vm._l((value.args),function(inputValue,inputKey){return _c('li',{key:inputKey},[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("check")]),_vm._v(_vm._s(_vm.displayConnectorAction(inputKey, inputValue, value.connector_action.args.inputs))+" ")],1)}),0)])}),0):_c('RfButton',{staticClass:"no-actions-box",attrs:{"disabled":_vm.editDisabled},on:{"click":function($event){return _vm.showPromoDetailDialog('connectors')}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" add ")])],1)],1),(
      (_vm.app?.flags?.custom_devices_js_sdk &&
        _vm.model.device_type === _vm.DeviceTypeWording.custom_defined.value) ||
      _vm.model.device_type === _vm.DeviceTypeWording.web.value
    )?_c('RfPromoDetailsInfoRow',{staticStyle:{"border-width":"1px"},attrs:{"title":"Website Actions"},scopedSlots:_vm._u([{key:"promodetail",fn:function(){return [_vm._l((_vm.activeDisplayActions),function(item){return _c('div',{key:item.actionName,staticClass:"actions-ct-inner"},[_c('div',{staticClass:"webaction-value-div"},[_vm._v(" "+_vm._s(item.title)+": "),_c('span',[_vm._v(" "+_vm._s(_vm.isRedirectAction(item.actionName) ? _vm.model.actions[item.actionName] : _vm.displayWebsiteActions(item.actionName)))])])])}),_c('div',{staticClass:"pd-info-edit pd-website-action"},[_c('RfButton',{attrs:{"icon-size":"16","title":"edit","icon":"edit","color":"accent","x-small":""},on:{"click":function($event){return _vm.showPromoDetailDialog('client_actions')}}})],1)]},proxy:true}],null,false,2438912106)}):_vm._e(),_c('v-dialog',{attrs:{"width":"1280","content-class":"show-promo-details-actions","persistent":""},model:{value:(_vm.showPromotionActionsDialog),callback:function ($$v) {_vm.showPromotionActionsDialog=$$v},expression:"showPromotionActionsDialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('RfRetentionActions',{ref:"actions",attrs:{"model":_vm.model,"isDisabled":_vm.editDisabled,"actionType":_vm.actionType}})],1),_c('v-card-actions',{staticClass:"modal-card-actions-div"},[_c('v-btn',{staticClass:"cancel-btn",attrs:{"large":"","depressed":"","outlined":"","width":"100px"},on:{"click":_vm.closeDialog}},[_vm._v("Close")]),_c('RfButton',{attrs:{"icon":"save","button-text":"Save","color":"primary","disabled":_vm.editDisabled,"large":"","width":"200px"},on:{"click":_vm.submitConfiguration}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }